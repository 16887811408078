import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import {useHistory} from 'react-router-dom';
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';

import {authActions} from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import Helper1, {notify} from '../utility/Helper1';
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import Countdown from 'react-countdown';
import { RiLoginBoxLine, RiHome7Line, RiArchiveLine, RiBarChart2Line, RiBriefcase4Fill, RiArchiveDrawerLine } from "react-icons/ri";


function UserVerification(){

    const userData = useSelector(userSelect);

    const [emailCodeInput, setEmailCodeInput] = useState('') 
    const [phoneCodeInput, setPhoneCodeInput] = useState('') 
    const [verifyingEmailOrPhone, setVerifyingEmailOrPhone] = useState(0)

    const [fullPageLoading, setFullPageLoading]         = useState(false);
    const history                                       = useHistory();

    const dispatch = useDispatch();

    const verifyEmail = (e) => {
        e.preventDefault();

        setFullPageLoading(true);
        
        dispatch(authActions.onSignUp("account/verify_email", {emailCode:emailCodeInput}, response => {

            if(response.code == 1){
                if((userData?.emailVerification == 1) && (userData?.phoneVerification == 1)){
                    history.replace('/');
                }else{
                    setVerifyingEmailOrPhone(1)
                }
            }

            notify(response.msg, response.code)
            setFullPageLoading(false);
        
        }),
        );

    }


    const resendEmailVerification = (e) => {
        e.preventDefault();

        setFullPageLoading(true);
        
        dispatch(authActions.onRefreshSystemData("account/resend_email_verification", 100, {}, response => {
            notify(response.msg, response.code)
            setFullPageLoading(false);
        }),
        );
    }


    const resendPhoneVerification = (e) => {
        e.preventDefault();

        setFullPageLoading(true);
        
        dispatch(authActions.onRefreshSystemData("account/resend_phone_verification", 100, {}, response => {
            notify(response.msg, response.code)
            setFullPageLoading(false);
        }),
        );
    }



    const verifyPhone = (e) => {
        e.preventDefault();

        setFullPageLoading(true);
        
        dispatch(authActions.onSignUp("account/verify_phone", {smsCode:phoneCodeInput}, response => {

            if(response.code == 1){
                history.replace('/');
            }

            notify(response.msg, response.code)
            setFullPageLoading(false);
        
        }),
        );

    }



    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())
    }

    useEffect(() => {
        if(userData?.emailVerification == 0){
            setVerifyingEmailOrPhone(0)
        }else if(userData?.phoneVerification == 0){
            setVerifyingEmailOrPhone(1)
        }
    }, [])

    const bg = require('../images/auth.jpg');



    const logout = () => {
        return <span className="text-info-1 mt-40 cursor-pointer" onClick={doLogOutNowNow} style={{}}>
            Log out
        </span>
    }

    const logoutContainer = {display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}

    return (
        <SiteWide hideMainDiv= {true} backgroundImage={bg} hideMainDiv= {true} hideMenu={true} title="Account verification">
            <Section hideHeader={true} loading={fullPageLoading} allNotification={false} searchPopup={true} title={'Verification'}
            >
                
                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100" style={{backgroundColor: otherColors.body}}>


                            {verifyingEmailOrPhone == 0 && <form className="contact-form-inner auth-box login100-form validate-form">
                                

                                <div className="single-input-wrap">
                                    <span className="text-info-1">Enter code</span>
                                    <input className="auth-input form-control" onChange={e => setEmailCodeInput(e.target.value) } type="text" placeholder="" />
                                </div>


                                <div className="verify-btn">
                                    <button onClick={verifyEmail} className="btn btn-info btn-block uth-input">Verify Email</button>
                                </div>

                                <div className="verify-btn" style={logoutContainer}>
                                    <Countdown
                                        zeroPadTime={2}
                                        key={new Date(userData?.lastEmailTime)}
                                        date={new Date(userData?.lastEmailTime)}
                                        renderer={({ hours, minutes, seconds, completed }) => {
                                          if (completed) {
                                            // Render a completed state
                                            // timeUp();
                                            return <button  className="btnnput text-info-1 mt-40" onClick={resendEmailVerification}>Resend OTP</button>
                                            // 
                                          } else {
                                            // Render a countdown
                                            return <button  className="bth-input text-info-1 mt-40">{minutes}:{seconds}</button>;
                                          }
                                        }}
                                    />

                                    {logout()}

                                    
                                </div>

                            </form>
                            }


                            {verifyingEmailOrPhone == 1 && <form className="contact-form-inner auth-box login100-form validate-form" style={{backgroundImage: "url(assets/img/bg/14.png)"}}>
                                <span className="login100-form-title p-b-49 text-left" style={{marginBottom: 20}}>
                                    <WebLink to="/"><RiHome7Line size={30} className="text-info" /></WebLink>  Verify Mobile
                                </span>

                                <div className="single-input-wrap">
                                    <span className="text-info-1">Enter code</span>
                                    <input className="auth-input form-control" onChange={e => setPhoneCodeInput(e.target.value) } type="text" placeholder="" />
                                </div>

                                <div>
                                    <span className="text-white">
                                        <Countdown
                                            zeroPadTime={2}
                                            key={new Date(userData?.lastSMSTime)}
                                            date={new Date(userData?.lastSMSTime)}
                                            renderer={({ hours, minutes, seconds, completed }) => {
                                              if (completed) {
                                                // Render a completed state
                                                // timeUp();
                                                return <span onClick={resendEmailVerification} className="de-click text-info-1 mt-40">Resend Phone OTP</span>
                                                // 
                                              } else {
                                                // Render a countdown
                                                return <span className="text-info-1 mt-40">Resend after {minutes}:{seconds}</span>;
                                              }
                                            }}
                                        />
                                    </span>

                                </div>

                                <div className="verify-btn">
                                
                                    <button onClick={verifyPhone} className="btn btn-info btn-block uth-input">Verify Phone</button>
                                </div>


                                <div className="verify-btn" style={logoutContainer}>

                                    <Countdown
                                        zeroPadTime={2}
                                        key={new Date(userData?.lastSMSTime)}
                                        date={new Date(userData?.lastSMSTime)}
                                        renderer={({ hours, minutes, seconds, completed }) => {
                                          if (completed) {
                                            // Render a completed state
                                            // timeUp();
                                            return <button  className="btut text-info-1 mt-40" onClick={resendEmailVerification}>Resend OTP</button>
                                            // 
                                          } else {
                                            // Render a countdown
                                            return <button  className="btnh-input text-info-1 mt-40">{minutes}:{seconds}</button>;
                                          }
                                        }}
                                    />

                                    {logout()}

                                </div>


                            </form>
                            }

                        </div>
                    </div>
                </div>


            </Section>
        </SiteWide>
    );

}

export default UserVerification;