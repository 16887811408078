import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../utility/Helper1';
import {homepage} from '../utility/APIRequests';


import Countdown from 'react-countdown';

import copy from 'copy-to-clipboard';
import {staticCountries} from '../utility/countries';
import {Images} from "./customComponents";
import Slider from "react-slick";
import { RiFileCopyLine, RiPhoneLockFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';
import QRCode from "react-qr-code";


function RefLink({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [accessCode, setAccessCode]   = useState('');

    const [emailOTP, setEmailOTP]   = useState('');

    
    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');

    const dispatch = useDispatch();

    

    const copyRefLink = () => {
        

        notify('Referral Link Copied', 1)

    }

console.log(site_settings?.plans)
   const levelTableDesc = [
    {id: 1, percent: `${site_settings?.plans?.[0]?.childs?.[0]?.ref_earning_1}%`, desc: 'Direct invitee'},
    {id: 2, percent: `${site_settings?.plans?.[0]?.childs?.[0]?.ref_earning_2}%`, desc: 'LV1 invitee'},
    {id: 3, percent: `${site_settings?.plans?.[0]?.childs?.[0]?.ref_earning_3}%`, desc: 'LV2 invitee'},

   ]
    const myRefLink = site_settings?.site_settings?.site_url+'/#/signup?ref='+userData?.myrefid

    return (
        <SiteWide id="main" title={`Invite Your Friends`}>
            <Section loading={loading} overlayText={loadingText} title={'Invite Your Friends'}>
                
                <div className="nput-container" style={{padding:20, color: '#fff'}}>



                        <div style={{padding: 20, color: '#fff !important'}}>



                            <p className="text-cente">

                                <div style={{background: '#fff', padding: 20, display: 'inline-block'}}>
                                    <QRCode value={myRefLink} />
                                </div>
                                
                                <br/><br/><br/>

                                <h4 className="text-info-2 cursor-pointer" onClick={() => {copy(userData?.myrefid??''); notify('Copied', 1);}}>{userData?.myrefid} <RiFileCopyLine /></h4>

                                <br/>

                            </p>



                            <div>
                                <h3 className="text-info-2">Commission rebating rules</h3>
                                    <br />
                                    <p className="text-info-2">For every profit earned by a friend after registering, you will receive a
                                        corresponding percentage of commission
                                        
                                    </p>
                                    <br /><br />
                                    <h3 className="text-info-2">Commission rate rules are as follows</h3>
                                    <br />

                                    <p>

                                        <table style={{background: '#f1f1f1'}} className="table table-bordered text-center">
                                            <tr>
                                                <td>Level</td>
                                                <td>Desc.</td>
                                                <td>Commission</td>
                                            </tr>

                                            {levelTableDesc.map((item, index) => <tr>
                                                <td>LV{item.id}</td>
                                                <td>{item.desc}</td>
                                                <td>{item.percent}</td>
                                            </tr>)}

                                        </table>

                                    </p>

                            </div>


                        </div>

                        <br />

                        <div style={{padding: 10, marginBottom: 20}}>
                            <button onClick={()=>{copy(myRefLink); copyRefLink();}} className="btn btn-warning btn-block">Copy Invitation Link</button>
                        </div>


                </div>



            </Section>
        </SiteWide>
    );

}

export default RefLink;