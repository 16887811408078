import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import CustomAgo from "./customComponents/CustomAgo";
import {WebLink, SiteWide, Images} from "./customComponents";
import {mainStyle, mainColor, header} from '../utility/styles';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {exchangeActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, exchangeSelect } from '../redux/selectors/index';
import Helper1, {notify, styles, money, customModalStyles, moneyStatus, usefulColors} from '../utility/Helper1';
import { useHistory } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';


function AllP2P() {

    const dispatch = useDispatch();

    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');

    const allExchangers = useSelector(exchangeSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);


    useEffect(() => {
        dispatch(exchangeActions.onGetExchange("p2p/load/all", response => {
        }))

    }, [])

    
    return (
        <SiteWide id="main" title={"All Exchanges"}>
            <Section  hideHeader={true} overlayText={loadingText} loading={loading} title={'All Exchanges'}>
                
                <div className="transaction-area">
                    <div className="container">


                        {allExchangers?.map((peerHead, headIndex) => <>
                            
                            <div className="single-goal single-goal-one">
                                <div className="row">
                                    <div className="col-7 pr-0">
                                        <div className="details">
                                            <h6>#{peerHead?.txn_id}</h6>
                                            <p>
                                                <span>{money(peerHead?.crypto_amount, peerHead?.type)} <i className="fa fa-long-arrow-right"></i>{money(peerHead?.fiat_amount, 0, true)}</span><br />
                                                <span>Unpaired sum: {money(peerHead?.fiat_amount_peerable, 0, true)}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-5 pl-0">
                                        <div className="circle-inner circle-inner-one">
                                            <div className="chart-circle" data-value="0.30">
                                                
                                                <canvas width="52" height="52" />

                                                <div className={`chart-circle-value text-center text-${peerHead?.buyOrSell == 0?'warning':'info'}`}>
                                                    <span>{peerHead?.active == 1?'Active':'Completed'}</span>
                                                    <br />
                                                    {peerHead?.buyOrSell == 0?'Buy':'Sell'} order
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </>)}

                    </div>
                </div>







            </Section>
        </SiteWide>
    );

}

export default AllP2P;