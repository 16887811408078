import React, {Component, useState, useEffect} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import {WebLink, SiteWide} from "./customComponents";
import NoData from "./customComponents/NoData";

import Section from "./layouts/Section";
import {useHistory, useLocation} from 'react-router-dom';

import Slider from "react-slick";
import {Images} from "./customComponents";
import Colors from '../utility/Colors';
import Helper1, {notify, styles, money} from '../utility/Helper1';

import {depositActions} from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import Modal from 'react-modal';
import LoadingOverlay from 'react-loading-overlay';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CustomAgo from "./customComponents/CustomAgo";

    

function TransferHistory() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]   = useState('Loading history...');

    const [loading, setloading]   = useState(false);
    const [deleting, setDeleting]   = useState(false);
    const [dataHasChanged, setDataHasChanged]   = useState(false);

    const [amount, setAmount]   = useState('');
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);

    const [enterAmountModal, setEnterAmountModal]   = useState(false);
    const [newDepositInfo, setNewDepositInfo]   = useState([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [transferData, setTransferData] = React.useState([]);

    const finance = useSelector(homeScreenStatSelect);

    const dispatch = useDispatch();
    const loadHistoryData = useSelector(allDepositSelect);


    const search = useLocation();
    const depositRequest = search?.state?.deposit;

    const loadAllDeposit = () => {

        setloading(true)
        setLoadingText('Loading payment history...')
        dispatch(depositActions.onLoadAllDeposits("transfer/load", response => {
            
            if(response.code == 1){
                setTransferData(response.data.transfers);
            }
            setloading(false)

        }))

    }

    useEffect(() => {
        loadAllDeposit()
    }, [])


    const status = ['Pending', 'Processing', 'Paid', 'Cancelled'];

    return (

        <SiteWide id="main" title={"Transfer"}>
            <Section loading={loading} title={'Transfer'}
                >

                <div class="container" style={{}}> 


                    <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>

                        <WebLink to="/deposit-records" class="button one">Deposits</WebLink>
                        <div></div>

                         <WebLink to="/withdrawal-records" class="button one">Withdrawals</WebLink>
                        <div></div>

                         <WebLink style={{backgroundColor: '#fff', color: '#000'}} to="/transfer-records" class="button one">Transfer</WebLink>
                        <div></div>

                    </div>

                    {transferData?.map((item, index) => {

                        let image = finance?.filter((filitem, index) => filitem.type == item.currency)

                        return <div className="ba-bill-pay-inner"  style={{background: '#fff', border: 'none'}}>
                            <div className="ba-single-bill-pay">
                                {/*<div className="thumb">
                                    <Images height={40} source={image?.[0]?.image} alt="img" />
                                </div>*/}
                                <div className="details" style={{width: '100%'}}>
                                    <h5 style={{display: 'flex'}}>
                                        <span style={{color: item?.transferer == 1?'red':'green', fontFamily: 'ghj'}}>
                                            {item?.transferer == 1?'Sent':'Received'}
                                        </span>
                                        <span style={{marginLeft: 'auto', color: item?.transferer == 1?'red':'green'}}>
                                            {money(item?.amount, item?.type)}
                                        </span>
                                    </h5>
                                    <p style={{display: 'flex'}}>
                                        <span>
                                            {item.peerUsername}
                                        </span>
                                        <span style={{marginLeft: 'auto'}}>
                                            {status[item?.status]}
                                        </span>
                                    </p>
                                    <p><CustomAgo time={item?.timestamp}/></p>
                                </div>

                            </div>

                        </div>

                    })}


                </div>



                <Footer />

            </Section>


                
            

        </SiteWide>
    );
}

export default TransferHistory;