import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import CustomAgo from "./customComponents/CustomAgo";
import {WebLink, SiteWide, Images} from "./customComponents";
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {exchangeActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, exchangeSelect } from '../redux/selectors/index';
import Helper1, {notify, styles, money, customModalStyles, customNormalModalStyles, moneyStatus, usefulColors} from '../utility/Helper1';
import Countdown from 'react-countdown';
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { RiChat3Fill, RiUpload2Fill, RiImageFill, RiRecordCircleFill, RiArrowGoBackLine } from "react-icons/ri";

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';


function P2P() {

    const dispatch = useDispatch();
    const hiddenFileInput = React.useRef(null);
    const history = useHistory();

    const [peeredId, setPeeredId]   = useState(false);
    const [showModal, setShowModal]   = useState(false);
    const [openBuySellModal, setOpenBuySellModal]   = useState(false);
    const [imageGallery, setImageGallery]   = useState({});
    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');
    const site_settings = useSelector(allRemoteSettingsSelect);

    const allExchangers = useSelector(exchangeSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [uSDTAmount, setUSDTAmount]   = useState('');
    const [nairaAmount, setNairaAmount]   = useState('');
    const [buyOrSell, setBuyOrSell]   = useState('');
    const [peerFilter, setPeerFilter]   = useState(0);

    const calculateOther = (editingNaira, inValue) => {

        let oneDollarToNaira = finance?.[0]?.rateToNaira;
        let finalNaira, finalUSDT;

        if(editingNaira == 1){
            finalNaira = inValue;
            finalUSDT = (inValue/oneDollarToNaira).toFixed(2);
            setUSDTAmount(finalUSDT)
        }else{
            finalUSDT = inValue;
            finalNaira = inValue * oneDollarToNaira;
            setNairaAmount(finalNaira)
        }
    }

    const updateUSDTAmount = (value) => {
        if(!isNaN(value)){ setUSDTAmount(value) }
        calculateOther(2, value)
    }

    useEffect(() => {
        dispatch(exchangeActions.onGetExchange("p2p/load/all", response => {
        }))
    }, [])

    const updateNairaAmount = (value) => {
        if(!isNaN(value)){ setNairaAmount(value) }
        calculateOther(1, value)
    }
    
    const submitExchange = (e) => {

        e.preventDefault();

        const currency = finance?.filter((finitem, finindex) => finitem.autoConfirm == 1)?.[0]?.type

        setloading(true)
        setLoadingText('Submitting exchange request...')


        dispatch(exchangeActions.onPostExchange({uSDTAmount, buyOrSell, currency}, "p2p/exchange/new", response => {
            
            if(response.code == 1){
                setUSDTAmount('')
                setNairaAmount('')
            }

            notify(response.msg, response.code)
            setloading(false)
            
        }))

    }


    const nairaPlace = () => {
        return <div class="form-group">
                    <span className="t-group-text" id="iput1">NGN</span>
                    <input type="text" className="text-left form-field" value={nairaAmount} onChange={(e) => updateNairaAmount(e.target.value)} placeholder="Naira sum" />
                </div>
    }


    const usdtPlace = () => {
        return <div class="form-group">
            <span className="t-group-text" id="iput1">USDT</span>
            <input type="text" className="text-left form-field" value={uSDTAmount} onChange={(e) => updateUSDTAmount(e.target.value)} placeholder="USDT sum" />
            </div>
    }


    const openBuyOrSell = (which) => {
        setOpenBuySellModal(true)
        setBuyOrSell(which)
    }


    const viewImage = (peerData) => {
        setShowModal(true)
        setImageGallery(peerData)
    }
    
    const selectPayment = (peeredObj) => {
        setPeeredId(peeredObj?.id)
        hiddenFileInput.current.click();
    }

    const uploadPayment = (file) => {
        setloading(true);
        setLoadingText('Uploding payment')

        dispatch(exchangeActions.onPostExchange({image: file}, `p2p/payment/upload/${peeredId}`, response => {

            notify(response.msg, response.code)
            setloading(false)
            
        }))

    }

    const confirmPayment = (peerData) => {
        setloading(true);
        setLoadingText('Confirming payment')

        dispatch(exchangeActions.onGetExchange(`p2p/payment/confirm/${peerData?.id}`, response => {

            notify(response.msg, response.code)
            setloading(false)
            
        }))

    }


    const options = [
      'one', 'two', 'three'
    ];

    const defaultOption = options[0];

    const changeDropDown = (inValue) => {
        setPeerFilter(inValue)
    }

    const chat = (peeredObj) => {
        history.push({
            pathname: '/chat',
            search: `?chatsource=${peeredObj?.id}`
        })
    }



    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
        }]
    };



    return (
        <SiteWide id="main" title={"Exchange"}>
            <Section overlayText={loadingText} loading={loading} title={'Exchange'}>
            

                <div className="container">
                   

                   <div style={{marginTop: 120, padding: 10, backgroundColor: otherColors.cotpBlue, borderRadius: 10}}>
                        
                        <div>
                            <h3 className="text-info-1">{site_settings?.operational_settings?.p2pHeadline}</h3>
                        </div>

                        <hr />
                        <div className="text-info-1">
                            {site_settings?.operational_settings?.p2pContent}
                        </div>

                    </div>

                </div>


                <div className="container">
                    
                    <div  style={{marginTop: 20}}>


                        <Slider arrows={false} {...settings}>

                            {allExchangers?.map((peerHead, headIndex) => <>
                            
                                <div className="single-goal single-goal-one" style={{backgroundColor: otherColors.cotpBlue, border: 'none'}}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="details">

                                                <div style={{display: 'flex'}}>
                                                    <h5 className="text-info-1">{peerHead.buyOrSell==0?'Buy':'Sell'} order {peerHead?.txn_id}</h5>
                                                    <h3 style={{marginLeft: 'auto'}}><RiRecordCircleFill size={20} color={peerHead?.active == 1?'#f70':'#90ee90'}/></h3>

                                                </div>

                                                <hr />



                                                <div style={{display: 'flex', color: 'white'}}>
                                                    <div className="text-info-1">Base sum</div>
                                                    <div className="text-info-1" style={{marginLeft: 'auto'}}>{money(peerHead?.crypto_amount, peerHead?.type)}</div>
                                                </div>

                                                <div style={{display: 'flex', color: 'white'}}>
                                                    <div className="text-info-1">Cleared</div>
                                                    <div className="text-info-1" style={{marginLeft: 'auto'}}>{money(peerHead?.fiat_amount, 0)}</div>
                                                </div>


                                                <div style={{display: 'flex', color: 'white'}}>
                                                    <div className="text-info-1">Idle</div>
                                                    <div className="text-info-1" style={{marginLeft: 'auto'}}>{money(peerHead?.fiat_amount_peerable, 0)}</div>
                                                </div>

                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                            </>)}

                        </Slider>
                        



                        <div className="container" style={{marginTop: 30, padding: 0}}>
                            <div className="row">
                                {moneyStatus()?.map((item, index) => <div className="col-3 text-center">
                                        <button onClick = {() => changeDropDown(index)} style={{backgroundColor: peerFilter == index?otherColors.cotpBlue:'transparent'}} className={`text-dark-1 btn-block levelBTN`}>{item}</button>
                                    </div>)}
                            </div>
                        </div>




                        <div className="containe mt-40">
                            <ul class="surveys grid">
                                

                                {allExchangers?.map((peerHead, headIndex) => <>
                                
                                    {peerHead?.peerData?.filter((filterItem, filterIndex) => filterItem.status == peerFilter || peerFilter == 100)?.map((peerData, childIndex) => <li class="survey-item">

                                        <span class="survey-country list-only">
                                          UK
                                        </span>

                                        {peerHead.buyOrSell == 0 && <span class="survey-name">
                                          {peerData?.sellerUsername} {peerData?.sellerAccountName}

                                          {peerHead.buyOrSell == 0 && (peerData?.status < 2) && <span>{peerData?.sellerBank}</span>}
                                        </span>}

                                        {peerHead.buyOrSell == 1 && <span class="survey-name">
                                          {peerData?.buyerUsername}
                                        </span>}

                                        

                                        <span class="survey-country grid-only">
                                          {money(peerData?.amount, 0, true)} #{peerData.txn_id} {(peerHead.buyOrSell == 0)?'purchase':'sale'} peer
                                        </span>

                                        <div class="pull-right">

                                            <span class="survey-progress">
                                                <span class="survey-progress-bg">
                                                    {peerHead.buyOrSell == 0 && (peerData?.status < 2) && <span>{peerData?.sellerBank}</span>}

                                                </span>

                                                <span class="survey-progress-labels">
                                                    <span class="survey-progress-label">
                                                        {peerData?.image !== null && <span onClick={() => viewImage(peerData)}><RiImageFill size={20} /></span>}
                                                    </span>

                                                    <span class="survey-completes">
                                                        {peerHead.buyOrSell == 0 && peerData?.status < 2 && <>
                                                            <span>
                                                                <span onClick = {() => selectPayment(peerData)} className="cursor-pointer" id="avatarPlace" title="Click to upload"><RiUpload2Fill size={20} /></span>
                                                                <input style={{display: 'none'}} ref={hiddenFileInput} onChange={(e) => uploadPayment(e.target.files[0])} type="file" accept=".jpg, .gif, .png, .jpeg"/>
                                                            </span>
                                                        </>}
                                                        {peerHead.buyOrSell == 1 && peerData?.status < 2 && <>
                                                            <span onClick = {() => window.confirm('Are you sure you want to confirm payment?') && confirmPayment(peerData)}>
                                                                Confirm
                                                            </span>
                                                        </>}
                                                    </span>
                                                </span>
                                            </span>

                                            <span class="survey-end-date ended">
                                                {(peerData?.status < 2) && <Countdown
                                                    date={new Date(peerData?.disengageTime)}
                                                    renderer={({ hours, minutes, seconds, completed }) => {
                                                      if (completed) {
                                                        return <span></span>
                                                      } else {
                                                        return <span>{hours}:{minutes}:{seconds}</span>;
                                                      }
                                                    }}
                                                  />}

                                                  


                                            </span>
                                            <span class="survey-stage">
                                                <span class="stage draft">Draft</span>
                                                <span class="stage awarded">Awarded</span>
                                                <span class="stage live">Live</span>
                                            </span>
                                        </div>
                                    </li>)}

                                </>)}
                                
                            </ul>
                        </div>


                        <div id="container-floating">
                          
                          <div class="nd3 nds">
                            <p onClick={() => openBuyOrSell(0)} class="letter">Buy</p>
                          </div>
                          
                          <div class="nd1 nds">
                            <p onClick={() => openBuyOrSell(1)} class="letter">Sell</p>
                          </div>

                          <div id="floating-button">
                            <p class="plus">+</p>
                            <img class="edit" src="https://ssl.gstatic.com/bt/C3341AA7A1A076756462EE2E5CD71C11/1x/bt_compose2_1x.png"/>
                          </div>
                        </div>


                    </div>

                </div>






                <Modal
                    isOpen={showModal}
                    onRequestClose={() => setShowModal(false)}
                    contentLabel="Uploaded Image"
                    style={customNormalModalStyles}
                    className="add-balance-inner-wrap"
                    shouldCloseOnOverlayClick={true}
                    shouldCloseOnEsc={true}
                  >

                    <div>

                        <div className="mody">
                            <div className="text-center">
                                <Images width={'200px'} src={imageGallery?.image} alt={"Uploaded peered amount"}/>
                            </div>
                        </div>
                    </div>

                </Modal>
















                    <Modal
                        isOpen={openBuySellModal}
                        onRequestClose={() => setOpenBuySellModal(false)}
                        contentLabel="New P2P"
                        style={customNormalModalStyles}
                        className="add-balance-inner-wrap"
                        shouldCloseOnOverlayClick={false}
                        shouldCloseOnEsc={false}
                      >

                        <div className="modal-content">
                            <div className="modal-body">

                                <form className="contact-form-wrap">

                                    {buyOrSell == 0 && nairaPlace()}
                                    {buyOrSell == 1 && usdtPlace()}

                                    <div className="text-center">
                                        <i className="fa fa-exchange" />
                                    </div>

                                    {buyOrSell == 1 && nairaPlace()}
                                    {buyOrSell == 0 && usdtPlace()}

                                    <button onClick={submitExchange} type="submit" disabled={loading} className="btn btn-block btn-primary" style={{backgroundColor: otherColors.cotpBlue}}>
                                        OK </button>
                                </form>

                            </div>
                        </div>


                        <div className="text-center mt-40">

                            <WebLink onClick={() => setOpenBuySellModal(false)} className="text-white cursor-pointer"><RiArrowGoBackLine /> Back</WebLink>
                        </div>

                    </Modal>






            </Section>
        </SiteWide>
    );

}

export default P2P;