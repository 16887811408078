import React, {useState, useEffect} from "react";

import {notificationAction} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { notificationSelect } from '../../redux/selectors/index';
import { store } from "../../redux/store";
import {Images} from "./index";
import { RiCloseFill, RiCheckDoubleFill } from "react-icons/ri";
import { FcCancel, FcApproval } from "react-icons/fc";


let hideIn = () =>  new Date(Date.now() + 6000);

export const show = (msg, code) => { store.dispatch(notificationAction.onChangeNotification({show: true, msg: msg, code: code, hideAfter: hideIn()})); }
export const hide = () => { store.dispatch(notificationAction.onChangeNotification({show: false})); }

let timer = 0;

function Notify() {

    // const dispatch = useDispatch();
    const notificationData = useSelector(notificationSelect);

    const hideNotification = () => {
        hide();
    }

    useEffect(() => {
        
        clearTimeout(timer);

        if(notificationData?.show){
            timer = setTimeout(() => {
                    hide();
                }, notificationData?.hideAfter - Date.now())
        }

        
    }, [notificationData?.hideAfter])

    let imageCode = notificationData?.code == 1 ? 1 : 0;

    if(notificationData?.show){
        return <div className="text-center text-white" onClick = {() => hideNotification()} 
                    style={{width: '250px',
                            height: '250px',
                            position: 'fixed',
                            zIndex: '999999999999999999999999',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            borderRadius: 15,
                            background: 'rgba(255, 255, 255, 0.9)',}}>

                <div className="" style={{display: 'flex', flexDirection: 'column', padding: 10, height: '100%'}}>
                    
                    <div className="modal-body text-center">
                        <div style={{display: 'block'}}>
                            {notificationData?.code == 1 ?<FcApproval size={90}/>:<FcCancel size={90}/>}
                        </div>
                        <br />
                        <p className="mb-5 text-dark h6" style={{marginTop: 10}}>{notificationData?.msg}</p>
                    </div>


                </div>

            </div>
    }else{
        return <></>
    }

}

export default Notify;