import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Helper1, {notify, niceDate, money} from '../utility/Helper1';
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';
import CustomAgo from "./customComponents/CustomAgo";

import { useSelector, useDispatch } from 'react-redux';
import {authActions, withdrawalActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import LoadingOverlay from 'react-loading-overlay';
import {Images} from "./customComponents";

import {RiUser4Line } from "react-icons/ri";
import PaginatedItems from "./customComponents/PaginatedItems";



function Referrals() {    
    const [loadingText, setLoadingText]   = useState('Loading netork...');
    const [loading, setLoading]         = useState(false)
    const [dataForLevel1, setLevel1]    = useState([])
    const [dataForLevel2, setLevel2]    = useState([])
    const [dataForLevel3, setLevel3]    = useState([])
    const [mainUplineData, setMainUplineData]    = useState({})

    const [viewingForLevel1, setViewingForLevel1]   = useState(0)
    const [viewingForLevel2, setViewingForLevel2]   = useState(0)
    const [viewingForLevel3, setViewingForLevel3]   = useState(0)
    const [viewingLevel, setViewingLevel]     = useState(1)
    const [refData, setRefData]     = useState([])
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);


    const dispatch = useDispatch();

    const loadDownLines = (myrefid, level) => {
        
        setLoading(true)

        dispatch(authActions.onRefreshSystemData(`user/referral/${myrefid}`, 10, {}, response => {
            

            setLoading(false)

            setRefData(response?.data?.referrals)
            setMainUplineData(response?.data?.upline)

            // if(level == 1){
                // setLevel1(response?.data?.referrals?.level1)
            // }else if(level == 2){
                // setLevel2(response?.data?.referrals?.level2)
            // }else{
                // setLevel3(response?.data?.referrals?.level3)
            // }

            // setLeaderboardData(response?.data?.leaderboard)
        }))
    }
    
    useEffect(() => {
        loadDownLines(userData?.myrefid, 1);
    }, [])



    const processRefWithdrawal = () => {
        

        setLoading(true);
        setLoadingText('Receiving...');

        dispatch(authActions.onLogin(`withdraw/ref`, {currency: finance?.[0]?.type}, response => {
            notify(response.msg, response.code)
            if(response.code == 1){
                
            }
            setLoading(false);
        }))
    }





    const userData = useSelector(userSelect);


    const affiliatePlace = ({ currentItems }) => {
        return <div className="col-12">
            <table className="text-center" style={{backgroundColor: '#fff', border: 'none'}} className={`table triped ordered hover`}>
              <thead style={{border: 'none', backgroundColor: '#000'}}>
                <tr>
                  {/*<th className="text-center" style={{border: 'none', height: 20}}><span className="text-white">#ID</span></th>*/}
                  <th className="text-left" style={{border: 'none', height: 20}}><span className="text-white">Username</span></th>
                  <th className="text-left" style={{border: 'none', height: 20}}><span className="text-white">Remitance</span></th>
                  <th className="text-right" style={{border: 'none', height: 20}}><span className="text-white">Signup date</span></th>
                </tr>
              </thead>
              <tbody style={{border: 'none'}}  className="text-white">

                {currentItems?.map((eachdownline, index) => {

                    let refWithdrawnFromMe = eachdownline?.['refWithdrawnFromMe_' + viewingLevel];

                return <tr style={{border: 'none'}}>
                    {/*<td className="text-center" style={{border: 'none'}}><span className="cursor-pointer">{eachdownline?.myrefid}</span></td>*/}
                    <td className="text-left" style={{border: 'none', color: '#000'}}>
                        <span>{eachdownline?.username}  {(eachdownline?.status ?? '') == 2 && <Images width={20} source={require('../images/verified.png')} />} </span>
                    </td>
                    <td className="text-left" style={{border: 'none', color: '#000'}}>
                        <span>
                            {money(refWithdrawnFromMe?refWithdrawnFromMe:0, 170)}
                        </span></td>
                    <td className="text-right" style={{border: 'none', color: '#000'}}><span>{niceDate(eachdownline?.date)}</span></td>
                </tr>
                })}
                

              </tbody>
            </table>
        </div>
    }


    return (<SiteWide id="main" title="Affiliate">
        <Section  hideHeader={false} allNotification={false}  overlayText={loadingText} loading={loading} searchPopup={true} title={'Affiliate'}
        
        rightTopMenu={<CopyToClipboard text={site_settings?.site_settings?.site_url+'/#/signup?ref='+userData?.myrefid} onCopy={() => notify('Copied', 1)}><span className="cursor-pointer">Ref. code</span></CopyToClipboard>}
        
        >
            <div className="container">
               

                <div className="container" style={{backgroundColor: '#fff', borderRadius: 10}}>
                    <div className="row">
                        
                        <div className="col-12 text-center text-whit text-right">
                            <h1>
                                {money(finance?.[0]?.commission ?? 0, finance?.[0]?.type ?? 0)}
                            </h1>
                        </div>

                    </div>


                </div>







                <div className="container" style={{marginTop: 30, padding: 0}}>
                    <div className="row">
                        {[1,2,3].map((item, index) => <div className="col-3 text-center">
                                <button onClick={() => setViewingLevel(item)} style={{background: viewingLevel == item?'transparent':otherColors.btn1, color: viewingLevel == item?'#000':'#fff'}} className={`text-dar btn-block levelBTN`}>LV{item}</button>
                            </div>)}

                        <div className="col-3 text-center"><button onClick={() => processRefWithdrawal()} style={{background: otherColors.btn1}}  className={`btn-info btn-block levelBTN cursor-pointer`}>
                            Claim
                        </button></div>

                    </div>
                </div>





                <div className="container" style={{marginTop: 30, marginBottom: 30, padding: 0}}>
                    <div className="row">
                                                
                        {(refData?.['level'+viewingLevel]?.length ?? []) > 0 && <PaginatedItems Template={affiliatePlace} arrayOfDataToDisplay={refData['level'+viewingLevel]} itemsPerPage={20} />}

                    </div>
                </div>

                        
                    
            </div>
        </Section>
        </SiteWide>
    );

}

export default Referrals;