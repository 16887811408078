import React, {useState, useEffect, useRef} from "react";
import Footer from "./layouts/Footer";
import {WebLink, SiteWide, Images} from "./customComponents";
import Ticker1 from "./customComponents/Ticker1";
import Ticker2 from "./customComponents/Ticker2";

import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';
import Slider from "react-slick";
import ModalCustomHead from "./customComponents/ModalCustomHead";
import PinAuth from "./customComponents/PinAuth";

import Colors from '../utility/Colors';
import {homepage, contact_us} from '../utility/APIRequests';
import Section from "./layouts/Section";
import LoadingOverlay from 'react-loading-overlay';
import Countdown from 'react-countdown';

import { useSelector, useDispatch } from 'react-redux';
import { exchangeSelect, allTradeSelect, allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import {authActions, withdrawalActions, depositActions, tradeActions} from '../redux/actions';

import AutoNotification from "./layouts/AutoNotification";
import NewsTicker, {Directions} from 'react-advanced-news-ticker';
import Helper1, {niceDate, notify, styles, money, decimalCount, FormatOnly, customNormalModalStyles, moneyStatus, currencySliderArray} from '../utility/Helper1';
import { useHistory } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { RiWalletFill, RiCheckboxIndeterminateLine, RiHome6Line, RiMore2Fill, RiCalculatorLine, RiArrowDropRightLine, RiLogoutBoxLine, RiLogoutBoxRLine, RiVolumeUpFill, RiNewspaperLine, RiIncreaseDecreaseLine, RiFundsFill, RiGiftFill, RiSwapBoxFill, RiMapPinRangeFill, RiTelegramFill, RiWhatsappFill, RiStockFill, RiRemoteControlFill, RiInstallFill, RiKeyboardFill, RiCheckboxLine, RiArrowLeftLine, RiShareForward2Fill, RiBattery2ChargeLine, RiBatteryShareFill, RiCastLine, RiDownload2Line, RiNotification3Line, RiArrowDownCircleFill, RiMailCheckFill, RiErrorWarningFill, RiDeleteBin2Fill, RiMoneyDollarCircleLine, RiFileCopy2Fill, RiGiftLine, RiSecurePaymentLine, RiRecordCircleFill, RiShieldCrossLine, RiUser4Fill, RiExternalLinkFill, RiContactsBookFill,
RiWallet3Fill, RiCalendarTodoFill, RiArrowGoBackLine, RiGasStationFill, RiBankCard2Line, RiShieldUserFill, RiTeamFill, RiTicket2Line, RiUserAddFill } from "react-icons/ri";

import { FcReddit, FcLink,FcMultipleInputs, FcNightPortrait, FcRedo, FcMultipleSmartphones, FcLockPortrait, FcBusinessContact, FcPhotoReel, FcPieChart, FcPuzzle, FcBarChart, FcBullish, FcCurrencyExchange, FcDebt, FcDepartment, FcAreaChart, FcDisplay, FcDocument, FcElectricalThreshold,
FcEngineering, FcFactory, FcFinePrint, FcFilingCabinet, FcHighPriority, FcIntegratedWebcam, FcLightAtTheEndOfTunnel, FcMms, FcNews, FcUpload } from "react-icons/fc";

import CustomAgo from "./customComponents/CustomAgo";


import Modal from 'react-modal';
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import Header from './layouts/Header'
import Ticker from 'react-ticker'
import Marquee from 'react-marquee-master';

// import Ticker from 'nice-react-ticker';

// import { PaystackButton, usePaystackPayment } from 'react-paystack'
// import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
// import Clock from 'react-live-clock';
// import TradingViewWidget from 'react-tradingview-widget';

import {
    Menu,
    MenuItem,
    MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';




const modalTitle = [
    'Withdraw',
    'Fund Wallet',
    'Transfer',
    'Trade',
    'Calculator',
]

const menuIcons = [

    {
        images:'dash_5.png',
        info:'FAQ',
        linked: 'faq'
    },
    {
        images:'dash_6.png',
        info:'About',
        linked: 'about'
    },
    
    
    
]



    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },,
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
    };



const balanceSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,

        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
        }]
    };

const balanceSettings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,

        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 1
            }
        }]
    };

function Home() {



    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);


    const [uploadingAvatar, setUploadingAvatar]           = useState(false);

    const history = useHistory();
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
// console.log(userData)
    const exchangeStoreData = useSelector(exchangeSelect);
    const tradeStoreData = useSelector(allTradeSelect);

    const [openModal, setOpenModal]   = useState(false);
    const [whichModal, setWhichModal]   = useState(1);
    const [loadingStat, setLoadingStat]   = useState(true);

    const [googleAuth, setGoogleAuth]   = useState('');

    const [withdrawAmount, setWithdrawAmount]   = useState('');
    const [withdrawalPin, setWithdrawalPin]     = useState('');
    const [walletAddress, setWalletAddress]     = useState('');
    const [whichWithdrawal, setWhichWithdrawal]     = useState(0);

    const [selectedMotherCurrencyForCalculator, setSelectedMotherCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.mother);
    const [selectedPlanCurrencyForCalculator, setSelectedPlanCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.childs);
    const [calculatorInput, setCalculatorInput]     = useState(0);
    const [calculatorResult, setCalculatorResult]     = useState(0);

    const allDepositData = useSelector(allDepositSelect);
    const [depositAmount, setDepositAmount]   = useState('');
    const [selectedCurrency, setSelectedCurrency]   = useState(finance?.[0]?.type);
    const [selectedCurrencyObj, setSelectedCurrencyObj]   = useState(finance?.[0]);


    const [receiverEmail, setReceiverEmail]     = useState('');
    const [sendingAmount, setSendingAmount]     = useState('');


    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');

    const [tradeAmount, setTradeAmount]   = useState('');
    const [selectedPlan, setSelectedPlan]   = useState(site_settings?.plans?.[0]?.childs?.[0]);
    
    const balanceref2 = useRef();
    const balanceref1 = useRef();
    const chooseCurrency1 = useRef();
    const chooseCurrency2 = useRef();



    const dispatch = useDispatch();

    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())
    }

    const selectACurrency = () => {
        
        let value = <><div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 10}}>
            {currencySliderArray(chooseCurrency2, chooseCurrency1, finance)}
        </div>

        <Slider ref={chooseCurrency2} arrows={false} {...balanceSettings2}>
            {finance?.slice(0, 6)?.map((item, index) => <>
                <div onClick={() => {setSelectedCurrency(item.type); setSelectedCurrencyObj(item)}} style={{display: 'flex', 
                    backgroundColor: ((item.type == selectedCurrency)?'#00ccb3':otherColors.header), borderRadius: 6, padding: '12px 3px', alignItems: 'center', border: "1px solid #CDD9ED"}}>
                    
                    <Images height={30} source={item.image} alt={item.name} />
                    <div className="content text-white" style={{marginLeft: 5}}>
                        <h6 className="text-white">{item.name}</h6>
                    </div>

                    <div style={{marginLeft: 'auto',}}>
                        {item.type == selectedCurrency?<RiCheckboxLine color={'#fff'} size={20}/>:<RiCheckboxIndeterminateLine color={"#fff"} size={20}/>}
                    </div>

                </div>
            </>)}
        </Slider>

        <Slider ref={chooseCurrency1} arrows={false} {...balanceSettings2}>
            {finance?.slice(6, 50)?.map((item, index) => <>
                <div onClick={() => {setSelectedCurrency(item.type); setSelectedCurrencyObj(item)}} style={{display: 'flex', 
                    backgroundColor: ((item.type == selectedCurrency)?'#00ccb3':otherColors.header), borderRadius: 6, padding: '12px 3px', alignItems: 'center', border: "1px solid #CDD9ED"}}>
                    
                    <Images height={30} source={item.image} alt={item.name} />
                    <div className="content text-white" style={{marginLeft: 5}}>
                        <h6 className="text-white">{item.name}</h6>
                    </div>

                    <div style={{marginLeft: 'auto',}}>
                        {item.type == selectedCurrency?<RiCheckboxLine color={'#fff'} size={20}/>:<RiCheckboxIndeterminateLine color={"#fff"} size={20}/>}
                    </div>

                </div>
            </>)}
        </Slider>

        </>

        return <></>;
    }

    
    const referralCode = () => {
        if((userData?.emailVerification ?? 0) == 0){
            history.push('/signin')
            return;
        }


    }


    const openWithdrawal = () => {
        if((userData?.emailVerification ?? 0) == 0){
            history.push('/signin')
            return;
        }
        setWhichWithdrawal(0)
        setWhichModal(0)
        setOpenModal(true)
    }




const dashMenu = [
    
    {
        icon: FcBarChart,
        name: 'Fund Wallet',
        color: '#9e6af8',
        click: () => openFunding(),
        id: 1,
    },

    {
        icon: FcBullish,
        name: 'Withdraw',
        color: '#9e6af8',
        click: () => openWithdrawal(),
        id: 2,
    },
    {
        icon: FcCurrencyExchange,
        name: 'Transfer',
        color: '#9e6af8',
        click: () => transferFunding(),
        id: 3,

    },




    {
        icon: FcDebt,
        name: 'Fund records',
        color: '#9e6af8',
        to: '/deposit-records',
        id: 4,

    },

    {
        icon: FcDepartment,
        name: 'Withdrawal records',
        color: '#50c7cb',
        to: '/withdrawal-records',
        id: 5,

    },

    {
        icon: FcAreaChart,
        name: 'Transfer records',
        color: '#50c7cb',
        to: '/transfer-records',
        id: 5,

    },
    {
        icon: FcDisplay,
        name: 'Exchange',
        color: '#000428',
        to: '/p2p',
        id: 6,

    },
    
    {
        icon: FcDocument,
        name: 'My team',
        color: '#8d8ef9',
        to: '/team',
        id: 7,

    },
    {
        icon: FcLockPortrait,
        name: 'Security',
        color: '#392',
        click: () => history.push('/securities'),
        value: "******",
        id: 8
    },
    // {
    //     icon: FcReddit,
    //     name: 'Trading bots',
    //     color: '#392',
    //     click: () => history.push('/bot'),
    //     value: "******",
    //     id: 9
    // },

    {
        icon: FcLink,
        name: 'Invite your friend',
        color: '#206afc',
        click: () => history.push('/ref-link'),
        value: userData?.myrefid
    },
    {
        icon: FcMultipleSmartphones,
        name: 'Update mobile',
        color: '#206afc',
        click: () => history.push('/phone'),
        value: userData?.phone?.substring(0,2) + '****'
    },
    {
        icon: FcCurrencyExchange,
        name: 'Bind Wallet',
        color: '#9e6af8',
        click: () => history.push('/wallet'),
    },
    {
        icon: FcMultipleInputs,
        name: 'Personal information',
        color: '#f70',
        click: () => history.push('/personal-information'),
    },
    {
        icon: FcNightPortrait,
        name: 'KYC',
        color: '#f70',
        click: () => history.push('/kyc'),
    },
    {
        icon: FcRedo,
        name: 'Log out',
        color: '#f70',
        click: () => doLogOutNowNow(),
    },





]



    
    const goToVerifyForum = () => {
        setloading(true);
        setLoadingText('Accessing verified user forumn...');

        dispatch(authActions.onLogin(`join/verified-forum`, {}, response => {
            
            setloading(false);

            if(response.code != 1){
                notify(response.msg, response.code)
            }else{
                let link = response?.data?.link;
                window.location.href = link;
            }
            

        }))
    }


    const openAffilliateWithdrawal = () => {
        if((userData?.emailVerification ?? 0) == 0){
            history.push('/signin')
            return;
        }
        setWhichWithdrawal(1)
        setWhichModal(0)
        setOpenModal(true)
    }


    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ setLoadingStat(false); return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {
            setLoadingStat(false)
        }))

    }

    useEffect(() => {

        let timing = setTimeout(() => {
            loadAllHomePageData();
        }, 60000)
        
        return () => clearTimeout(timing);
    }, [])

    if (openModal) {
      // push to history when modal opens
      window.history.pushState(null, '', window.location.href)
      
      // close modal on 'back'
      window.onpopstate = () => {
        window.onpopstate = () => {}
        window.history.back()
        setOpenModal(false)
      }
    }

{console.log(userData)}
    const processWithdrawal = (e) => {
        
        e.preventDefault();

        // if(((selectedCurrencyObj.autoConfirm == 0) && (userData.bank == ''))
            // || 
        if((userData?.usdttrc20wallet?.length ?? 0) < '5'){ 
            notify('Please bind your wallet details before withdrawal.');
            return false;
        }

        if((isNaN(withdrawAmount)) || (withdrawAmount == 0)){
            // notify('Enter amount', 0);
            return false;
        }


        if(withdrawalPin == ''){
            notify('Please enter your withdrawal pin', 0);
            return false;
        }

        if(selectedCurrency == ''){
            notify('Please mark a Currency', 0);
            return false;
        }

        setloading(true);
        setLoadingText('Requesting withdrawal');

        dispatch(withdrawalActions.onCreateWithdrawal({googleAuth, withdrawalPin, amount: withdrawAmount, currency: selectedCurrency, walletAddress}, `withdrawal/new/${whichWithdrawal}`, response => {
            notify(response.msg, response.code)
            if(response.code == 1){
                setOpenModal(false)
                setWithdrawAmount('');
                // setSelectedCurrency('')
                setWithdrawalPin('');
                setGoogleAuth('');
                history.push('/withdrawal-records')
            }
            setloading(false);
        }))
    }



    const openFunding = () => {
        if((userData?.emailVerification ?? 0) == 0){
            history.push('/signin')
            return;
        }
        
        setloading(true)
        setLoadingText('Refreshing deposit records...')

        dispatch(depositActions.onLoadAllDeposits("deposit/load", response => {
            if(response.code == 1){
                setloading(false)
                setWhichModal(1)
                setOpenModal(true)
            }
        }))

    }


    const uploadSelectedPayment = (file) => {
        
        setLoadingText('Uploading payment...')
        setloading(true)

        dispatch(depositActions.onCreateDeposit({txn_id:getPendingPayment?.[0]?.txn_id, image:file}, "deposit/manual-deposit-upload", response => {
            setloading(false)
            notify(response.msg,  response.code)
        }))

    }



    const transferFunding = () => {
        if((userData?.emailVerification ?? 0) == 0){
            history.push('/signin')
            return;
        }
        
        setWhichModal(2)
        setOpenModal(true)

    }


    const processTransfer = (e) => {

        e.preventDefault();


        if(selectedCurrency == ''){
            notify('Select Currency', 0);
            return false;
        }
        
        setloading(true)
        setLoadingText('Transfering fund...')




        dispatch(authActions.onLogin("fund/transfer/new", {amount: sendingAmount, email: receiverEmail, googleAuth, withdrawalPin, currency: selectedCurrency}, response => {
            if(response.code == 1){
                setReceiverEmail('')
                setSendingAmount('')
                setWithdrawalPin('')
                setGoogleAuth('')
                // setSelectedCurrency('')
                setOpenModal(false)
            }
            setloading(false)
            notify(response.msg, response.code);    
        }))


    }


    const processDeposit = (e) => {
        
        e.preventDefault();

        if((isNaN(depositAmount)) || (depositAmount == 0)){
            notify('Enter amount', 0);
            return false;
        }

        if(selectedCurrency == ''){
            notify('Select Currency', 0);
            return false;
        }

        setloading(true)
        setLoadingText('Generating payment...')

        dispatch(depositActions.onCreateDeposit({amount: depositAmount, currency: selectedCurrency}, "deposit/new", response => {
            if(response.code == 1){
                setDepositAmount('')
            }else{
                notify(response.msg, response.code)
            }

            setloading(false)
            
        }))
    }

    const getPendingPayment = allDepositData?.filter((item, index) => item.status < 2);
    
    const deleteInv = (txn_id) => {

        setloading(true)
        setLoadingText('Deleting request...')

        dispatch(depositActions.onLoadAllDeposits(`deposit/delete/${txn_id}`, response => {
            if(response.code == 1){
                // setWhichModal(1)
                // setOpenModal(true)
            }
            setloading(false)
        }))

    }


    const totalEscrow = exchangeStoreData?.filter((item, index) => item.active == 1 ).reduce((def, arrItem) => Number(def) + Number(arrItem.crypto_amount), 0);
    const capitals = tradeStoreData?.data?.filter((item, index) => item.active == 1 ).reduce((def, arrItem) => Number(def) + Number(arrItem.capital), 0);
    const compundInterest = tradeStoreData?.data?.filter((item, index) => item.active == 1 ).reduce((def, arrItem) => Number(def) + Number(arrItem.inTrade), 0);
        
    const withdrawalChargeHolder = (whichWithdrawal == 0)?'withdrawalCharge':'withdrawalChargeRef';
    const withdrawalChargePercentage = site_settings?.plans?.[0]?.mother[withdrawalChargeHolder]

    const ayoDeleObj = [
        {name:'Balance',    data: FormatOnly(finance?.[0]?.balance ?? 0), bgColor: '#0d60d8', icon: 'fa-area-chart'},
        {name:'Affiliate',  data: FormatOnly(finance?.[0]?.commission ?? 0), bgColor: '#7913e5', icon: 'fa-arrows'},
        {name:'Exchange',   data: loadingStat?<span className="fa fa-1x fa-circle-o-notch fa-spin"></span>:FormatOnly(totalEscrow ?? 0), bgColor: '#222222', icon: 'fa-anchor'},
        {name:'Interest',      data: loadingStat?<span className="fa fa-1x fa-circle-o-notch fa-spin"></span>:FormatOnly(compundInterest ?? 0), bgColor: '#753ff6', icon: 'fa-briefcase'}
    ];



    const progClickFileUpload = () => {
        if(!userData?.image){ history.push('/signin'); return;}
        hiddenFileInput.current.click();
    }


    const progClickPaymentUpload = () => {
        uploadPayment.current.click();
    }




    const instantUploadPreview = (file, destID) => {

        let output = document.getElementById(destID);

        // if(!Array.isArray(file)){ 
            // typeof file === 'object'
        if(file.type == undefined){
            output.src = file;
        

        }else{

            output.src = URL.createObjectURL(file);
            output.onload = function() {
                  URL.revokeObjectURL(output.src) // free memory
                }

        }

    };


    const uploadAvatar = async (file) => {

        // instantUploadPreview(file, 'avatarPlace');
        setUploadingAvatar(true)

        dispatch(authActions.onLogin("account/change-avatar", {avatar:file}, response => {
                setUploadingAvatar(false)
            }),
        )
  
    }


    const openTradeModal = () => {
        setWhichModal(3)
        setOpenModal(true)
    }


    const tradeNow = () => {

      if(Number(finance?.[0]?.balance) < Number(finance?.[0]?.minimumParticipation)){
            notify('Balance does not measure up to minimum trade', 0);
            return;
      }

      if(isNaN(tradeAmount) || (tradeAmount <= 0)){
          return;
      }

      setloading(true);
      setLoadingText('Logging trade...');

      dispatch(tradeActions.onPOSTTheTrades({amount:tradeAmount, currency:selectedPlan?.type}, "trade/new", response => {
                setloading(false)
                notify(response.msg, response.code);
                if(response.code == 1){
                    setloading(false);
                    setOpenModal(false)
                    // setTradeResultResponse({...response?.data?.event ?? {}})
                    history.push('/arbitrage')
                }
                
            }),
        )

    }

    let totalFreq = 24/selectedPlan?.hoursProfitComesBack




    const saveBTNJSX = () => (
        <div style={{marginTop: 'auto'}}>
            <button type="submit" disabled={loading} className="btn btn-block btn-primary" style={{background: otherColors.btn1}}>
            Submit </button>
        </div>
        )




    const onSuccessOfManualFiatAutoPay = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        loadAllHomePageData();
    };

    // you can call this function anything
    const onCloseOfManualFiatAutoPay = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        notify('You closed payment portal', 0);
    }



    
    
    const name = userData?.username;
    const phone = userData?.phone;
    const email = userData?.email;


    // paystack

     const amount    = getPendingPayment?.[0]?.amountEnforcedToPay * 100;
    const publicKey = site_settings?.site_settings?.paystackPublicKey;
    const reference = getPendingPayment?.[0]?.txn_id;

    const componentProps = {
        email,
        amount,
        reference,
        metadata: {
          name,
          phone,
        },
        publicKey,
        text: "Pay Now"

    }

    // const initializePayment = usePaystackPayment(componentProps);


    // const payFiatWithPaystack = () => {
    //     initializePayment(onSuccessOfManualFiatAutoPay, onCloseOfManualFiatAutoPay);
    // }

    // pay with flutterwave

    const config = {
        public_key: site_settings?.site_settings?.flutterwavePublicKey,
        tx_ref: getPendingPayment?.[0]?.txn_id,
        amount: getPendingPayment?.[0]?.amountEnforcedToPay,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email,
          phonenumber: phone,
          name,
        },
        customizations: {
          title: 'my Payment Title',
          description: 'Payment for items in cart',
          logo: site_settings?.site_settings?.logo,
        },
    };

    // const handleFlutterPayment = useFlutterwave(config);


    // const payWithFlutterWave = () => {
    //     handleFlutterPayment({
    //         callback: (response) => {
    //            onSuccessOfManualFiatAutoPay()
    //             closePaymentModal() // this will close the modal programmatically
    //         },
    //         onClose: () => { onCloseOfManualFiatAutoPay(); },
    //     });
    // }



    const selectFiatAutoPay = () => {
        if(site_settings?.site_settings?.fiatAutomatedDepositMerchant == 0){ // paystack
            // payFiatWithPaystack();
        }else if(site_settings?.site_settings?.fiatAutomatedDepositMerchant == 1){ // flutterwave
            // payWithFlutterWave();
        }
    }


    const startCalculator = () => {
        setWhichModal(4)
        setOpenModal(true)

    }


    const updateCalculatorInput = (inValue) => {
        let newInput;

        if(calculatorInput == 0){
            newInput = inValue
        }else{
            newInput = String(calculatorInput) + String(inValue)
        }
        setCalculatorInput(newInput)
    }

    const calculateNow = () => {
        let roi = selectedPlanCurrencyForCalculator?.[0]?.roi
        let round = Number(selectedPlanCurrencyForCalculator?.[0]?.totalRound)
        let input = Number(calculatorInput)

        let slitRoi = roi.split(',');
        let totalRoiArray = slitRoi.reduce((def, arrItem) => Number(def) + Number(arrItem), 0);


        let result = ((totalRoiArray/100) * input)

        if(selectedPlanCurrencyForCalculator?.[0]?.releaseCapital == 1){
            result+=input;
        }

        setCalculatorResult(result);
    }


    return (

        <SiteWide title={"Account"}>
            <Section overlayText={loadingText} loading={loading} title={'Account'}>
                


                <div className="container"  style={{background: otherColors.card}}>
                    


                    <div className="container"  style={{marginTop: 100, background: 'transparent', marginBottom: 10, borderRadius: '10px 10px 0 0'}}>

                        <div style={{color: otherColors.text, padding: 20}} className="text-center">

                            <div className="lexify">
                                



                                <ul className="header-right" style={{}}>
                                    
                                    <li>
                                        <WebLink className="header-user">
                                            <Images style={{width:'60px', height:'60px'}} id="avatarPlace" title="Click to upload" className="cursor-pointer ig-thumbnail" source={((userData?.image ?? null) != null)?userData?.image:require('../images/avatar.png')} alt="avatar" />

                                        </WebLink>
                                    </li>
                                </ul>


                                <div style={{flex: 1, marginLeft: 20}}>
                                    <div className="header-user h2" style={{fontWeight: 900}}>
                                        {userData?.username?.toUpperCase()}
                                    </div>
                                    <div style={{color: '#d5d5d5'}}>
                                        {userData?.myrefid}
                                    </div>
                                </div>
                                
                            </div>

                            <div className="" style={{padding: 10}}>

                                <h4  style={{fontWeight: 800}}>Wallet Asset</h4>


                                <div className="lexify" style={{justifyContent: 'space-between'}}>


                                    <h2 style={{fontFamily: 'inherit', fontWeight: '900', marginTop: 10, color: '#fabc3c'}} className="-white">{money(finance?.[0]?.balance ?? 0, finance?.[0]?.type ?? 0)}</h2>
                                   

                                </div>



                            </div>

                        </div>

                    </div>









                    <div className="container"  style={{background: otherColors.card2, borderRadius: '10px 10px 0 0'}}>



                        <div style={{color: otherColors.text}} className="mt-40">



                            <div className="row">
                                

                                {dashMenu.map((item, index) => {

                                    if((item.id == 6) && (site_settings?.operational_settings?.useP2P == 0)){ return false;}
                                    
                                    const Icon = item.icon;

                                    return <div className="col-4 text-center" onClick={item?.click} style={{marginBottom: 60}}>
                                    <WebLink style={{}} title={item.name} to={item.to}>
                                        <button className="text-center my-btn-roun" style={{background: '#0075ff', width: 60, height: 60, borderRadius: 35}}>
                                            <Icon style={{marginTop: 'auto'}} color={otherColors.text2} size="30"/>
                                        </button>
                                        <h5 style={{fontSize: 13, marginTop: 20}} className="sall-icon-head text-info-2">{item.name}</h5>
                                    </WebLink>

                                </div>})}



                            </div>
                        </div>

                    </div>




                </div>



                <Modal
                    isOpen={openModal}
                    onRequestClose={() => setOpenModal(false)}
                    contentLabel="New trade"
                    style={customNormalModalStyles}
                    className="add-balance-inner-wrap"
                    shouldCloseOnOverlayClick={whichModal == 4?true:false}
                    shouldCloseOnEsc={whichModal == 4?true:false}
                  >


                

                    <ModalCustomHead hideHead={whichModal == 400 ?true:false} modalTitle={modalTitle[whichModal]} setOpenModal={() => setOpenModal(false)}>



                        {whichModal == 0 && <div className="actent">
                            <form onSubmit = {processWithdrawal} style={{}} action="">
                                

                                <div className="container" style={{height: '60vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                    <div>

                                        {selectACurrency()}
                                    

                                        <div class="form-group">

                                            <span className="text-info-1">Sum</span>
                                            <input type="text" className="form-control form-field" onChange = {(e) => setWithdrawAmount(e.target.value)} value={withdrawAmount} />
                                            
                                        </div>

                                        <div style={{backgroundColor: '#fff', padding: 6}}>
                                            <ul className="text-small text-left">

                                              <li className="mb-3"><i className="fa fa-check mr-2 text-info"></i> System Withdrawal charge - {withdrawalChargePercentage}%</li>
                                              <li className="mb-"><i className="fa fa-check mr-2 text-info"></i> You will be paid - {money((withdrawAmount - ((withdrawalChargePercentage/100) * withdrawAmount) < 0?0:withdrawAmount - ((withdrawalChargePercentage/100) * withdrawAmount)).toFixed(2))}</li>
                                                
                                            </ul>
                                        </div>


                                        <PinAuth userData={userData} setWithdrawalPin={setWithdrawalPin} setGoogleAuth={setGoogleAuth} />


                                    </div>
                                


                                    
                                        {saveBTNJSX()}


                                </div>

                            </form>
                        </div>}




                        {whichModal == 1 && <div className="actent">
                            {getPendingPayment?.length == 0 && <form onSubmit = {processDeposit} action="">
                                

                               
                                <div className="container" style={{height: '60vh', display: 'flex', flexDirection: 'column', ustifyContent: 'space-between'}}>
                                    <div>

                                        {selectACurrency()}

                                        <div class="form-group">
                                            <span className="text-info-1" style={{backgroundColor: otherColors.card}}>Sum {selectedCurrencyObj?.useCryptoValue?` (${selectedCurrencyObj?.symbol} value)`:''}</span>
                                            <input type="text" style={{backgroundColor: otherColors.card}} className="form-control form-field" onChange = {(e) => setDepositAmount(e.target.value)} value={depositAmount} />
                                        </div>

                                        <div style={{backgroundColor: '#fff', padding: 6}}>
                                            <ul className="text-small text-left">

                                              <li className="mb-3"><i className="text-info">Funding tip</i></li>
                                              <li className="mb-3">The minimum recharge amount is {money(selectedCurrencyObj?.minimumDeposit, selectedCurrencyObj?.id)}</li>
                                              <li className="mb-">
                                                If your recharge amount is less than the minimum 
                                                amount, recharge will be refused and refunding will be unavailable
                                              </li>
                                                
                                            </ul>
                                        </div>

                                    </div>

                                        {saveBTNJSX()}

                                </div>

                            </form>}


                            {getPendingPayment?.map((pendingPayment, pendingIndex) => {

                                let curDetails = finance?.filter((filitem, index) => filitem.type == getPendingPayment?.[0]?.currency)
                                let perfectWalletobj;
                                let eachParam;


                                if((getPendingPayment?.[0]?.createdWithAutoOrManual == 0) && (getPendingPayment?.[0]?.autoConfirm == 2)){
                                    let pmAddress = JSON.parse(getPendingPayment?.[0]?.address);
                                    eachParam = Object.keys(pmAddress.param);
                                    perfectWalletobj = pmAddress
                                }
                                
                                    return (<div className="ba-bill-pay-inner" style={{backgroundColor: otherColors.cotpBlue, border: 'none', color: otherColors.text}}>
                                        <div className="ba-single-bill-pay">
                                            <div className="thumb">
                                                <Images height={40} source={curDetails?.[0]?.image} alt="img" />
                                            </div>
                                            <div className="details" style={{width: '100%'}}>
                                                <h5 style={{display: 'flex'}}>
                                                    <span className="text-info-1">
                                                        Deposit
                                                    </span>
                                                    {getPendingPayment?.[0]?.amountEnforcedToPay != '' && getPendingPayment?.[0]?.amountEnforcedToPay != 0 && <span className="text-info-1" style={{marginLeft: 'auto'}}>
                                                        
                                                        {money(getPendingPayment?.[0]?.amountEnforcedToPay, getPendingPayment?.[0]?.currency, null, {decimals:decimalCount(getPendingPayment?.[0]?.amountEnforcedToPay)})}

                                                        <CopyToClipboard text={getPendingPayment?.[0]?.amountEnforcedToPay} onCopy={() => notify('Copied', 1)}>
                                                            <RiFileCopy2Fill className="cursor-pointer" />
                                                        </CopyToClipboard>

                                                    </span>}
                                                </h5>
                                                <p style={{display: 'flex'}}>
                                                    <span className="text-info-1">
                                                        {getPendingPayment?.[0]?.txn_id}

                                                        {" "}

                                                        <CopyToClipboard text={getPendingPayment?.[0]?.txn_id}
                                                        onCopy={() => notify('Transaction ID copied', 1)}>
                                                            <RiFileCopy2Fill className="cursor-pointer" />
                                                        </CopyToClipboard>
                                                    </span>
                                                    <span className="text-info-1" style={{marginLeft: 'auto'}}>
                                                        {moneyStatus()[getPendingPayment?.[0]?.status]}
                                                    </span>
                                                </p>
                                                <p style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <span className="text-info-1">
                                                        <CustomAgo time={getPendingPayment?.[0]?.timestamp}/>
                                                    </span>
                                                    <span>

                                                        {getPendingPayment?.[0]?.createdWithAutoOrManual == 1 && <span>
                                                            <span className="text-white" onClick={() => progClickPaymentUpload()}  style={{textDecoration: 'underline', cursor: 'pointer'}}>
                                                                <FcUpload />{(getPendingPayment?.[0]?.image == null)?'Upload':'Re-upload'} Payment
                                                            </span>
                                                            <input style={{display: 'none'}} ref={uploadPayment} onChange={(e) => uploadSelectedPayment(e.target.files[0])} type="file" accept=".jpg, .gif, .png, .jpeg"/>
                                                        </span>}

                                                        {getPendingPayment?.[0]?.createdWithAutoOrManual == 0 && getPendingPayment?.[0]?.autoConfirm == 0 && 
                                                            <span className="text-white" style={{textDecoration: 'underline', cursor: 'pointer'}} className="" onClick={() => selectFiatAutoPay()}> Pay Now </span>}

                                                        {getPendingPayment?.[0]?.createdWithAutoOrManual == 0 && getPendingPayment?.[0]?.autoConfirm == 2 && <span>
                                                            <form action={perfectWalletobj?.form_url} method="POST" style={{display: 'inline'}}>
                                                                {eachParam.map((eachkey, index) => (
                                                                        <input name={eachkey} value={perfectWalletobj.param[eachkey]} type="hidden" />
                                                                    ))}
                                                                <button className="text-white" style={{textDecoration: 'underline'}} type="submit">Pay Now</button>
                                                            </form>

                                                            </span>}

                                                             {" "}{" "}{" "}
                                                        
                                                        {getPendingPayment?.[0]?.status == 0 && <span className="text-dar" style={{textDecoration: 'underline', cursor: 'pointer', color: '#fff'}} onClick={() => window.confirm('Click OK to confirm deletion') && deleteInv(getPendingPayment?.[0]?.txn_id)}> Delete</span>}
                                                    </span>
                                                </p>
                                            </div>

                                            

                                        </div>
                                        <div className="amount-inner text-info-1">
                                            <div className="flexify" style={{marginBottom: 10}}>
                                                <h5 className="text-info-1"></h5>
                                                {(getPendingPayment?.[0]?.autoConfirm == 1) && 1>2 && (getPendingPayment?.[0]?.createdWithAutoOrManual == 0) && <small className="text-info-1">{getPendingPayment?.[0]?.blockConfirmationsAchieved} Block Confirmation</small>}
                                            </div>

                                        

                                            {getPendingPayment?.[0]?.autoConfirm == 1 && <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <div>
                                                    <h6>
                                                        <CopyToClipboard text={getPendingPayment?.[0]?.address}
                                                            onCopy={() => notify('Copied', 1)}>
                                                            <span className="text-info cursor-pointer">
                                                                {getPendingPayment?.[0]?.address} <RiFileCopy2Fill />
                                                            </span>
                                                        </CopyToClipboard>
                                                    </h6>
                                                    <small>Deposit exactly {getPendingPayment?.[0]?.amountEnforcedToPay} {getPendingPayment?.[0]?.abbr} to this address</small><br/>
                                                    <small>Send Only send {getPendingPayment?.[0]?.abbr} to this wallet</small><br/>
                                                    <small className="text-danger">{getPendingPayment?.[0]?.paymentMessage}</small><br/>


                                                        {getPendingPayment?.[0]?.creationCryptoMerchant == 3 && <small>
                                                            <Countdown
                                                                date={new Date(getPendingPayment?.[0]?.timeout)}
                                                                renderer={({ hours, minutes, seconds, completed }) => {
                                                                  if (completed) {
                                                                    return <span>Payment timeout</span>
                                                                  } else {
                                                                    return <span>{hours}:{minutes}:{seconds}</span>;
                                                                  }
                                                                }}
                                                            />
                                                        <br/></small>}

                                                    <div style={{backgroundColor: '#fff', display: 'inline-block', marginTop: 20}}>
                                                        <Images style={{fill: "red"}} src={getPendingPayment?.[0]?.qrCode} />
                                                    </div>
                                                </div>

                                                
                                            </div>}


                                            {getPendingPayment?.[0]?.autoConfirm == 0 && <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <h6 className="text-info-1">
                                                    {curDetails?.[0]?.accountNameText}
                                                </h6>

                                                <h6>
                                                    <span className="text-info cursor-pointer">
                                                        {curDetails?.[0]?.accountName}
                                                    </span>
                                                </h6>

                                                
                                            </div>}

                                            {getPendingPayment?.[0]?.autoConfirm == 0 && <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <h6 className="text-info-1">
                                                    {curDetails?.[0]?.accountNumberText}
                                                </h6>

                                                <h6 className="text-info-1">
                                                    <CopyToClipboard text={curDetails?.[0]?.accountNumber}
                                                        onCopy={() => notify('Copied', 1)}>
                                                        <span className="text-info cursor-pointer">
                                                            {curDetails?.[0]?.accountNumber}
                                                            <RiFileCopy2Fill />
                                                        </span>
                                                    </CopyToClipboard> | <span className="text-info cursor-pointer">{curDetails?.[0]?.bankName}</span>
                                                </h6>

                                                
                                            </div>}



                                        </div>
                                    </div>)
                                })}



                        </div>}




                        {whichModal == 2 && <div className="actent">
                            <form onSubmit = {processTransfer} action="">
                                
                                <div className="container" style={{height: '60vh', display: 'flex', flexDirection: 'column'}}>
                                    <div>

                                        {selectACurrency()}

                                        <div class="form-group">
                                            <span className="text-info-1">Sum</span>
                                            <input type="text" className="form-control form-field" onChange = {(e) => setSendingAmount(e.target.value)} value={sendingAmount} />
                                        </div>


                                        <div class="form-group">
                                            <span className="text-info-1">Receiver's Mail</span>
                                            <input type="text" className="form-control form-field" onChange = {(e) => setReceiverEmail(e.target.value)} value={receiverEmail} />
                                        </div>

                                
                                        <PinAuth userData={userData} setWithdrawalPin={setWithdrawalPin} setGoogleAuth={setGoogleAuth} />


                                    </div>


                                        {saveBTNJSX()}


                                </div>

                            </form>
                        </div>}



            
                        {whichModal == 3 && <div className="actent">
                            <form onSubmit = {() => tradeNow()} action="">
                                


                                <ul className="list-unstyled my-5 text-small text-left">

                                  <li className="mb-3"><i className="fa fa-check mr-2 text-info"></i> {((Number(selectedPlan?.roi) - 100) * totalFreq).toFixed(1)}%<span className="text-small font-weight-normal ml-2"> daily</span></li>
                                  <li className="mb-3"><i className="fa fa-check mr-2 text-info"></i> {money(selectedPlan?.minimumParticipation, selectedPlan?.type)} minimum trade</li>
                                  <li className="mb-3"><i className="fa fa-check mr-2 text-info"></i> {money(selectedPlan?.maximumParticipation, selectedPlan?.type)} maximum trade</li>
                                  <li className="mb-3"><i className="fa fa-check mr-2 text-info"></i> {totalFreq} rounds of Trading</li>
                                  
                                </ul>


                                <div className="form-group basic">
                                    <label className="label">Enter Amount</label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="input1">$</span>
                                        </div>
                                        <input type="text" className="form-control form-field" onChange = {(e) => setTradeAmount(e.target.value)} value={tradeAmount} />
                                    </div>
                                </div>


                                     

                                <div className="form-group basic">
                                    <button className="text-white texttext cursor-pointer">Submit <i className="fa fa-long-arrow-right"></i></button>
                                </div>
                            </form>
                        </div>}



                        {whichModal == 4 && <div className="actent">

                            <div class="calcu-container">
                                <div class="calc-body">
                                    <div class="calc-screen">
                                        <div  class="calc-operation" style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div>
                                                <Menu menuButton={<MenuButton style={{marginLeft: 10}}><RiMore2Fill color={"#fff"} /></MenuButton>} transition>
                                                    {selectedPlanCurrencyForCalculator?.map((menuItem, menuIndex) => <MenuItem><small>{menuItem?.name}</small></MenuItem>)}
                                                    <MenuItem onClick={() => setOpenModal(false)}><small>Close</small></MenuItem>
                                                </Menu>
                                            </div>
                                            <div>{calculatorInput}</div>
                                        </div>
                                        <div class="calc-typed">{calculatorResult}</div>
                                    </div>
                                    <div class="calc-button-row" style={{overflowX: 'scroll'}}>
                                        <div class="button c" onClick={() => {setCalculatorInput(0); setCalculatorResult(0)}}>C</div>
                                        
                                    </div>
                                    <div class="calc-button-row" style={{overflowX: 'scroll'}}>
                                        <div style={{width: 3000}}>
                                            {site_settings?.plans?.map((item, index) => <div style={{width: 160}} onClick={() => {setSelectedMotherCurrencyForCalculator(item.mother); setSelectedPlanCurrencyForCalculator(item?.childs)}} className={`button  l ${selectedMotherCurrencyForCalculator.type == item.mother.type?'bg-warning text-white':''}`}>{item.mother.abbr}</div>)}
                                        </div>
                                    </div>

                                    <div class="calc-button-row">
                                        <div class="button" onClick={() => updateCalculatorInput(7)}>7</div>
                                        <div class="button" onClick={() => updateCalculatorInput(8)}>8</div>
                                        <div class="button" onClick={() => updateCalculatorInput(9)}>9</div>
                                    </div>
                                    <div class="calc-button-row">
                                        <div class="button" onClick={() => updateCalculatorInput(4)}>4</div>
                                        <div class="button" onClick={() => updateCalculatorInput(5)}>5</div>
                                        <div class="button" onClick={() => updateCalculatorInput(6)}>6</div>
                                    </div>
                                    <div class="calc-button-row">
                                        <div class="button" onClick={() => updateCalculatorInput(1)}>1</div>
                                        <div class="button" onClick={() => updateCalculatorInput(2)}>2</div>
                                        <div class="button" onClick={() => updateCalculatorInput(3)}>3</div>
                                    </div>
                                    <div class="calc-button-row">
                                        <div class="button" onClick={() => updateCalculatorInput('.')}>.</div>
                                        <div class="button" onClick={() => updateCalculatorInput(0)}>0</div>
                                        <div class="button" onClick={() => calculateNow()}>=</div>
                                    </div>
                                </div>
                            </div>


                        </div>}




                    </ModalCustomHead>


                    

                </Modal>




            </Section>

        </SiteWide>
    );
}

export default Home;