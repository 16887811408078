import React, {Component, useEffect, useState} from "react";
import Section from "./layouts/Section";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {WebLink, SiteWide} from "./customComponents";
import {authActions} from '../redux/actions';
import { RiFacebookLine, RiStackshareLine, RiDownloadCloud2Fill, RiUploadCloud2Fill, RiFileCopy2Line, RiTelegramLine, RiWhatsappLine } from "react-icons/ri";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Helper1, {notify, styles, money, FormatOnly, customNormalModalStyles, moneyStatus} from '../utility/Helper1';
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';
import Modal from 'react-modal';
import {Images} from "./customComponents";
import { RWebShare } from "react-web-share";





function Social(){

    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');
    const [socialPosts, setSocialPosts]   = useState([]);
    
    const [screenShotLink, setScreenShotLink]   = useState('');
    const [screenShot, setScreenShot]   = useState('');
    const [file, setFile]   = useState('');
    const [openModal, setOpenModal]   = useState('');

    const hiddenFileInput = React.useRef(null);

    const bakground = require('../images/space.jpg');
    const dispatch = useDispatch();

    const site_settings = useSelector(allRemoteSettingsSelect);



    const progClickFileUpload = () => {
        hiddenFileInput.current.click();
    }

    const submitSocial = () => {

        if(file == ''){
            notify('Select screenshot', 0);
            return;
        }

        setloading(true);
         dispatch(authActions.onLogin("social/posts/proof", {screenShotLink, image:file}, response => {
            setloading(false);
            setSocialPosts(response.data.social)
            if(response.code == 1){
                notify('Successful', 1);
                setOpenModal(false)
                setFile('')
                setScreenShotLink('')
            }

        }),
        );

    }


    useEffect(() => {
        setloading(true);
         dispatch(authActions.onRefreshSystemData("social/posts", 100, {}, response => {
            setloading(false);
            setSocialPosts(response.data.social)

        }),
        );
    }, [])

    return (
        <SiteWide id="main" title={`Social`}>
            <Section hideHeader={true} title={'Social'} loading={loading} overlayText={loadingText}>
                <div className="container-inner">
                    <div className="container-fluid">
                        <div className="row flex-row">

                        
                            <div className="col-xl-12">
                                {/* Basic Tabs */}

                                {socialPosts?.map((item, index) => {
                                
                                let Icon = `Ri${(item.platform.charAt(0).toUpperCase()) + (item.platform.slice(1))}Line`

                                return <div className="widget has-shadow" style={{backgroundColor: 'rgba(255, 255, 255, 0.8)'}}>
                                    <div style={{borderBottom: '1px solid #cecece', backgroundColor: 'rgba(255, 255, 255, 0.8)'}} className="bg-ino widget-header bordered no-actions">
                                        <h6 className="text-dark" style={{display: 'flex', flexDirection: 'row'}}>
                                            <span>
                                                <RWebShare
                                                    data={{
                                                      text: item?.message,
                                                      title: "Share",
                                                    }}
                                                    onClick={() => console.log("shared successfully!")}
                                                  >
                                                    <h5 classNames="h1">Share <RiStackshareLine size={15}/></h5>
                                                </RWebShare>
                                            </span>
                                            {item.image2 && item.image2 != '' && <span style={{marginLeft: 'auto'}}>
                                                <a href={item?.image2}>
                                                    <RiDownloadCloud2Fill size={20} />
                                                </a>
                                            </span>}
                                        </h6>
                                    </div>
                                    <div className="widget-body sliding-tabs">
                                        
                                        <div className="tab-content pt-3">
                                            <div className="text-dark tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="base-tab-1">
                                                {item?.message}
                                            </div>
                                        </div>
                                    </div>

                                </div>}
                                )}
                            </div>

                        </div>
                        {/* End Row */}
                    </div>
                </div>



                <div id="container-floating">

                  <div onClick={() => setOpenModal(true)} id="floating-button" style={{backgroundColor: otherColors.cotpBlue}}>
                    <p class="plus">+</p>
                    <img class="edit" src="https://ssl.gstatic.com/bt/C3341AA7A1A076756462EE2E5CD71C11/1x/bt_compose2_1x.png"/>
                  </div>
                </div>







                    <Modal
                        isOpen={openModal}
                        onRequestClose={() => setOpenModal(false)}
                        contentLabel="New P2P"
                        style={customNormalModalStyles}
                        className="add-balance-inner-wrap"
                        shouldCloseOnOverlayClick={true}
                        shouldCloseOnEsc={true}
                      >

                        <div className="modal-content">
                            <div className="modal-body">

                                <form className="contact-form-wrap">

                                    <div class="form-group">
                                        <input type="text" className="form-control form-field" onChange = {(e) => setScreenShotLink(e.target.value)} value={screenShotLink} />
                                        <span className="label">Page link</span>
                                    </div>

                                    <div class="form-group">
                                        <span style={{width: '100%'}} onClick={() => progClickFileUpload()}>
                                            <RiUploadCloud2Fill /> select screenshot
                                        </span>
                                        <input style={{display: 'none'}} ref={hiddenFileInput} onChange={(e) => setFile(e.target.files[0])} type="file" accept=".jpg, .gif, .png, .jpeg"/>
                                    </div>

                                    <button onClick={submitSocial} type="submit" disabled={loading} className="btn btn-block btn-primary" style={{backgroundColor: otherColors.cotpBlue}}>
                                        Upload </button>
                                </form>

                            </div>
                        </div>


                    </Modal>




            </Section>
        </SiteWide>
    );

}

export default Social;