import React, {useState, useEffect, useRef} from "react";
import Section from "./layouts/Section";
import CustomAgo from "./customComponents/CustomAgo";
import {WebLink, SiteWide, Images} from "./customComponents";
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, exchangeSelect } from '../redux/selectors/index';
import Helper1, {notify, styles, money, customModalStyles, customNormalModalStyles, moneyStatus, usefulColors} from '../utility/Helper1';
import Countdown from 'react-countdown';
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { RiChat3Fill, RiUpload2Fill, RiImageFill, RiRecordCircleFill, RiArrowGoBackLine } from "react-icons/ri";
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';





const balanceSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,

        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
        }]
    };


function Swap() {

    const dispatch = useDispatch();
    const hiddenFileInput = React.useRef(null);
    const history = useHistory();
    const chooseCurrency = useRef();

    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');
    const site_settings = useSelector(allRemoteSettingsSelect);

    const allExchangers = useSelector(exchangeSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [uSDTAmount, setUSDTAmount]   = useState('');
    const [nairaAmount, setNairaAmount]   = useState('');
    const [buyOrSell, setBuyOrSell]   = useState('');

    const oneDollarToNaira = site_settings?.operational_settings?.swapNairaToOneDollarExchangeRate;

    const calculateOther = (editingNaira, inValue) => {

        let finalNaira, finalUSDT;

        if(editingNaira == 1){
            finalNaira = inValue;
            finalUSDT = (inValue/oneDollarToNaira).toFixed(2);
            setUSDTAmount(finalUSDT)
        }else{
            finalUSDT = inValue;
            finalNaira = inValue * oneDollarToNaira;
            setNairaAmount(finalNaira)
        }
    }



    const updateNairaAmount = (value) => {
        if(!isNaN(value)){ setNairaAmount(value) }
        calculateOther(1, value)
    }

    const updateUSDTAmount = (value) => {
        if(!isNaN(value)){ setUSDTAmount(value) }
        calculateOther(2, value)
    }
    
    const swapMyLife = (swappingFrom) => {

        setloading(true)
        setLoadingText('Submitting exchange request...')

        dispatch(authActions.onLogin("swap/new", {uSDTAmount, nairaAmount, swappingFrom}, response => {
            
            if(response.code == 1){
                setUSDTAmount('')
                setNairaAmount('')
            }

            notify(response.msg, response.code)
            setloading(false)
            
        }))

    }


    const nairaPlace = () => {
        return <div class="form-group">
                    <span className="t-group-text" id="iput1">NGN</span>
                    <input type="text" className="text-left form-field" value={nairaAmount} onChange={(e) => updateNairaAmount(e.target.value)} placeholder="Naira sum" />
                </div>
    }


    const usdtPlace = () => {
        return <div class="form-group">
            <span className="t-group-text" id="iput1">USDT</span>
            <input type="text" className="text-left form-field" value={uSDTAmount} onChange={(e) => updateUSDTAmount(e.target.value)} placeholder="USDT sum" />
            </div>
    }

    const currencySliderArray = (refToUse) => {
        let arrowd;

        arrowd = finance?.length > 1 && <div>
            <BsArrowLeftSquare onClick={() => refToUse.current.slickPrev()} className="cursor-pointer" size={20}/> {" "}
            <BsArrowRightSquare onClick={() => refToUse.current.slickNext()} className="cursor-pointer" size={20}/>
        </div>

        return arrowd;
    }




    return (
        <SiteWide id="main" title={"Swap"}>
            <Section overlayText={loadingText} loading={loading} title={'Swap'}>
            

                <div className="container">
                   

                   <div style={{marginTop: 120, padding: 10, backgroundColor: otherColors.cotpBlue, borderRadius: 10}}>
                        
                        <div>
                            <h3 className="text-info-1">{site_settings?.operational_settings?.swapHeadline}</h3>
                        </div>

                        <hr />
                        <div className="text-info-1">
                            {site_settings?.operational_settings?.swapContent}
                        </div>

                    </div>

                </div>


                <div className="container">
                    
                    <div  style={{marginTop: 20}}>


                        <div className="head text-info-1" style={{display: 'flex', justifyContent: 'space-between'}}>

                            <h3 className="text-info-1">Swap</h3>
                            {currencySliderArray(chooseCurrency)}

                        </div>

                        <Slider ref={chooseCurrency} arrows={false} {...balanceSettings}>
                            {finance?.map((item, index) => <>


                                <form className="contact-form-wrap">

                                    {item.autoConfirm == 0 && nairaPlace()}
                                    {item.autoConfirm == 1 && usdtPlace()}

                                    <div className="text-center text-info-1">
                                        -TO-
                                    </div>

                                    {item.autoConfirm == 1 && nairaPlace()}
                                    {item.autoConfirm == 0 && usdtPlace()}

                                    <button onClick={() => swapMyLife(item.type)} type="submit" disabled={loading} className="btn btn-block btn-primary" style={{backgroundColor: otherColors.btn1, marginTop: 50}}>
                                        OK</button>
                                </form>
                            </>)}

                        </Slider>

                    </div>

                </div>




            </Section>
        </SiteWide>
    );

}

export default Swap;