import React, {Component} from "react";
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import AutoNotification from "./AutoNotification";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
import {mainStyle, mainColor, header, footer, otherColors} from '../../utility/styles';

function Section(props){

    return (

        <section className="main-section" style={{...props.sectionstyle, background: mainColor, paddingTop: props?.paddingTop ?? 120}}>

            {!props.hideHeader && <Header back={props.back} title={props.title} rightTopMenu={props.rightTopMenu} home={props.home} searchPopup={props.searchPopup} />}
            
            {props.children}
            
            <br />

            <Footer />

            {props.loading && <LoadingOverlay
                active={props.loading}
                spinner={<BounceLoader />}
                text={(props.overlayText ?? '' !='') ?props.overlayText: 'Requesting...'}
                styles={{


                        overlay: (base) => ({
                          ...base,
                          background: 'rgba(255, 255, 255, 0.9)',
                          borderRadius: 15,
                          color: '#000',
                        }),

                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            color: '#000',
                            '& svg circle': {
                                    stroke: 'rgba(255, 255, 255, 0.9)'
                                }
                        }),
                        wrapper: {
                            width: '250px',
                            height: '250px',
                            position: props.loading?'fixed':'none',
                            zIndex: '999999999999999999999999',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#000'
                        }
                    }}
            >
            </LoadingOverlay>}
        </section>
    );

}

export default Section;