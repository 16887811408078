import React, {useState, useEffect} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import AutoNotification from "./layouts/AutoNotification";
import Section from "./layouts/Section";
import {WebLink, SiteWide, Images} from "./customComponents";
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';

import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, allTradeSelect } from '../redux/selectors/index';
import Helper1, {notify, styles, money, customModalStyles} from '../utility/Helper1';
import { useSnackbar } from 'snackfy';
import Countdown from 'react-countdown';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ReactTimeAgo from 'react-time-ago'
import Slider from "react-slick";
import { useHistory } from "react-router-dom";


function Bot() {

    const history = useHistory();
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const tradesData = useSelector(allTradeSelect);

    const [openModal, setOpenModal]   = useState(false);
    const [tradeAmount, setTradeAmount]   = useState('');

    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');
    const [selectedPlan, setSelectedPlan]   = useState({});
    const [tradeResultResponse, setTradeResultResponse]   = useState();

    const dispatch = useDispatch();

    const buyABot = (botId) => {
      setloading(true);
      setLoadingText('Preparing purchase');

      dispatch(authActions.onRefreshSystemData(`bot/new/${botId}`, 100, {}, response => {
            setloading(false)
            notify(response.msg, response.code);    
        }))

    }
    
    const manageBot = () => {
      setloading(true);
      if(userData?.robot?.length == 0){ notify('You must have active bot to take this action', 0); return; }
      
      setLoadingText('Executing command...');


      dispatch(authActions.onRefreshSystemData(`bot/manage`, 100, {}, response => {
            setloading(false)
            notify(response.msg, response.code);    
        }))

    }
    

    

    return (
        <SiteWide title={"Buy a Bot"}>
            <Section overlayText={loadingText} loading={loading} title={'Buy a Bot'}
              rightTopMenu={<div className="text-right">
                    <span onClick={manageBot} className={`cursor-pointer bg-${userData.pauseTradingBot == 0?'info':'success'}`} style={{padding: 5, borderRadius: 5}}>{userData?.pauseTradingBot == 0?'Disable':'Enable'} Bot</span>
                  </div>}
            >

              <div className="container p5" style={{padding: 25}}>

                <div style={{marginTop: 90}}>

                  

                  <div className="row">

                    {site_settings?.bots?.map((item, index) => {

                      const existedRobot = userData?.robot?.filter((existeItem, index) => item.id == existeItem.tradingBotId)

                      return <div style={{backgroundColor: otherColors.cotpBlue, marginBottom: 10}} className={`bot_container bot_container ${index+1} col-sm-12 col-12`}>
                        <div className="inner_bot_container">
                            <div className="title h2 text-dark-1"><span style={{color: '#fff'}}>{item.name}</span></div>

                            <div className="text-center txt-white">Trades between <br /> ${item.minimum} - ${item.maximum}</div><br />

                            <div className="main_number" style={{color: '#fff'}}><sup><small style={{fontSize: 26}}>$</small></sup>{item.price}</div>
                            <div className="bot_container_text">
                                <div className="bot_container_text2"></div>
                            </div>
                        </div>
                        <WebLink onClick={() => buyABot(item.id)}><span>{existedRobot?.length > 0?<span>{userData.pauseTradingBot == 0 ?'Active':'Diabled'}</span>:'Buy'}</span></WebLink>
                    </div>})}
                    

                  </div>

                </div>

              </div>


            </Section>
        </SiteWide>
    );

}

export default Bot;