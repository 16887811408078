import React, {Component} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {Images} from "./customComponents";
import parse from 'html-react-parser';
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';

    

function Term(){

    const site_settings = useSelector(allRemoteSettingsSelect);


    return (
        <SiteWide id="main" title={"About us"}>
            <Section  hideHeader={true} title={'About'}>
                {/* transaction start */}
                <div className="transaction-area pd-top-36">
                    <div className="container">

                        <div className="about-content-inner" style={{backgroundColor: otherColors.card2}}>
                            <h5>Terms and condition</h5><br /><br />
                            <p>{parse(site_settings?.site_settings?.term ?? '')}</p>
                        </div>
                        
                    </div>
                </div>
            </Section>
        </SiteWide>
    );

}

export default Term;