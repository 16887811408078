import React, {useState, useEffect, useRef} from "react";
import Section from "./layouts/Section";
import CustomAgo from "./customComponents/CustomAgo";
import {WebLink, SiteWide, Images} from "./customComponents";
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, exchangeSelect } from '../redux/selectors/index';
import Helper1, {notify, styles, money, customModalStyles, customNormalModalStyles, moneyStatus, usefulColors} from '../utility/Helper1';
import Countdown from 'react-countdown';
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { RiChat3Fill, RiUpload2Fill, RiImageFill, RiRecordCircleFill, RiArrowGoBackLine } from "react-icons/ri";
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';





const balanceSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,

        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 1
            }
        }]
    };


function Bonus() {

    const dispatch = useDispatch();
    const hiddenFileInput = React.useRef(null);
    const history = useHistory();
    const chooseCurrency2 = useRef();
    const chooseCurrency1 = useRef();

    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');
    const site_settings = useSelector(allRemoteSettingsSelect);

    const allExchangers = useSelector(exchangeSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [uSDTAmount, setUSDTAmount]   = useState('');
    const [nairaAmount, setNairaAmount]   = useState('');
    const [buyOrSell, setBuyOrSell]   = useState('');

    const oneDollarToNaira = site_settings?.operational_settings?.swapNairaToOneDollarExchangeRate;

    const calculateOther = (editingNaira, inValue) => {

        let finalNaira, finalUSDT;

        if(editingNaira == 1){
            finalNaira = inValue;
            finalUSDT = (inValue/oneDollarToNaira).toFixed(2);
            setUSDTAmount(finalUSDT)
        }else{
            finalUSDT = inValue;
            finalNaira = inValue * oneDollarToNaira;
            setNairaAmount(finalNaira)
        }
    }



    const updateNairaAmount = (value) => {
        if(!isNaN(value)){ setNairaAmount(value) }
        calculateOther(1, value)
    }

    const updateUSDTAmount = (value) => {
        if(!isNaN(value)){ setUSDTAmount(value) }
        calculateOther(2, value)
    }
    
    const processBonusWithdrawal = (swappingFrom) => {


        setloading(true);
        setLoadingText('Requesting bonus...');

        dispatch(authActions.onLogin(`withdraw/bonus`, {}, response => {
            notify(response.msg, response.code)
            if(response.code == 1){
                
            }
            setloading(false);
        }))

    }


    const nairaPlace = () => {
        return <div class="form-group">
                    <span className="t-group-text" id="iput1">NGN</span>
                    <input type="text" className="text-left form-field" value={nairaAmount} onChange={(e) => updateNairaAmount(e.target.value)} placeholder="Naira sum" />
                </div>
    }


    const usdtPlace = () => {
        return <div class="form-group">
            <span className="t-group-text" id="iput1">USDT</span>
            <input type="text" className="text-left form-field" value={uSDTAmount} onChange={(e) => updateUSDTAmount(e.target.value)} placeholder="USDT sum" />
            </div>
    }

    const currencySliderArray = (refToUse2, refToUse1) => {
        let arrowd;

        arrowd = finance?.length > 1 && <div>
            <BsArrowLeftSquare onClick={() => {refToUse2.current.slickPrev(); refToUse1.current.slickPrev()}} className="cursor-pointer" size={20}/> {" "}
            <BsArrowRightSquare onClick={() => {refToUse2.current.slickNext(); refToUse1.current.slickNext()}} className="cursor-pointer" size={20}/>
        </div>

        return arrowd;
    }




    return (
        <SiteWide id="main" title={"Deposit Bonus"}>
            <Section overlayText={loadingText} loading={loading} title={'Deposit Bonus'}>
            

                <div className="container">
                   

                   <div style={{marginTop: 120, padding: 10, backgroundColor: otherColors.cotpBlue, borderRadius: 10}}>
                        
                        <div>
                            <h3 className="text-info-1">{site_settings?.operational_settings?.depositBonusHeadline}</h3>
                        </div>


                        <hr />
                        <div className="text-info-1">
                            {site_settings?.operational_settings?.depositBonusContent}
                        </div>

                    </div>

                    


                    <div className="container" style={{marginTop: 30, backgroundColor: otherColors.cotpBlue, borderRadius: 10}}>
                        

                        <div className="head text-info-1" style={{display: 'flex', justifyContent: 'space-between'}}>
                            <h4 className="text-white">Bonus Point</h4>

                            {currencySliderArray(chooseCurrency1, chooseCurrency2)}

                        </div>

                    
                        <br />

                        <div>
                            <Slider ref={chooseCurrency2} arrows={false} {...balanceSettings}>
                                
                                {finance?.slice(0, 6)?.map((bonItem, index) => <div className="container"><div className="row" style={{backgroundColor: otherColors.cotpBlue, padding: 10, display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                                    

                                    <div className="flexify" style={{width: '100%',
                                        backgroundColor: otherColors.header, borderRadius: 6, padding: '12px 3px', alignItems: 'center', border: "1px solid #CDD9ED"}}>
                                        
                                        
                                        <div className="content text-white" style={{marginLeft: 5}}>
                                        <Images height={30} source={bonItem.image} alt={bonItem.name} />
                                            <h6 className="text-white">{bonItem.abbrRo}</h6>
                                        </div>

                                        <div style={{color: '#fff'}}>
                                            {money(bonItem?.depositBonus ?? 0, bonItem?.type ?? 0)}
                                        </div>

                                    </div>
                                    
                                </div></div>)}

                            </Slider>

                            <Slider ref={chooseCurrency1} arrows={false} {...balanceSettings}>
                                
                                {finance?.slice(6, 50)?.map((bonItem, index) => <div className="container"><div className="row" style={{backgroundColor: otherColors.cotpBlue, padding: 10, display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                                    

                                    <div className="flexify" style={{width: '100%',
                                        backgroundColor: otherColors.header, borderRadius: 6, padding: '12px 3px', alignItems: 'center', border: "1px solid #CDD9ED"}}>
                                        
                                        
                                        <div className="content text-white" style={{marginLeft: 5}}>
                                        <Images height={30} source={bonItem.image} alt={bonItem.name} />
                                            <h6 className="text-white">{bonItem.abbrRo}</h6>
                                        </div>

                                        <div style={{color: '#fff'}}>
                                            {money(bonItem?.depositBonus ?? 0, bonItem?.type ?? 0)}
                                        </div>

                                    </div>
                                    
                                </div></div>)}

                            </Slider>


                        </div>
                        

                        <br /><br />

                        <button onClick={() => processBonusWithdrawal()} style={{backgroundColor: '#fff'}}  className={`btn-block b-white btn cursor-pointer`}>
                            Claim recharge bonus
                        </button>


                    </div>

                </div>


            </Section>
        </SiteWide>
    );

}

export default Bonus;