import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Helper1, {notify, niceDate, money} from '../utility/Helper1';
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';
import CustomAgo from "./customComponents/CustomAgo";

import { useSelector, useDispatch } from 'react-redux';
import {authActions, withdrawalActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import LoadingOverlay from 'react-loading-overlay';
import {Images} from "./customComponents";

import {RiMore2Fill} from "react-icons/ri";

import {
    Menu,
    MenuItem,
    MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

const topVerticalMenu = [
    {id:1, title:"Today's profit"},
    {id:2, title: "Total deposits"},
    {id:3, title: "Total withdrawal"},
    // {id:4, title: "New registration"},
]


function TeamEarning() {    
    const [loadingText, setLoadingText]   = useState('Loading netork...');
    const [loading, setLoading]         = useState(false)
    const [dataForLevel1, setLevel1]    = useState([])
    const [dataForLevel2, setLevel2]    = useState([])
    const [dataForLevel3, setLevel3]    = useState([])
    const [viewingForLevel1, setViewingForLevel1]   = useState(0)
    const [viewingForLevel2, setViewingForLevel2]   = useState(0)
    const [viewingForLevel3, setViewingForLevel3]   = useState(0)
    const [viewingLevel, setViewingLevel]     = useState(1)
    const [focusedTopMenu, setFocusedTopMenu]     = useState(1)

    const [refData, setRefData]     = useState([])
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);


    const dispatch = useDispatch();

    const loadDownLines = (myrefid, level) => {
        
        setLoading(true)

        dispatch(authActions.onRefreshSystemData(`user/referral/${myrefid}`, 10, {}, response => {
            

            setLoading(false)

            setRefData(response?.data?.referrals)

        }))
    }
    
    useEffect(() => {
        loadDownLines(userData?.myrefid, 1);
    }, [])



    const processRefWithdrawal = () => {
        

        setLoading(true);
        setLoadingText('Receiving...');

        dispatch(authActions.onLogin(`withdraw/ref`, {currency: finance?.[0]?.type}, response => {
            notify(response.msg, response.code)
            if(response.code == 1){
                
            }
            setLoading(false);
        }))
    }





    const userData = useSelector(userSelect);


    const affiliatePlace = () => {
        return <div className="col-12">
            <table className="text-center" style={{backgroundColor: '#262a35', border: 'none'}} className={`table triped ordered hover`}>
              <thead style={{border: 'none', backgroundColor: '#000'}}>
                <tr>
                  <th className="text-left" style={{border: 'none', height: 20}}><span className="text-white">Username</span></th>

                  <th className="text-right" style={{border: 'none', height: 20}}><span className="text-white">{topVerticalMenu?.filter((item) => item.id == focusedTopMenu)?.[0]?.title}</span></th>
                </tr>
              </thead>
              <tbody style={{border: 'none'}}  className="text-white">

                {refData['level'+viewingLevel]?.map((eachdownline, index) => (
                <tr style={{border: 'none'}}>
                    <td className="text-left" style={{border: 'none'}}><span>{eachdownline?.username}</span></td>
                    <td className="text-right" style={{border: 'none'}}>
                        {finance.filter((item) => item.autoConfirm == 0).map(() => <>
                            {focusedTopMenu == 1 && eachdownline?.profitToday0} 
                            {focusedTopMenu == 2 && eachdownline?.totalDeposit0} 
                            {focusedTopMenu == 3 && eachdownline?.totalWithdrawal0} 
                        </>)}
                        {" "}|{" "}
                        {finance.filter((item) => item.autoConfirm == 1).map(() => <>
                            {focusedTopMenu == 1 && eachdownline?.profitToday1}
                            {focusedTopMenu == 2 && eachdownline?.totalDeposit1} 
                            {focusedTopMenu == 3 && eachdownline?.totalWithdrawal1} 
                        </>)}
                    </td>
                </tr>
                ))}
                

              </tbody>
            </table>
        </div>
    }


    return (<SiteWide id="main" title={`Account wallet`}>
            <Section loading={loading} overlayText={loadingText} title={'Account wallet'}>

            <div className="">
               

                <div className="" style={{}}>
                    <div className="row">
                        {[1,2,3,4].map((item, index) => <div className="col-3 text-center">
                                <button onClick={() => setViewingLevel(item)} style={{backgroundColor: viewingLevel == item?otherColors.cotpBlue:'transparent'}} className={`text-dark-1 btn-block levelBTN`}>LV{item}</button>
                            </div>)}
                    </div>
                </div>





                <div className="container" style={{marginTop: 30, padding: 0}}>
                    <div className="row">
                        
                        {affiliatePlace()}
                        
                            
                    </div>
                </div>

                        
                    
            </div>
        </Section>
        </SiteWide>
    );

}

export default TeamEarning;