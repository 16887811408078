import React, {Component} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {Images} from "./customComponents";
import parse from 'html-react-parser';
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';

    

function About(){

    const site_settings = useSelector(allRemoteSettingsSelect);

    return (
        <SiteWide id="main" title={"About us"}>
            <Section title={'About'}>
                {/* transaction start */}
                    <div className="container">

                        <div style={{padding: 15, backgroundColor: otherColors.card2}}>

                            {parse(site_settings?.content_settings?.[0]?.con1)}

                        </div>

                    </div>
                {/* transaction End */}
            </Section>
        </SiteWide>
    );

}

export default About;