import React, {useState, useEffect, useRef} from "react";
import Footer from "./layouts/Footer";
import {WebLink, SiteWide, Images} from "./customComponents";
import Ticker1 from "./customComponents/Ticker1";
import Ticker2 from "./customComponents/Ticker2";

import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';
import Slider from "react-slick";
import ModalCustomHead from "./customComponents/ModalCustomHead";
import Colors from '../utility/Colors';
import {homepage, contact_us} from '../utility/APIRequests';
import Section from "./layouts/Section";
import LoadingOverlay from 'react-loading-overlay';

import { useSelector, useDispatch } from 'react-redux';
import { exchangeSelect, allTradeSelect, allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import {authActions, withdrawalActions, depositActions, tradeActions} from '../redux/actions';

import AutoNotification from "./layouts/AutoNotification";
import NewsTicker, {Directions} from 'react-advanced-news-ticker';
import Helper1, {capitalizeFirstLetter, niceDate, notify, styles, money, FormatOnly, customNormalModalStyles, moneyStatus, currencySliderArray} from '../utility/Helper1';
import { useHistory } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { RiCheckboxIndeterminateLine, RiArrowRightLine, RiHome6Line, RiMore2Fill, RiCalculatorLine, RiArrowDropRightLine, RiLogoutBoxLine, RiLogoutBoxRLine, RiVolumeUpFill, RiNewspaperLine, RiIncreaseDecreaseLine, RiFundsFill, RiGiftFill, RiSwapBoxFill, RiMapPinRangeFill, RiTelegramFill, RiWhatsappFill, RiStockFill, RiRemoteControlFill, RiInstallFill, RiKeyboardFill, RiCheckboxLine, RiArrowLeftLine, RiShareForward2Fill, RiBattery2ChargeLine, RiBatteryShareFill, RiCastLine, RiDownload2Line, RiNotification3Line, RiArrowDownCircleFill, RiMailCheckFill, RiErrorWarningFill, RiDeleteBin2Fill, RiMoneyDollarCircleLine, RiFileCopy2Fill, RiGiftLine, RiSecurePaymentLine, RiRecordCircleFill, RiShieldCrossLine, RiUser4Fill, RiExternalLinkFill, RiContactsBookFill,
RiWallet3Fill, RiCalendarTodoFill, RiArrowGoBackLine, RiGasStationFill, RiBankCard2Line, RiShieldUserFill, RiTeamFill, RiTicket2Line, RiUserAddFill,
RiShieldStarFill, RiShieldUserLine, RiSideBarFill, RiBodyScanLine, RiOpenArmLine, RiStarSmileLine
 } from "react-icons/ri";

import {FcBookmark, FcBriefcase, FcBrokenLink, FcUpload, FcBusinessContact, FcCableRelease, FcDiploma2} from "react-icons/fc";

import CustomAgo from "./customComponents/CustomAgo";


import Modal from 'react-modal';
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import Header from './layouts/Header'
import Ticker from 'react-ticker'
import Marquee from 'react-marquee-master';


import {
    Menu,
    MenuItem,
    MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { AiOutlineArrowDown, AiOutlineArrowsAlt, AiOutlineBranches, AiFillCaretRight } from "react-icons/ai";



const modalTitle = [
    'Withdraw',
    'Recharge',
    'Transfer',
    'Trade',
    'Calculator',
]

const menuIcons = [

    {
        images:'dash_5.png',
        info:'FAQ',
        linked: 'faq'
    },
    {
        images:'dash_6.png',
        info:'About',
        linked: 'about'
    },
    
    
    
]



    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },,
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
    };



const balanceSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,

        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
        }]
    };

const balanceSettings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,

        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 1
            }
        }]
    };





    const accountSettingsSliders = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4.5,
        slidesToScroll: 3,
        autoplay: false,
    };





const bg = require('../images/cardimgfree.png');

function Home() {



    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);


    const [uploadingAvatar, setUploadingAvatar]           = useState(false);

    const history = useHistory();
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const exchangeStoreData = useSelector(exchangeSelect);
    const tradeStoreData = useSelector(allTradeSelect);

    const [openModal, setOpenModal]   = useState(false);
    const [whichModal, setWhichModal]   = useState(1);
    const [loadingStat, setLoadingStat]   = useState(true);

    const [withdrawAmount, setWithdrawAmount]   = useState('');
    const [withdrawalPin, setWithdrawalPin]     = useState('');
    const [walletAddress, setWalletAddress]     = useState('');
    const [whichWithdrawal, setWhichWithdrawal]     = useState(0);

    const [selectedMotherCurrencyForCalculator, setSelectedMotherCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.mother);
    const [selectedPlanCurrencyForCalculator, setSelectedPlanCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.childs);
    const [calculatorInput, setCalculatorInput]     = useState(0);
    const [calculatorResult, setCalculatorResult]     = useState(0);

    const allDepositData = useSelector(allDepositSelect);
    const [depositAmount, setDepositAmount]   = useState('');
    const [selectedCurrency, setSelectedCurrency]   = useState('');
    const [selectedCurrencyObj, setSelectedCurrencyObj]   = useState('');


    const [receiverEmail, setReceiverEmail]     = useState('');
    const [sendingAmount, setSendingAmount]     = useState('');


    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');

    const [tradeAmount, setTradeAmount]   = useState('');
    const [selectedPlan, setSelectedPlan]   = useState(site_settings?.plans?.[0]?.childs?.[0]);
    
    const balanceref2 = useRef();
    const balanceref1 = useRef();
    const chooseCurrency1 = useRef();
    const chooseCurrency2 = useRef();

const loadJson = fetch('assets/data/data.json')

    const dispatch = useDispatch();

    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())
        notify('Logout successful', 1)
    }

    const selectACurrency = () => {
        
        let value = <><div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 10}}>
            {currencySliderArray(chooseCurrency2, chooseCurrency1, finance)}
        </div>

        <Slider ref={chooseCurrency2} arrows={false} {...balanceSettings2}>
            {finance?.slice(0, 6)?.map((item, index) => <>
                <div onClick={() => {setSelectedCurrency(item.type); setSelectedCurrencyObj(item)}} style={{display: 'flex', 
                    backgroundColor: ((item.type == selectedCurrency)?'#00ccb3':otherColors.header), borderRadius: 6, padding: '12px 3px', alignItems: 'center', border: "1px solid #CDD9ED"}}>
                    
                    <Images height={30} source={item.image} alt={item.name} />
                    <div className="content text-white" style={{marginLeft: 5}}>
                        <h6 className="text-white">{item.name}</h6>
                    </div>

                    <div style={{marginLeft: 'auto',}}>
                        {item.type == selectedCurrency?<RiCheckboxLine color={'#fff'} size={20}/>:<RiCheckboxIndeterminateLine color={"#fff"} size={20}/>}
                    </div>

                </div>
            </>)}
        </Slider>

        <Slider ref={chooseCurrency1} arrows={false} {...balanceSettings2}>
            {finance?.slice(6, 50)?.map((item, index) => <>
                <div onClick={() => {setSelectedCurrency(item.type); setSelectedCurrencyObj(item)}} style={{display: 'flex', 
                    backgroundColor: ((item.type == selectedCurrency)?'#00ccb3':otherColors.header), borderRadius: 6, padding: '12px 3px', alignItems: 'center', border: "1px solid #CDD9ED"}}>
                    
                    <Images height={30} source={item.image} alt={item.name} />
                    <div className="content text-white" style={{marginLeft: 5}}>
                        <h6 className="text-white">{item.name}</h6>
                    </div>

                    <div style={{marginLeft: 'auto',}}>
                        {item.type == selectedCurrency?<RiCheckboxLine color={'#fff'} size={20}/>:<RiCheckboxIndeterminateLine color={"#fff"} size={20}/>}
                    </div>

                </div>
            </>)}
        </Slider>

        </>

        return value;
    }

    
    const referralCode = () => {
        if((userData?.emailVerification ?? 0) == 0){
            history.push('/signin')
            return;
        }


    }

    const copyRefLink = () => {
        

        if((userData?.emailVerification ?? 0) == 0){
            notify('Please log in', 0)
            return;
        }

        notify('Referral Link Copied', 1)

    }

    const openWithdrawal = () => {
        if((userData?.emailVerification ?? 0) == 0){
            history.push('/signin')
            return;
        }
        setWhichWithdrawal(0)
        setWhichModal(0)
        setOpenModal(true)
    }




const dashMenu = [
    
    // {
    //     icon: FcBookmark,
    //     name: 'Official',
    //     color: '#9e6af8',
    //     click: () => window.location.href = homepage,
    //     id: 1,
    // },
    // {
    //     icon: FcBookmark,
    //     name: 'Account',
    //     color: '#9e6af8',
    //     click: () => history.push('/personal-information'),
    //     id: 1,
    // },
    {
        icon: RiShieldStarFill,
        name: 'Promotion tool',
        color: '#9e6af8',
        click: () => history.push('/promotion-tool'),
        id: 1,
    },

    {
        icon: RiShieldUserLine,
        name: 'About us',
        color: '#9e6af8',
        click: () => history.push('/about'),
        id: 2,
    },

    {
        icon: RiSideBarFill,
        name: 'News',
        color: '#9e6af8',
        click: () => history.push('/news'),
        id: 3,
    },

    // {
    //     icon: RiBodyScanLine,
    //     name: 'Customer care',
    //     color: '#9e6af8',
    //     click: () => window.location.href = `https://tawk.to/chat/${site_settings?.site_settings?.tawktoLivechatCode}`,
    //     id: 4,
    // },

    {
        icon: RiOpenArmLine,
        name: 'FAQ',
        color: '#9e6af8',
        click: () => history.push('/faq'),
        id: 5,
    },

    // {
    //     icon: RiStarSmileLine,
    //     name: 'Certification',
    //     color: '#9e6af8',
    //     click: () => history.push('/certification'),
    //     id: 6,
    // },

    {
        icon: RiStarSmileLine,
        name: 'Telegram',
        color: '#9e6af8',
        click: () => window.location.href = site_settings?.site_settings?.telegram,
        id: 6,
    },
    {
        icon: RiTeamFill,
        name: 'Customer service',
        color: '#9e6af8',
        click: () => window.location.href = site_settings?.site_settings?.whatsapp,
        id: 6,
    },


]



const dashMenu2 = []

    
    const goToVerifyForum = () => {
        setloading(true);
        setLoadingText('Accessing verified user forumn...');

        dispatch(authActions.onLogin(`join/verified-forum`, {}, response => {
            
            setloading(false);

            if(response.code != 1){
                notify(response.msg, response.code)
            }else{
                let link = response?.data?.link;
                window.location.href = link;
            }
            

        }))
    }


    const openAffilliateWithdrawal = () => {
        if((userData?.emailVerification ?? 0) == 0){
            history.push('/signin')
            return;
        }
        setWhichWithdrawal(1)
        setWhichModal(0)
        setOpenModal(true)
    }


    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ setLoadingStat(false); return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {
            setLoadingStat(false)
        }))

    }

    useEffect(() => {

        let timing = setTimeout(() => {
            loadAllHomePageData();
        }, 2000)
        
        return () => clearTimeout(timing);
    }, [])

    if (openModal) {
      // push to history when modal opens
      window.history.pushState(null, '', window.location.href)
      
      // close modal on 'back'
      window.onpopstate = () => {
        window.onpopstate = () => {}
        window.history.back()
        setOpenModal(false)
      }
    }


    const processWithdrawal = (e) => {
        
        e.preventDefault();

        if(((selectedCurrencyObj.autoConfirm == 0) && (userData.bank == ''))
            || 
        ((selectedCurrencyObj.autoConfirm == 1) && (userData.trc20tetherwallet == ''))){ 
            notify('Payment details not found. Please bind your payment details.');
            return false;
        }

        if((isNaN(withdrawAmount)) || (withdrawAmount == 0)){
            // notify('Enter amount', 0);
            return false;
        }


        if(withdrawalPin == ''){
            notify('Please enter your withdrawal pin', 0);
            return false;
        }

        if(selectedCurrency == ''){
            notify('Please mark a Currency', 0);
            return false;
        }

        setloading(true);
        setLoadingText('Requesting withdrawal');

        dispatch(withdrawalActions.onCreateWithdrawal({withdrawalPin, amount: withdrawAmount, currency: selectedCurrency, walletAddress}, `withdrawal/new/${whichWithdrawal}`, response => {
            notify(response.msg, response.code)
            if(response.code == 1){
                setOpenModal(false)
                setWithdrawAmount('');
                setSelectedCurrency('')
                setWithdrawalPin('');
                history.push('/withdrawal-records')
            }
            setloading(false);
        }))
    }



    const openFunding = () => {
        if((userData?.emailVerification ?? 0) == 0){
            history.push('/signin')
            return;
        }
        
        setloading(true)
        setLoadingText('Refreshing deposit records...')

        dispatch(depositActions.onLoadAllDeposits("deposit/load", response => {
            if(response.code == 1){
                setloading(false)
                setWhichModal(1)
                setOpenModal(true)
            }
        }))

    }


    const uploadSelectedPayment = (file) => {
        
        setLoadingText('Uploading payment...')
        setloading(true)

        dispatch(depositActions.onCreateDeposit({txn_id:getPendingPayment?.[0]?.txn_id, image:file}, "deposit/manual-deposit-upload", response => {
            setloading(false)
            notify(response.msg,  response.code)
        }))

    }



    const transferFunding = () => {
        if((userData?.emailVerification ?? 0) == 0){
            history.push('/signin')
            return;
        }
        
        setWhichModal(2)
        setOpenModal(true)

    }


    const processTransfer = (e) => {

        e.preventDefault();


        if(selectedCurrency == ''){
            notify('Select Currency', 0);
            return false;
        }
        
        setloading(true)
        setLoadingText('Transfering fund...')




        dispatch(authActions.onLogin("fund/transfer/new", {amount: sendingAmount, email: receiverEmail, pin: withdrawalPin, currency: selectedCurrency}, response => {
            if(response.code == 1){
                setReceiverEmail('')
                setSendingAmount('')
                setWithdrawalPin('')
                setSelectedCurrency('')
                setOpenModal(false)
            }
            setloading(false)
            notify(response.msg, response.code);    
        }))


    }


    const processDeposit = (e) => {
        
        e.preventDefault();

        if((isNaN(depositAmount)) || (depositAmount == 0)){
            notify('Enter amount', 0);
            return false;
        }

        if(selectedCurrency == ''){
            notify('Select Currency', 0);
            return false;
        }

        setloading(true)
        setLoadingText('Generating payment...')

        dispatch(depositActions.onCreateDeposit({amount: depositAmount, currency: selectedCurrency}, "deposit/new", response => {
            if(response.code == 1){
                setDepositAmount('')
            }else{
                notify(response.msg, response.code)
            }

            setloading(false)
            
        }))
    }

    const getPendingPayment = allDepositData?.filter((item, index) => item.status < 2);
    
    const deleteInv = (txn_id) => {

        setloading(true)
        setLoadingText('Deleting request...')

        dispatch(depositActions.onLoadAllDeposits(`deposit/delete/${txn_id}`, response => {
            if(response.code == 1){
                // setWhichModal(1)
                // setOpenModal(true)
            }
            setloading(false)
        }))

    }


    const totalEscrow = exchangeStoreData?.filter((item, index) => item.active == 1 ).reduce((def, arrItem) => Number(def) + Number(arrItem.crypto_amount), 0);
    const capitals = tradeStoreData?.data?.filter((item, index) => item.active == 1 ).reduce((def, arrItem) => Number(def) + Number(arrItem.capital), 0);
    const compundInterest = tradeStoreData?.data?.filter((item, index) => item.active == 1 ).reduce((def, arrItem) => Number(def) + Number(arrItem.inTrade), 0);
        
    const withdrawalChargeHolder = (whichWithdrawal == 0)?'withdrawalCharge':'withdrawalChargeRef';
    const withdrawalChargePercentage = site_settings?.plans?.[0]?.mother[withdrawalChargeHolder]

    const ayoDeleObj = [
        {name:'Balance',    data: FormatOnly(finance?.[0]?.balance ?? 0), bgColor: '#0d60d8', icon: 'fa-area-chart'},
        {name:'Affiliate',  data: FormatOnly(finance?.[0]?.commission ?? 0), bgColor: '#7913e5', icon: 'fa-arrows'},
        {name:'Exchange',   data: loadingStat?<span className="fa fa-1x fa-circle-o-notch fa-spin"></span>:FormatOnly(totalEscrow ?? 0), bgColor: '#222222', icon: 'fa-anchor'},
        {name:'Interest',      data: loadingStat?<span className="fa fa-1x fa-circle-o-notch fa-spin"></span>:FormatOnly(compundInterest ?? 0), bgColor: '#753ff6', icon: 'fa-briefcase'}
    ];



    const progClickFileUpload = () => {
        if(!userData?.image){ history.push('/signin'); return;}
        hiddenFileInput.current.click();
    }


    const progClickPaymentUpload = () => {
        uploadPayment.current.click();
    }




    const instantUploadPreview = (file, destID) => {

        let output = document.getElementById(destID);

        // if(!Array.isArray(file)){ 
            // typeof file === 'object'
        if(file.type == undefined){
            output.src = file;
        

        }else{

            output.src = URL.createObjectURL(file);
            output.onload = function() {
                  URL.revokeObjectURL(output.src) // free memory
                }

        }

    };


    const uploadAvatar = async (file) => {

        // instantUploadPreview(file, 'avatarPlace');
        setUploadingAvatar(true)

        dispatch(authActions.onLogin("account/change-avatar", {avatar:file}, response => {
                setUploadingAvatar(false)
            }),
        )
  
    }


    const openTradeModal = () => {
        setWhichModal(3)
        setOpenModal(true)
    }


    const tradeNow = () => {

      if(Number(finance?.[0]?.balance) < Number(finance?.[0]?.minimumParticipation)){
            notify('Balance does not measure up to minimum trade', 0);
            return;
      }

      if(isNaN(tradeAmount) || (tradeAmount <= 0)){
          return;
      }

      setloading(true);
      setLoadingText('Logging trade...');

      dispatch(tradeActions.onPOSTTheTrades({amount:tradeAmount, currency:selectedPlan?.type}, "trade/new", response => {
                setloading(false)
                notify(response.msg, response.code);
                if(response.code == 1){
                    setloading(false);
                    setOpenModal(false)
                    // setTradeResultResponse({...response?.data?.event ?? {}})
                    history.push('/arbitrage')
                }
                
            }),
        )

    }

    let totalFreq = 24/selectedPlan?.hoursProfitComesBack


    const pinValidator = () => {
        return <div>
            {/*<div className="alert alert-info">Validate action with account PIN below</div>*/}
            <div class="form-group">
            <span className="text-info-1">Pin</span>
                <input maxLength={4} autoComplete="new-password" placeholder="Account Pin" onChange = {e => setWithdrawalPin(e.target.value) } required="" className="auth-inpu  form-field form-control" type="password" />
        </div></div>
    }

    const saveBTNJSX = () => (
        <>
            <button type="submit" disabled={loading} className="btn btn-block btn-primary" style={{backgroundColor: otherColors.btn1}}>
            Submit </button>
        </>
        )




    const onSuccessOfManualFiatAutoPay = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        loadAllHomePageData();
    };

    // you can call this function anything
    const onCloseOfManualFiatAutoPay = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        notify('You closed payment portal', 0);
    }



    
    
    const name = userData?.username;
    const phone = userData?.phone;
    const email = userData?.email;


    // paystack

     const amount    = getPendingPayment?.[0]?.amountEnforcedToPay * 100;
    const publicKey = site_settings?.site_settings?.paystackPublicKey;
    const reference = getPendingPayment?.[0]?.txn_id;

    const componentProps = {
        email,
        amount,
        reference,
        metadata: {
          name,
          phone,
        },
        publicKey,
        text: "Pay Now"

    }

    // const initializePayment = usePaystackPayment(componentProps);


    // const payFiatWithPaystack = () => {
    //     initializePayment(onSuccessOfManualFiatAutoPay, onCloseOfManualFiatAutoPay);
    // }

    // pay with flutterwave

    const config = {
        public_key: site_settings?.site_settings?.flutterwavePublicKey,
        tx_ref: getPendingPayment?.[0]?.txn_id,
        amount: getPendingPayment?.[0]?.amountEnforcedToPay,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email,
          phonenumber: phone,
          name,
        },
        customizations: {
          title: 'my Payment Title',
          description: 'Payment for items in cart',
          logo: site_settings?.site_settings?.logo,
        },
    };

    // const handleFlutterPayment = useFlutterwave(config);


    // const payWithFlutterWave = () => {
    //     handleFlutterPayment({
    //         callback: (response) => {
    //            onSuccessOfManualFiatAutoPay()
    //             closePaymentModal() // this will close the modal programmatically
    //         },
    //         onClose: () => { onCloseOfManualFiatAutoPay(); },
    //     });
    // }



    const selectFiatAutoPay = () => {
        if(site_settings?.site_settings?.fiatAutomatedDepositMerchant == 0){ // paystack
            // payFiatWithPaystack();
        }else if(site_settings?.site_settings?.fiatAutomatedDepositMerchant == 1){ // flutterwave
            // payWithFlutterWave();
        }
    }


    const startCalculator = () => {
        setWhichModal(4)
        setOpenModal(true)

    }


    const updateCalculatorInput = (inValue) => {
        let newInput;

        if(calculatorInput == 0){
            newInput = inValue
        }else{
            newInput = String(calculatorInput) + String(inValue)
        }
        setCalculatorInput(newInput)
    }

    const calculateNow = () => {
        let roi = selectedPlanCurrencyForCalculator?.[0]?.roi
        let round = Number(selectedPlanCurrencyForCalculator?.[0]?.totalRound)
        let input = Number(calculatorInput)

        let slitRoi = roi.split(',');
        let totalRoiArray = slitRoi.reduce((def, arrItem) => Number(def) + Number(arrItem), 0);


        let result = ((totalRoiArray/100) * input)

        if(selectedPlanCurrencyForCalculator?.[0]?.releaseCapital == 1){
            result+=input;
        }

        setCalculatorResult(result);
    }

    const transactionHistory = () => {

        let dataToRender = site_settings?.transactions;

        let mapArray = dataToRender?.map((item, index) => {
            return <div style={{display:'flex', flex: 4, paddingBottom: 20}}>
                <div style={{flex: 1}}>
                    <Images style={{width: 60, height: 60, borderRadius: '50%'}} className="" source={item.uimage ?? require('../images/avatar.png')} />

                </div>
                <div style={{flex: 1}}>

                    <h6 className="text-white"> <Images src={item.merchant.flag} /> {item.merchant.username.slice(0, 2)}****{item.merchant.username.slice(-1)}</h6>
                    <span className="text-white">{niceDate(item.timeCleared)}</span>

                </div>

                <div className="text-white" style={{flex: 1}}>
                    <h6 className="text-white" style={{fontSize: 13}}>${money(item.capital, item.currencyId)}</h6>
                    <span style={{fontSize: 13}}>{item.cryptoAsset}</span>
                </div>


                <div style={{flex: 1}}>
                    <h6 style={{color: '#ff5722', fontWeight: 900, fontSize: 13}}>{money(item.profit, item.currencyId)}</h6>
                </div>

            </div>
        })

        return mapArray;
    }


    const menupalace1 = [
        {icon: AiOutlineArrowDown , name: 'Fund',       to: "/deposit"},
        {icon: AiOutlineArrowsAlt, name: 'Withdraw',    to: "/withdraw"},
        {icon: AiOutlineBranches, name: 'Send',         to: "/transfer"},
        {icon: AiFillCaretRight, name: 'Records',       to: "/deposit-records"},
    ]

    const menupalace2 = [
        {image: require('../images/account-image-5.jpg'), name: 'Ai Bots',  to: "/trade/bots"},
        {image: require('../images/account-image-2.jpg'), name: 'Wallet',   to: "/wallet"},
        {image: require('../images/account-image-7.jpg'), name: 'Team',     to: "/team"},
        {image: require('../images/account-image-3.jpg'), name: 'Security', to: "/securities"},
        {image: require('../images/account-image-6.jpg'), name: 'Invitation',   to: "/ref-link"},
        {image: require('../images/account-image-8.jpg'), name: 'Personal',     to: "/personal-information"},
    ]



    const salaryData = site_settings?.salary_settings;

    return (

        <SiteWide title={"Home"}>
            <Section paddingTop={0} hideHeader={true} overlayText={loadingText} loading={loading} title={'Home'} style={{}}>
                

                <div className="container" style={{backgroundColor: header}}>
                    <div className="" style={{paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        
                        <div className="c-4">

                            <WebLink to={'/'} className="logo">
                                <Images style={{marginLeft: 20}} height="40px" src={(site_settings?.site_settings?.logo)} alt="logo" />
                            </WebLink>

                            
                        </div>

                        <div className="c-4" style={{marginRight: 10, display: 'flex', alignItems: 'center'}}>
                            
                            

                            {/*<>
                                {((userData?.emailVerification ?? 100) != 100) && <WebLink onClick={doLogOutNowNow} className="header-user">
                                    <RiLogoutBoxRLine color={otherColors.text} size={23}/>
                                </WebLink>}

                                {((userData?.emailVerification ?? 100) == 100) && <WebLink to={'/signin'} className="header-user">
                                    <RiLogoutBoxLine color={otherColors.text} size={23}/>
                                </WebLink>}
                            </>*/}

                               
                            <div style={{marginLeft: 10}}>
                                <LoadingOverlay
                                    active={uploadingAvatar}
                                    spinner
                                >
                                <Images style={{width: 40, height: 40, borderRadius: '50%'}} id="avatarPlace" title="Click to upload" className="cursor-pointer im" onClick={() => progClickFileUpload()} width="60px" source={((userData?.image ?? null) != null)?userData?.image:require('../images/avatar.png')} alt="avatar" />
                                <input style={{display: 'none'}} ref={hiddenFileInput} onChange={(e) => uploadAvatar(e.target.files[0])} type="file" accept=".jpg, .gif, .png, .jpeg"/>
                                </LoadingOverlay>
                            </div>

                        </div>

                    </div>
                </div>



                <div style={{}}>

                    <div className="text-center" style={{padding: 30, paddingBottom: 0}}>

                        <h3 class="text-white">{capitalizeFirstLetter(userData?.username)}</h3>

                        <h1 class="text-white passion-font" style={{fontSize: 45, fontWeight: '900'}}>
                            {money(finance?.[0]?.balance ?? '0.00', finance?.[0]?.type ?? 170)}
                        </h1>
                        <p class="text-white">Total Balance</p>
                    </div>

                    <div className="text-center" style={{marginTop: 5}}>
                        <Images source={require('../images/barchart2.png')} style={{ maxWidth: '80%', objectFit: 'cover' }} />
                    </div>

                </div>
                



                <div className="section"  style={{background: otherColors.card2, padding: 20, marginRight: 10, marginLeft: 10, borderRadius: 20}}>


                        <div className="" style={{borderRadius: 10, background: otherColors.cotpBlue, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
                            
                            

                            {menupalace1.map((item, index) => {

                                const Icon = item.icon;

                                return <div className="text-center" onClick={() => {}} style={{paddingBottom: 10, paddingTop: 10}}>
                                    <WebLink style={{}} title={""} to={item.to}>
                                       
                                        <div className="text-center" style={{color: '#fff', background: '#33c3ff', width: 60, justifyContent: 'center', alignItems: 'center', display: 'flex', height: 60, borderRadius: 50}}>
                                            <Icon size={15} color="#fff" />
                                        </div>

                                        <h6 className="sall-icon-head text-white" style={{fontSize: 12, marginTop: 10}}>{item.name}</h6>

                                    </WebLink>

                            </div>})}

                        </div>





                        <Slider arrows={false} style={{marginTop: 20, marginBottom: 20}} {...accountSettingsSliders}>
                                                            
                                {menupalace2.map((item, index) => {


                                    return <div style={{}}>
                                        <WebLink to={item.to} style={{marginRight: 20, border: '1px solid #3426b5', padding: 5, background: '#fff', borderRadius: 10, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}} title={item.name} to={item.to}>
                                        
                                            <button style={{color: '#fff', borderRadius: 60}}>
                                                <Images style={{borderRadius: 10}} width={'100%'} source={item.image} />
                                            </button><br/>


                                            <h6 className="sall-icon-head passion-font text-" style={{fontSize: 12, marginTop: 10, color: '#3426b5'}}>{item.name}</h6>

                                        </WebLink>
                                    </div>})}

                        </Slider>





                        <div className="" style={{marginTop: 20, borderRadius: 10,  backgroundColor: otherColors.card, paddingTop: 20, paddingBottom: 20}}>
                            
                            <div className="row">
                                
                                {dashMenu.map((item, index) => {

                                    const Icon = item.icon;

                                    return <div className="col-4 text-center" onClick={item?.click} style={{marginBottom: 60}}>
                                    <WebLink style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}} title={item.name} to={item.to}>
                                        
                                        <button style={{color: '#fff', background: '#d0cdf4', width: 60, height: 60, borderRadius: 60, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                            <Icon color={"#3426b5"} size="22"/>
                                        </button><br/>


                                        <h6 className="sall-icon-head text-dark" style={{fontSize: 12, marginTop: 10}}>{item.name}</h6>

                                    </WebLink>

                                </div>})}

                            </div>


                            <div className="text-center" style={{}}>
                                {((userData?.username ?? '') != '') && <button onClick={doLogOutNowNow} className="passion-font" style={{color: '#c6c9cc', paddingHorizontal: 90, paddingRight: 15, paddingLeft: 15, border: '2px solid #c6c9cc', borderRadius: 20, justifyContent: 'center', alignItems: 'center'}}>
                                    Logout
                                </button>}

                                {((userData?.username ?? '') == '') && <WebLink to={"/signin"} className="passion-font" style={{color: '#c6c9cc', paddingHorizontal: 90, paddingRight: 15, paddingLeft: 15, border: '2px solid #c6c9cc', borderRadius: 20, justifyContent: 'center', alignItems: 'center'}}>
                                    Login
                                </WebLink>}

                            </div>

                        </div>

                </div>








            </Section>

        </SiteWide>
    );
}

export default Home;