import React, {useState, useEffect, useRef} from "react";
import ReactPaginate from 'react-paginate';




	
export default function PaginatedItems({ itemsPerPage, arrayOfDataToDisplay, Template }) {

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);



    useEffect(() => {
    	if(arrayOfDataToDisplay.length > 0){
	    	let items = [...arrayOfDataToDisplay];

		    // Fetch items from another resources.
		    const endOffset = itemOffset + itemsPerPage;
		    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
		    setCurrentItems(items.slice(itemOffset, endOffset));
		    setPageCount(Math.ceil(items.length / itemsPerPage));
		}
	  }, [arrayOfDataToDisplay, itemOffset, itemsPerPage]);




	  // Invoke when user click to request another page.
	  const handlePageClick = (event) => {
	    const newOffset = (event.selected * itemsPerPage) % arrayOfDataToDisplay.length;
	    // console.log(
	    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
	    // );
	    setItemOffset(newOffset);
	  };

  	return (
	    <>
	      <Template currentItems={currentItems} />
	      <ReactPaginate
	        breakLabel="..."
	        nextLabel=">"
	        onPageChange={handlePageClick}
	        pageRangeDisplayed={5}
	        pageCount={pageCount}
	        previousLabel="<"
	        renderOnZeroPageCount={null}
	        pageClassName="page-item"
	        pageLinkClassName="page-link"
	        previousClassName="page-item"
	        previousLinkClassName="page-link"
	        nextClassName="page-item"
	        nextLinkClassName="page-link"
	        breakClassName="page-item"
	        breakLinkClassName="page-link"
	        containerClassName="paginational"
	        activeClassName="actival"

	      />
	    </>
  	);


}