
import {FcSettings, FcHome, FcSalesPerformance, FcReddit} from "react-icons/fc";
import { AiOutlineAppstoreAdd, AiOutlineBlock, AiOutlineBorderOuter, AiOutlineCluster, AiTwotoneCrown } from "react-icons/ai";

export const menuItems = [
    {address: '/', title: 'Home', icon: AiOutlineAppstoreAdd},

    {address: '/trade/bots', title: 'Ai Bots', icon: AiTwotoneCrown},

    {address: '/global', title: 'Trading center', icon: AiOutlineBlock},

    // {address: '/bot', title: 'Bots', icon: AiOutlineBorderOuter},

    // {address: '/team', title: 'Team', icon: RiSteamFill},
    {address: '/personal-information', title: 'Account', icon: AiOutlineCluster},
]

