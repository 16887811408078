import React, {useState, useEffect} from "react";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ReactTimeAgo from 'react-time-ago'


function CustomAgo({time}) {

    TimeAgo.addLocale(en)
    const timeAgo = new TimeAgo('en-US')

    return <ReactTimeAgo date={new Date(time)} locale="en-US"/>

}

export default CustomAgo;