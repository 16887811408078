import React, {useState, useEffect} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import AutoNotification from "./layouts/AutoNotification";
import Section from "./layouts/Section";
import {WebLink, SiteWide, Images} from "./customComponents";
import {mainStyle, mainColor, header} from '../utility/styles';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {tradeActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, allTradeSelect } from '../redux/selectors/index';
import Helper1, {notify, styles, money, customModalStyles} from '../utility/Helper1';
import { useSnackbar } from 'snackfy';
import Countdown from 'react-countdown';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ReactTimeAgo from 'react-time-ago'
import Slider from "react-slick";
import { useHistory } from "react-router-dom";

    

const tradeBTN = require('../images/trade_button_bg.jpg');
  



    const settings = {
            dots: true,
            slidesToShow: 3,
            infinite: true,
            accessibility: true,
            autoplay: false,
            
            responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 3,
                  }
                },
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 3,
                  }
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 2,
                  }
                }
            ]
        };



function Trade() {

    const history = useHistory();
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const tradesData = useSelector(allTradeSelect);

    const [openModal, setOpenModal]   = useState(false);
    const [tradeAmount, setTradeAmount]   = useState('');

    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');
    const [selectedPlan, setSelectedPlan]   = useState({});
    const [tradeResultResponse, setTradeResultResponse]   = useState();

    const dispatch = useDispatch();

    const prepareTradeModal = (planObjectSelected) => {
      setOpenModal(true)
      setSelectedPlan({...planObjectSelected})
    }

    const tradeNow = () => {

      if(Number(finance?.[0]?.balance) < Number(finance?.[0]?.minimumParticipation)){
          return;
      }

      if(isNaN(tradeAmount) || (tradeAmount <= 0)){
          return;
      }

      setloading(true);
      setLoadingText('Searching for appropriate trade entry...');

      dispatch(tradeActions.onPOSTTheTrades({amount:tradeAmount, currency:selectedPlan?.type}, "trade/new", response => {
                setloading(false)
                notify(response.msg, response.code);
                if(response.code == 1){
                    setloading(false);
                    setOpenModal(false)
                    // setTradeResultResponse({...response?.data?.event ?? {}})
                    history.push('/arbitrage')
                }
                
            }),
        )

    }

    return (
        <SiteWide id="main" title={"Select a trade plan"}>
            <Section hideHeader={true} overlayText={loadingText} loading={loading} title={'Trade plan'}>
            
                

              <div className="container py-5">

                <header className="text-center mb-5 text-white">
                  <div className="row">
                    <div className="col-lg-7 mx-auto" styles={{padding: 40}}>
                      <h1 className="text-grey">{site_settings?.content_settings?.[0]?.con7}</h1>
                      <p className="text-grey">{site_settings?.content_settings?.[0]?.con8}</p>
                    </div>
                  </div>
                </header>


                <Slider {...settings}>
                  {site_settings?.plans?.[0]?.childs?.map((item, index) => {

                    let totalFreq = 24/item.hoursProfitComesBack

                    return <>
                    <div className="mb-5 mb-lg-0 text-center" styles={{paddingRight: '30em'}}>
                      <div className={`bg-whit p-5 rounded-lg shadow text-white package_bg_${index+1}`} styles={{marginRight: '30em'}}>
                        <h1 className="h6 text-uppercase font-weight-bold mb-4">{item.name}</h1>
                        <h2 className="h1 font-weight-bold">{((Number(item.roi) - 100) * totalFreq).toFixed(1)}%<span className="text-small font-weight-normal ml-2">/ day</span></h2>

                        <div className="custom-separator my-4 mx-auto bg-white"></div>

                        <ul className="list-unstyled my-5 text-small text-left">

                          <li className="mb-3"><i className="fa fa-check mr-2 text-white"></i> {money(item.minimumParticipation, item?.type)} minimum trade</li>
                          <li className="mb-3"><i className="fa fa-check mr-2 text-white"></i> {money(item.maximumParticipation, item?.type)} maximum trade</li>
                          <li className="mb-3"><i className="fa fa-check mr-2 text-white"></i> {totalFreq} rounds of Trading</li>
                          <li className="mb-3"><i className="fa fa-check mr-2 text-white"></i> Automated withdrawal system</li>
                          <li className="mb-3"><i className="fa fa-check mr-2 text-white"></i> Blockchain confirmation</li>


                          
                        </ul>
                        <WebLink onClick={() => prepareTradeModal(item)} className="btn btn-primary btn-block shadow rounded-pill">Subscribe</WebLink>
                      </div>
                    </div>
                  </>})}
                </Slider>


              </div>




                    <Modal
                        isOpen={openModal}
                        onRequestClose={() => setOpenModal(false)}
                        contentLabel="New trade"
                        style={customModalStyles}
                        className="add-balance-inner-wrap"
                        shouldCloseOnOverlayClick={true}
                        shouldCloseOnEsc={true}
                      >

                        <div>
                            
                            <div className="text-center">
                                <Images width="100px" style={{position: 'relative', zIndex: 99999, marginTop: -65}} src={require('../images/subscribe.png')} />
                            </div>

                            <div className="mody">

                                <div className="actent">

                                    <form action="">
                                        
                                        
                                            

                                        <div className="form-group basic">
                                            <label className="label">Enter Amount</label>
                                            <div className="input-group mb-3">

                                              <div className="input-group-prepend">
                                                  <span className="input-group-text" id="input1">$</span>
                                              </div>
                                              <input type="text" className="form-control form-control-lg" onChange = {(e) => setTradeAmount(e.target.value)} value={tradeAmount} />

                                            </div>
                                        </div>

                                        {(Number(finance?.[0]?.balance) < Number(tradeAmount)) && tradeAmount !='' && <div className="alert alert-info-bordered alert-lg square">Account balance not sufficient for this trade sum</div> }
                                        {(Number(tradeAmount) < Number(selectedPlan?.minimumParticipation)) && tradeAmount !='' && <div className="alert alert-info-bordered alert-lg square">Trade sum below minimum tradable</div> }
                                        {(Number(tradeAmount) > Number(selectedPlan?.maximumParticipation)) && tradeAmount !='' && <div className="alert alert-info-bordered alert-lg square">Trade sum beyond maximum tradable</div> }


                                        <div className="form-group basic">
                                            <button onClick = {() => tradeNow()} className="btn-c btn-primary btn-block btn-lg cursor-pointer">Process</button>
                                        </div>

                                    </form>

                                </div>



                            </div>
                        </div>

                    </Modal>



            </Section>
        </SiteWide>
    );

}

export default Trade;