import React, {Component, useEffect} from "react";
import Section from "./layouts/Section";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {WebLink, SiteWide} from "./customComponents";
import Faqo from "react-faq-component";

function Faq(){

    const bakground = require('../images/space.jpg');

    const site_settings = useSelector(allRemoteSettingsSelect);

    const styles = {
        bgColor: 'transparent',
        titleTextColor: "#fff",
        rowTitleColor: "#fff",
        rowContentColor: '#fff',
        arrowColor: "#fff",
    };

    const config = {
        animate: true,
        // arrowIcon: "V",
        tabFocus: true,
        expandIcon: "+",
        collapseIcon: "-",
    };

    const data = {    
        title: "FAQ",
        rows: site_settings?.faq?.map(function(item, index){ return {title: item.question, content: item.answer} })

    };

    return (
        <SiteWide id="main" ackgroundImage={bakground} title={`FAQ`}>
            <Section title={'FAQ'}>
                <div className="container">
                    <div className="container-fluid" style={{marginTop: 100}}>
                        <Faqo
                            data={data}
                            styles={styles}
                            config={config}
                        />
                    </div>
                </div>
            </Section>
        </SiteWide>
    );

}

export default Faq;