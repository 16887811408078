import React, {Component, useState, useEffect} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import {WebLink, SiteWide} from "./customComponents";
import Section from "./layouts/Section";
import {useHistory, useLocation} from 'react-router-dom';

import Slider from "react-slick";
import {Images} from "./customComponents";
import Colors from '../utility/Colors';
import Helper1, {notify, styles, money} from '../utility/Helper1';
import NoData from "./customComponents/NoData";

import {withdrawalActions} from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allWithdrawalSelect, allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import Modal from 'react-modal';
import LoadingOverlay from 'react-loading-overlay';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import CustomAgo from "./customComponents/CustomAgo";


function DepositHistory() {


    const search = useLocation();

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);

    const [loading, setloading]   = useState(false);
    const [amount, setAmount]   = useState();
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);
    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [walletAddress, setWalletAddress]   = useState('');


    const [enterAmountModal, setEnterAmountModal]   = useState(search?.state?.withdraw ?? false);
    const [newDepositInfo, setNewDepositInfo]   = useState();
    const [changeWhichWallet, setChangeWhichWallet]   = useState(100);


    TimeAgo.addDefaultLocale(en)
    const timeAgo = new TimeAgo('en-US')



    const [modalIsOpen, setIsOpen] = React.useState(false);

    const finance = useSelector(homeScreenStatSelect);

    const dispatch = useDispatch();
    const loadHistoryData = useSelector(allWithdrawalSelect);



    const loadAllWithdrawal = () => {

        setloading(true)
        dispatch(withdrawalActions.onLoadAllWithdrawals("withdrawal/load", response => {

            if(response.code == 1){
                setloading(false)
            }
        }))

    }

    useEffect(() => {
        loadAllWithdrawal()
    }, [])


    



    const whichWallet = ['Balance', 'Commission']

    const status = ['Processing', 'Processing', 'Paid', 'Cancelled'];

    return (

        <SiteWide id="main" title={"Withdrawals"}>
            <Section loading={loading} title={'Withdrawals'}>

                <div class="container" style={{}}> 


                    <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>

                        <WebLink to="/deposit-records" class="button one">Deposits</WebLink>
                        <div></div>

                         <WebLink style={{backgroundColor: '#fff', color: '#000'}} to="/withdrawal-records" class="button one">Withdrawals</WebLink>
                        <div></div>

                         <WebLink to="/transfer-records" class="button one">Transfer</WebLink>
                        <div></div>

                    </div>


                    {loadHistoryData?.filter((item, index) => item.whichWallet == 0)?.map((item, index) => { 

                        let image = finance?.filter((filitem, index) => filitem.type == item.currency)

                        return <div className="ba-bill-pay-inner" style={{background: '#fff', border: 'none'}}>
                            <div className="ba-single-bill-pay">
                                <div className="thumb">
                                    <Images height={40} source={image?.[0]?.image} alt="img" />
                                </div>
                                <div className="details" style={{width: '100%'}}>
                                    <h5 style={{display: 'flex'}}>
                                        <span>
                                            Withdraw
                                        </span>
                                        <span style={{marginLeft: 'auto'}}>
                                            {money(item?.readOnlyTotalWithdrawn, item?.currency)}
                                        </span>
                                    </h5>
                                    <p style={{display: 'flex'}}>
                                        <span>
                                            {item.txn_id}
                                        </span>
                                        <span style={{marginLeft: 'auto'}}>
                                            {status[item?.status]}
                                        </span>
                                    </p>
                                    <p><CustomAgo time={item?.timestamp}/></p>
                                </div>

                                

                            </div>
                            <div className="amount-inner">
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <h5>Discharged amount</h5>
                                    <h5>{money(item?.amount, item?.currency)}</h5>
                                </div>
                            </div>
                        </div>

                    })}

                </div>

                <Footer />

            </Section>


            

                

            

        </SiteWide>
    );
}

export default DepositHistory;