import React, {useState, useEffect, useRef} from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import {Link} from "react-router-dom";
import AutoNotification from "../layouts/AutoNotification";
import Section from "../layouts/Section";
import CustomAgo from "../customComponents/CustomAgo";
import {WebLink, SiteWide, Images} from "../customComponents";
import PaginatedItems from "../customComponents/PaginatedItems";

import {mainStyle, mainColor, header, otherColors} from '../../utility/styles';
import Modal from 'react-modal';

import { useSelector, useDispatch } from 'react-redux';
import {tradeActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, allTradeSelect } from '../../redux/selectors/index';
import Helper1, {notify, styles, money, customNormalModalStyles, currencySliderArray} from '../../utility/Helper1';
import { useSnackbar } from 'snackfy';
import Countdown from 'react-countdown';
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { Dropdown } from 'semantic-ui-react'
import ModalCustomHead from "../customComponents/ModalCustomHead";
import parse from 'html-react-parser';
import './tradeBots.css';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


import {
    Menu,
    MenuItem,
    MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';



import {RiMore2Fill, RiAddBoxFill, RiCloseLine, RiEye2Line, RiIndeterminateCircleFill, RiExchangeLine, RiBankFill, RiHandCoinFill, RiFundsFill, RiPieChartFill} from "react-icons/ri";












export default function TradingBots() {



    const chooseCurrency2 = useRef();
    const chooseCurrency1 = useRef();

    const site_settings = useSelector(allRemoteSettingsSelect);

    const [loading, setloading]   = useState(false);
    const [tradeDataToShow, setTradeDataToShow]   = useState([]);
    const [loadingText, setLoadingText]   = useState('');
    const [currentSentTradeData, setCurrentSentTradeData]   = useState({});
    const tradesData = useSelector(allTradeSelect);
    const finance = useSelector(homeScreenStatSelect);
    const [selectedPlan, setSelectedPlan]   = useState(site_settings?.plans?.[0]?.childs?.[0]);
    const [openModal, setOpenModal]   = useState(false);

    const [openTradeModal, setOpenTradeModal]   = useState(false);
    const [tradeSaleData, setTradeSaleData]   = useState({});

    const [tradeCapital, setTradeCapital]   = useState('');
    const [selectedImage, setSelectedImage]   = useState('');
    const [selectedCurrency, setSelectedCurrency]   = useState('');


    const dispatch = useDispatch();

    const arbi = require('../../images/arbi.jpg');

    const type              =   finance?.[0]?.type
    
    const systemPlans       =   site_settings.plans.[0].childs;





    if (openTradeModal) {
      // push to history when modal opens
      window.history.pushState(null, '', window.location.href)
      
      // close modal on 'back'
      window.onpopstate = () => {
        window.onpopstate = () => {}
        window.history.back()
        setOpenTradeModal(false)
      }
    }




    const startTradeModal = (currency) => {

        setSelectedCurrency(currency)
        setOpenTradeModal(true)

    }



    const tradeNow = (item) => {


      setloading(true);
      setLoadingText('Purchase on-going...');



      // dispatch(tradeActions.onPOSTTheTrades({currency:selectedPlan?.type, amount: tradeCapital}, "trade/new", response => {
      dispatch(tradeActions.onPOSTTheTrades({currency:item.type, amount: tradeCapital}, "trade/new", response => {

                setloading(false)

                if(response.code == 1){
                    
                    // setStep(1);
                    setOpenTradeModal(true)
                    setTradeSaleData(response.data)

                }

                notify(response.msg, response.code);
                
            }),
        )

    }




    


    const customNormalModalStyles2 = {
      content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        width: 400,
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: 'transparent',
        zIndex: 999999,
        border: 'none',
        
      },

      overlay: {
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 99999999,
            background: 'rgba(0, 0, 0, 0.8)',
            padding: 0,
            margin: 0,
            border: 'none',
          }
    };


    const plansHere = site_settings?.plans?.filter((item, index) => item.mother.type == selectedCurrency)






    const balanceSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,
        responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 1
          }
        },,
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
    };

    const changeFocusedPlan = (index) => {
        chooseCurrency2.current.slickGoTo(index);
    }


    const areAllElementsVisible = chooseCurrency2.current && chooseCurrency2.current.innerSlider.state.slideCount === chooseCurrency2.current.innerSlider.state.slidesToShow;









    return (
        <SiteWide title={"Ai Bots"}>
            <Section overlayText={loadingText} loading={loading} title={'Trading Center'}>


                        



                        <div class="alarm-ul" style={{padding: 10, marginLeft: 10, marginRight: 10, bacgroundColor: otherColors.cotpBlue, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>


                            <h1 className="passion-font" style={{fontSize: 90, fontWeight: '900px', marginBottom: 20, color: '#fff'}}>
                                Ai Trading<br />Bots
                            </h1>   






                            {!areAllElementsVisible && <div className="flexify" style={{marginTop: 35, marginBottom: 25}}>
                                {systemPlans?.map((finItem, finIndex) => {
                                    return <span onClick={() => changeFocusedPlan(finIndex)} className="cursor-pointer each-plan-clickery" style={{paddingTop: 10, paddingBottom: 10, color: '#fff'}}>{finItem.name}</span>
                                })}

                            </div>}

                            <div class="">


                                <Slider class="grid" ref={chooseCurrency2} arrows={false} {...balanceSettings}>
                                    
                                    {systemPlans?.map((finItem, finIndex) => {

                                        const regex = /{{(.*?)}}/g;
                                        const matches = [];
                                        let match;

                                        while (match = regex.exec(finItem.writeUpContent)) {
                                          matches.push(match[1]);
                                        }

                                       
                                        return <div className="card" style={{display: 'none', marginRight: 20, backgroundColor: (finIndex % 2 == 0)?'#f2f1f5':'#eee7fd'}}>
                                            <h2 class="card_title color-black-1">{finItem.name}</h2>

                                            <Images width={'50%'} source={finItem.image} />

                                            <p class="pricing color-black-1">{matches?.[0] ?? ''}<span class="small color-black-1">{matches?.[1] ?? ''}</span></p>
                                            <p className="color-black-1">{matches?.[2] ?? ''}</p>
                                            <p className="color-black-1">{matches?.[3] ?? ''}</p>
                                            <hr />
                                            <ul class="features color-black-1">
                                                {matches.slice(4, matches.length - 1).map((match, index) => <li className="color-black-1">{match}</li>)}
                                            </ul>

                                            <Popup trigger={<a style={{backgroundColor: '#171321', color: '#fff'}} class="cta_btn cursor-pointer">{matches?.[matches.length - 1] ?? 'Buy'}</a>} position="top center" nested>
                                                


                                                <div className="" style={{display: 'flex', flexDirection: 'column', backgroundColor: '#fff'}}>

                                                    <div class="form-group">

                                                        <input style={{border: '1px solid #000', backgroundColor: '#f2f1f5'}} placeholder="Amount" value={tradeCapital} onChange = {e => setTradeCapital(e.target.value) } required="" className="form-control" type="text" />

                                                    </div>

                                                    <div style={{marginTop: 'auto', paddintBottom: 20}}>
                                                        <button onClick={() => tradeNow(finItem)} style={{backgroundColor: '#fff', paddintTop: 10, paddintBottom: 20, }}  class="tn bt-sm btn-block">Submit</button>
                                                    </div>

                                                </div>






                                            </Popup>

                                            
                                        
                                        </div>

                                    })}


                                </Slider>


                            </div>

                            <WebLink to={"/global"} class="link">Pre-managed Ai</WebLink>


                        </div>






                  


            </Section>
        </SiteWide>
    );

}

