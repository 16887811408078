import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../utility/Helper1';
import {homepage} from '../utility/APIRequests';
import PinAuth from "./customComponents/PinAuth";


import Countdown from 'react-countdown';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import {staticCountries} from '../utility/countries';
import {Images} from "./customComponents";
import Slider from "react-slick";
import { RiPhoneLockFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';


function Wallet({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [financeEditing, setFinanceEditing]   = useState(0);
    const [walletModal, setWalletModal]   = useState(false);
    const [withdrawalPinModal, setWithdrawalPinModal]   = useState(false);
    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [googleAuth, setGoogleAuth]   = useState('');

    const [emailOTP, setEmailOTP]   = useState('');
    const [phoneModal, setPhoneModal]   = useState(false);
    
    const [phone, setPhone]   = useState(userData?.phone ?? '');
    const [phoneCode, setPhoneCode]   = useState('...');
    const [country, setCountry]             = useState(userData?.country ?? ''); //'156'

    const [passwordModal, setPasswordModal]   = useState(false);
    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');

    const [password, setPassword]       = useState('');

    const [bank, setBank]       = useState(userData?.bank ?? '');
    const [accountName, setAccountName]       = useState(userData?.accountName ?? '');
    const [accountNumber, setAccountNumber]       = useState(userData?.accountNumber ?? '');
    
    const [usdttrc20wallet, usdttrc20walletSet]       = useState( '');
    const [btcwallet, btcwalletSet]       = useState('');
    const [bchwallet, bchwalletSet]       = useState('');
    const [ethwallet, ethwalletSet]       = useState('');
    const [ltcwallet, ltcwalletSet]       = useState('');
    const [usdcwallet, usdcwalletSet]       = useState('');
    const [xrpwallet, xrpwalletSet]       = useState('');
    const [adawallet, adawalletSet]       = useState('');
    const [dogewallet, dogewalletSet]       = useState('');
    const [dashwallet, dashwalletSet]       = useState('');
    const [xtzwallet, xtzwalletSet]       = useState('');
    const [dotwallet, dotwalletSet]       = useState('');
    const [linkwallet, linkwalletSet]       = useState('');
    const [xlmwallet, xlmwalletSet]       = useState('');
    const [etcwallet, etcwalletSet]       = useState('');
    const [uniwallet, uniwalletSet]       = useState('');
    const [pmwallet, pmwalletSet]       = useState('');
    const [bnbwallet, bnbwalletSet]       = useState('');



    const dispatch = useDispatch();

    const doAddressChange = (e) => {
        setloading(true);
        e.preventDefault();

        let updateObject;

        // if(userData.depositMethod == 1){
            updateObject = {usdttrc20wallet, btcwallet, bchwallet, ethwallet, ltcwallet, usdcwallet, xrpwallet, adawallet, dogewallet, dashwallet, xtzwallet, dotwallet, linkwallet, xlmwallet, etcwallet, uniwallet, pmwallet, bnbwallet, withdrawalPin, googleAuth}
        // }else{
        //     updateObject = {bank, accountName, accountNumber, pin: withdrawalPin}
        // }

        dispatch(authActions.onLogin(`account/account_change/1`, updateObject, response => {
            notify(response.msg, response.code)
            setloading(false);
            if(response.code == 1){
                setWithdrawalPin('')
            }
        }),
        );
    }

    useEffect(() => {
       let mat =  userData?.bankdetails?.map((item, detail) => eval(item?.valueJsConstFunction)(item.value));
    }, [])


    return (
        <SiteWide id="main" title={`Account wallet`}>
            <Section loading={loading} overlayText={loadingText} title={'Account wallet'}>
                
                <div className="container">

                    <form onSubmit={doAddressChange} className="input-container">

                        <div>

                            {userData?.bankdetails?.map((item, detail) =><div class="fup">

                                <span className="text-info-1">{item?.abbr} wallet</span>
                                <input style={{height: 60, color: '#000'}} value={eval(item.valueJsConst)} placeholder={item?.name} onChange = {e => eval(item?.valueJsConstFunction)(e.target.value) } required="" className="form-field form-control" type="text" />

                                
                            </div>)}


                            <PinAuth userData={userData} setWithdrawalPin={setWithdrawalPin} setGoogleAuth={setGoogleAuth} />

                        </div>

                        <div>
                            <button type="submit" disabled={loading} className="bger btn-block" style={{marginTop: 20, padding: 50, background: '#f68c5e'}}>
                            Save </button>
                        </div>
                        
                    </form>

                </div>


            </Section>
        </SiteWide>
    );

}

export default Wallet;