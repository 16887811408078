import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide, Images} from "./customComponents";
import {useHistory} from 'react-router-dom';

import {authActions} from '../redux/actions';
import Helper1, {notify} from '../utility/Helper1';
import  APIRequests from '../utility/APIRequests';
import Countdown from 'react-countdown';
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';

import { useSelector, useDispatch } from 'react-redux';

function ForgetPassword(){

    const [emailInput, setEmailInput] = useState('') 
    const [newPassword, setNewPassword] = useState('') 
    const [confirmNewPassword, setConfirmNewPassword] = useState('') 

    const site_settings = useSelector(allRemoteSettingsSelect);

    const [emailCode, setEmailCode] = useState('')
    const [requestOTPRetObj, setRequestOTPRetObj] = useState({})
    const [uiLevel, setUiLevel] = useState(0)

    const [fullPageLoading, setFullPageLoading]         = useState(false);
    const history                                       = useHistory();


    const requestOTP = async (e) => {
        
        e.preventDefault();
        setFullPageLoading(true);

        let loadApi = new APIRequests;
        let getPost = await loadApi.runPost("account/reset_password_pin", {email: emailInput});

        if(getPost?.code == 1){
            setUiLevel(1)
            setRequestOTPRetObj(getPost?.data)
        }

        notify(getPost?.msg, getPost?.code)
        setFullPageLoading(false);

    }


   

    const changePassword = async (e) => {

        e.preventDefault();

        if((newPassword != confirmNewPassword) || (newPassword == '')){
            notify("Passwords must match", 0)
            return;
        }

        
        setFullPageLoading(true);

        let loadApi = new APIRequests;
        let getPost = await loadApi.runPost("account/reset_password", {email: emailInput, password:newPassword, passwordResetCode:emailCode});

        if(getPost?.code == 1){
            setUiLevel(0)
            notify("Password reset successful. You are being redirected to login page", 1)
            
            setTimeout(() => {
                history.replace('/signin');
            }, 5000)
        }

        // notify(getPost?.msg, getPost?.code)
        setFullPageLoading(false);


    }

    return (
        <SiteWide title="Reset Password">
            <Section loading={fullPageLoading} allNotification={false} searchPopup={true} title={'Verification'} >
                

                <div className="limiter">
        <div className="container-login100">
            <div className="wrap-login100" style={{backgroundColor: otherColors.body}}>



                        <div className="c-4">

                            <WebLink to={'/'} className="logo">
                                <Images height="120px" src={(site_settings?.site_settings?.logo)} alt="logo" />
                            </WebLink>

                            
                        </div>


                        {uiLevel == 0 && <form className="erification-inner text-center" style={{ackgroundImage: "url(assets/img/bg/14.png)", padding: '30px', marginTop: '20px'}}>
                            

                             <label className="single-input-wrap">
                                <span className="text-white">Enter Email ID*</span>
                                <input className="uth-input" onChange={e => setEmailInput(e.target.value) } placeholder="Email ID" required type="email" required />
                            </label>


                            <div className="verify-btn">
                                <button onClick={requestOTP} className="btn btn-danger btn-block uth-input">Reset Password</button>
                            </div>

                        </form>
                        }


                        {uiLevel == 1 && <form className="veification-inner text-center" style={{ackgroundImage: "url(assets/img/bg/14.png)", padding: '30px', marginTop: '20px'}}>
                            
                            <label className="single-input-wrap">
                                <span className="text-white">New Password*</span>
                                <input className="uth-input" onChange={e => setNewPassword(e.target.value) } placeholder="New Password" required type="email" required type="password" />
                            </label>

                            <label className="single-input-wrap">
                                <span className="text-white">Confirm Password*</span>
                                <input className="uth-input" onChange={e => setConfirmNewPassword(e.target.value) } placeholder="Confirm Password" required type="password" />
                            </label>


                            <label className="single-input-wrap">
                                <span className="text-white">Email Token*</span>
                                <input className="uth-input" onChange={e => setEmailCode(e.target.value) } placeholder="Email Token" required type="text" />
                            </label>


                            <div>
                                <span>
                                    <Countdown
                                        zeroPadTime={2}
                                        key={new Date(requestOTPRetObj?.passwordResetCodeTimer)}
                                        date={new Date(requestOTPRetObj?.passwordResetCodeTimer)}
                                        renderer={({ hours, minutes, seconds, completed }) => {
                                          if (completed) {
                                            // Render a completed state
                                            // timeUp();
                                            return <span className="design-text-for-click">Expired. Reload to try again</span>
                                            // 
                                          } else {
                                            // Render a countdown
                                            return <span>Expires in {minutes}:{seconds}</span>;
                                          }
                                        }}
                                    />
                                </span>

                            </div>

                            <div className="verify-btn">
                                <button onClick={changePassword} className="btn btn-danger btn-block uth-input">Change Password</button>
                            </div>
                        </form>
                        }

                    </div>
                </div>
                </div>
            </Section>
        </SiteWide>
    );

}

export default ForgetPassword;