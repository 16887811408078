import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide, Images} from "./customComponents";

import {useHistory, useLocation} from 'react-router-dom';
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';

import useScript from './customComponents/useScript';

import APIRequests from '../utility/APIRequests';
import {staticCountries} from '../utility/countries';
import { setUserSession, setUserFinance, setUserToken } from '../utility/CommonAuth';
import Helper1, {notify} from '../utility/Helper1';
import {Alert} from "../alertServices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {authActions} from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { RiLoginBoxLine, RiEyeOffFill, RiEyeFill, RiHome7Line, RiArchiveLine, RiBarChart2Line, RiBriefcase4Fill, RiArchiveDrawerLine } from "react-icons/ri";
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';

const bacggroundImage = require('../images/home-2.jpg');

let referralToUse = localStorage?.getItem('ref') != 'null'?localStorage?.getItem('ref'):'';

function Signup() {


    const [username, setUsername]                             = useState('');
    const [email, setEmail]                             = useState('');
    const [withdrawalPin, setWithdrawalPin]             = useState('');
    const [showPin, setShowPin]                         = useState(false);
    


    const [password, setPassword]                       = useState('');
    const [loading, changeLoading]                      = useState(false);
    const [fullPageLoading, setFullPageLoading]         = useState(false);
    const [flashCounter, setFlashCounter]               = useState(0);
    const [showPassword, setShowPassword]               = useState(false);
    const [country, setCountry]             = useState(0); //'156'
    const [phone, setPhone]                 = useState('');
    const [phoneCode, setPhoneCode]           = useState('...'); //'+234'
    const [referral, setReferrals]           = useState(referralToUse); //'+234'
    const [agree, setAgree] = useState(false);



    const [walletAddress, setWalletAddress]             = useState('');
    const [bank, setBankName]                           = useState('');
    const [accountName, setAccountName]                 = useState('');
    const [accountNumber, setAccountNumber]             = useState('');
    const [depositMethod, setDepositMethod]             = useState(100);



    const site_settings = useSelector(allRemoteSettingsSelect);
    const search = useLocation().search;
    const referalLink = new URLSearchParams(search).get("ref");


    const history                                       = useHistory();
    const dispatch = useDispatch();

    const paymentMethod = [
        {
            label: 'Bank',
            tag: 0
        },
        {
            label: 'USDTTRC20',
            tag: 1
        },
    ]

    useEffect(() => {

        if((referalLink != '') && (referalLink!= null) && (referalLink!='null')){
            localStorage.setItem('ref', referalLink);
            // referralToUse = referalLink;
            setReferrals(referalLink)
        }
    }, [])


    const checkboxHandler = () => {
        // if agree === true, it will be set to false
        // if agree === false, it will be set to true
        setAgree(!agree);
        // Don't miss the exclamation mark
      }


    const doRegister = async (e) => {



        if(!agree){
            notify('You must accept the terms and condition to continue', 0);
            return;
        }
        
        e.preventDefault();
        setFullPageLoading(true);
        let financeLoad;


        let payLoad = {depositMethod, username, email, password, country, phone, withdrawalPin, referral}
        if(depositMethod == 0){
            financeLoad = {bank, accountName, accountNumber}
        }else{
            financeLoad = {walletAddress}
        }
        
        dispatch(authActions.onSignUp("account/register", Object.assign({}, payLoad, financeLoad), response => {

            if(response.code != 0){

                localStorage.removeItem('ref');
                
                // if(response.code == 1){
                    history.replace('/personal-information');
                // }else if(response.code == 2){
                //     history.replace('/personal-information');
                // }
                
            }else{
                notify(response.msg, response.code)
            }

            setFullPageLoading(false);
        
        }),
        );


    }


  const changeCountry = (countryId) =>{
    let selectedCountry = staticCountries.filter(e => e.id == countryId) 
    setCountry(countryId);
    let countryPhoneCode = selectedCountry?.[0]?.phonecode;
    setPhoneCode(countryPhoneCode);
    
  }


    const bg = require('../images/auth.jpg');


    return (
        <SiteWide backgroundImage={bg} hideMainDiv= {true} hideMenu={true} title="Registration">
            <Section hideHeader={true}  loading={fullPageLoading} title={'Registration'} home={<WebLink to="/signin">Sign in</WebLink>}
                rightTopMenu={<span style={{cursor:'pointer'}} onClick={() => history.replace('/signin')}>Sign in</span>}

            >
                

                
            <div className="limiter">
        <div className="container-login100">


            <div className="wrap-login100" style={{backgroundColor: otherColors.body}}>
                    <form className="contact-form-inner auth-box login100-form validate-form"  style={{marginTop: 50}}>


                        <div className="c-4 text-center">

                            <WebLink to={'/'} className="logo">
                                <Images height="70px" src={(site_settings?.site_settings?.logo)} alt="logo" />
                            </WebLink>

                            
                        </div><br /><br />

                            <label className="single-input-wrap">
                                <span className="text-info-1">Username*</span>
                                <input className="auth-input form-control" onChange={e => setUsername(e.target.value) } placeholder="UserName" required type="text" />
                            </label>

                            
                            <label className="single-input-wrap">
                                <span className="text-info-1">Email*</span>
                                <input className="auth-input form-control" onChange={e => setEmail(e.target.value) } placeholder="Email" required type="email" />
                            </label>


                        
                            <label className="single-input-wrap">
                                <span className="text-info-1">Password*</span>
                                <div className="input-group">
                                    <input type={ (showPassword) ? 'text' : 'password'} className="auth-input form-control" onChange={e => setPassword(e.target.value) } required placeholder="Password" />
                                    <div className="input-group-append" style={{position: 'absolute', right: 2, top: 4, color: '#fff', zIndex: 5}}>
                                        <span onClick={() => setShowPassword(!showPassword)} className="input-group-text cursor-pointer" id="basic-addon1">
                                            {(showPassword) ? <RiEyeOffFill /> : <RiEyeFill/>}
                                        </span>
                                    </div>
                                </div>
                            </label>


                            <label className="single-input-wrap">
                                <span className="text-info-1">Account Pin*</span>
                                <div className="input-group">
                                    <input maxLength={4} type={ (showPin) ? 'text' : 'password'} className="auth-input form-control" onChange={e => setWithdrawalPin(e.target.value) } required placeholder="Account Pin (4 Digits)" />
                                    <div className="input-group-append" style={{position: 'absolute', right: 2, top: 4, zIndex: 5}}>
                                        <span onClick={() => setShowPin(!showPin)} className="input-group-text cursor-pointer" id="basic-addon1">
                                            {(showPin) ? <RiEyeOffFill /> : <RiEyeFill />}
                                        </span>
                                    </div>
                                </div>
                            </label>




                            <label className="single-input-wrap">
                                <span className="text-info-1">Country*</span>
                                <select style={{height: 45, backgroundColor: 'rgba(0, 0, 0, 0.2)', color: 'grey', border: 'none'}} value={country} onChange={e => changeCountry(e.target.value) } required=""  className="auth-input form-control">
                                    <option hidden value="">Select country</option>
                                    {
                                        staticCountries.map((item, index) => 
                                            <option key={index} value={item.id}>{item.nicename}</option>
                                        )
                                    }
                                </select>
                            </label>

                            <label className="single-input-wrap">
                                <span className="text-info-1">Phone*</span>
                                <div className="input-group">
                                    <input type='text' style={{paddingLeft: 80}} className="auth-input form-control" onChange={e => setPhone(e.target.value) } required placeholder="Phone" />
                                    <div className="input-group-prepend" style={{position: 'absolute', width: 70, textAlign: 'center !important', left: 2, top: 4, zIndex: 99999999, borderRight: '1px solid #cecece'}}>
                                        <span style={{margin: '0 auto'}} onClick={() => setShowPassword(!showPassword)} className="input-group-text text-dark cursor-pointer no-background" id="basic-addon1">
                                            {phoneCode}
                                        </span>
                                    </div>
                                </div>
                            </label>


                            <label style={{display: 'none'}} className="single-input-wrap">
                                <span className="text-info-1">Invited By:</span>
                                <input defaultValue={referral} className="auth-input" onChange={e => setReferrals(e.target.value) } placeholder="Ref. Code Here (Optional)" type="text" required />
                            </label>


                            {(site_settings?.site_settings?.term ?? '') != '' && <div>
                              <input type="checkbox" id="agree" onChange={checkboxHandler} /> {" "}
                              <label className="text-info-1" htmlFor="agree"> I agree to <WebLink to={"/term"} style={{textDecoration: 'underline'}} className="cursor-pointer text-info-1"><b>terms and conditions</b></WebLink></label>
                            </div>}


                            <div className="container-login100-form-btn mt-40">
                                <div className="wrap-login100-form-btn">
                                    <div className="login100-form-bgbtn"></div>
                                    <button  onClick={doRegister} className="login100-form-btn bg-info">
                                        Submit
                                    </button>
                                </div>
                            </div>


                        </form>
                    </div>
                    </div>
                </div>
            </Section>
        </SiteWide>
    );

}

export default Signup;