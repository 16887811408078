import React, {useState} from "react";

import {WebLink, Images} from "../customComponents";
import { removeUserSession, setUserSession, setUserFinance, setUserToken} from '../../utility/CommonAuth';
import  {useHistory} from 'react-router-dom';
import Colors from '../../utility/Colors';
import {notify} from '../../utility/Helper1';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer } from 'react-toastify';
import APIRequests from '../../utility/APIRequests';
import LoadingOverlay from 'react-loading-overlay';

import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';
import {authActions} from '../../redux/actions';
import {menuItems} from '../../utility/menuLists';


import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { RiLoginBoxLine, RiHome7Line, RiBubbleChartLine, RiCollageFill, RiArchiveLine, RiBarChart2Line, RiBriefcase4Fill, RiArchiveDrawerLine } from "react-icons/ri";


    


function Navbar() {

    const userData = useSelector(userSelect);
    const dispatch = useDispatch();
    const site_settings = useSelector(allRemoteSettingsSelect);


    const hiddenFileInput = React.useRef(null);

    const [uploadingAvatar, setUploadingAvatar]           = useState(false);
    const history                                       = useHistory();

    const progClickFileUpload = () => {
        if(!userData?.image){ history.push('/signin'); return;}
        hiddenFileInput.current.click();
    }




    const instantUploadPreview = (file, destID) => {

        let output = document.getElementById(destID);

        // if(!Array.isArray(file)){ 
            // typeof file === 'object'
        if(file.type == undefined){
            output.src = file;
        

        }else{

            output.src = URL.createObjectURL(file);
            output.onload = function() {
                  URL.revokeObjectURL(output.src) // free memory
                }

        }


    };


    const uploadAvatar = async (file) => {

        // instantUploadPreview(file, 'avatarPlace');
        setUploadingAvatar(true)

        dispatch(authActions.onLogin("account/change-avatar", {avatar:file}, response => {
                setUploadingAvatar(false)
            }),
        )

        
    }


    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())
    }
    
    const activeBackgroundColor = {backgroundColor: Colors.sideMenu2}
    const pathname = window.location.pathname

    const bslit = require('../../images/profile-small.png')






    return (
        <div className="ba-navbar">

            <ProSidebar breakPoint="lg">
                
                    <SidebarHeader style={{backgroundColor: '#140d5d'}}>
                        <div style={{padding: 20}}>
                            <WebLink to={'/'} className="logo">
                                {(site_settings?.site_settings?.logo !=='') && <Images style={{height:'40px'}} className="cursor-pointer" source={(site_settings?.site_settings?.logo)} alt="logo" /> }
                            </WebLink>
                        </div>
                    </SidebarHeader>
                    <SidebarContent style={{backgroundColor: '#140d5d'}}>
                        <Menu>

                            {menuItems.map((item, index) => {
                                const MenuIcon = item.icon
                                return <MenuItem style={{marginBottom: 10}} icon={<MenuIcon size={40}/>}>
                                    <h4 className="text-white">{item.title}</h4>
                                    <WebLink to={item.address} />
                                </MenuItem>
                            })}


                            
                        </Menu>
                    </SidebarContent>

                    

                    <SidebarFooter style={{backgroundColor: '#140d5d'}}>

                        {((userData?.emailVerification ?? 100) != 100) && <Menu>
                            <MenuItem onClick={doLogOutNowNow} icon={<RiLoginBoxLine  size={40}/>}>
                                <h4 className="text-white">Log Out</h4>
                                <WebLink to="/" />
                            </MenuItem>
                        </Menu>}

                        {((userData?.emailVerification ?? 0) == 0) && <Menu>
                            <MenuItem onClick={doLogOutNowNow} icon={<RiLoginBoxLine  size={40} />}>
                                <h4 className="text-white">Account</h4>
                                <WebLink to="/personal-information" />
                            </MenuItem>
                        </Menu>}

                        


                    </SidebarFooter>
                
            </ProSidebar>

        </div>
    );
}


export default Navbar;