import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../utility/Helper1';
import {homepage} from '../utility/APIRequests';
import PinAuth from "./customComponents/PinAuth";


import Countdown from 'react-countdown';

import {Images} from "./customComponents";
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';


function Wallet({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const dispatch = useDispatch();

    const [password, setPassword] = useState('')
    const [withdrawalPin, setWithdrawalPin] = useState('')
    const [googleAuth, setGoogleAuth] = useState('')

    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');


    const doPasswordChange = async (e) => {

        e.preventDefault();

        if(password == ''){
            notify('Please input new password', 0);
            return false;
        }

        setloading(true);
        
        dispatch(authActions.onLogin("account/change_password", {password, withdrawalPin, googleAuth}, response => {
            if(response.code == 1){
                setPassword('');
                setWithdrawalPin('');
            }
            notify(response.msg, response.code)
            setloading(false);
        }),
        );

        
    }





    return (
        <SiteWide id="main" title={`Account wallet`}>
            <Section loading={loading} overlayText={loadingText} title={'Account wallet'}>
                
                <div className="container">

                    <form onSubmit={doPasswordChange} className="input-container">

                        <div>

                            {userData?.bankdetails?.map((item, detail) =><div class="form-group">

                                <span className="text-info-1">New password</span>
                                <input placeholder={"New password"} onChange = {e => setPassword(e.target.value) } required="" className="form-field form-control" type="text" />
                                
                            </div>)}


                            <PinAuth userData={userData} setWithdrawalPin={setWithdrawalPin} setGoogleAuth={setGoogleAuth} />

                        </div>

                        <div>
                            <button type="submit" disabled={loading} className="btn mt-40 btn-block btn-danger" style={{background: otherColors.btn1}}>
                            Save </button>
                        </div>
                        
                    </form>

                </div>


            </Section>
        </SiteWide>
    );

}

export default Wallet;