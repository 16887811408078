import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../utility/Helper1';
import {homepage} from '../utility/APIRequests';


import Countdown from 'react-countdown';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import {staticCountries} from '../utility/countries';
import {Images} from "./customComponents";
import Slider from "react-slick";
import { RiPhoneLockFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';


function Wallet({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [emailOTP, setEmailOTP]   = useState('');

    
    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');

    const dispatch = useDispatch();

    


    const doWithdrawalPinChange = (e) => {

        e.preventDefault();
        setloading(true)
        setLoadingText('Changing Withdrawal PIN...')

        dispatch(authActions.onLogin(`account/withdrawal-pin/change`, {emailOTP, withdrawalPin}, response => {
            notify(response.msg, response.code)
            setloading(false);
            if(response.code == 1){  setWithdrawalPin(''); }
        }),
        );

    }
  const getNewEmailOTP = () => {

        dispatch(authActions.onLogin(`account/send-email-otp/withdrawal-pin`, {}, response => {

        }),
        );

    }


    useEffect(() => {
        getNewEmailOTP();
    }, [])

    return (
        <SiteWide title={`Change PIN`}>
            <Section loading={loading} overlayText={loadingText} title={'Change PIN'}>
                
                <div className="container">

                    <form onSubmit={doWithdrawalPinChange} className="input-container">

                        <div>

                            <div class="fooup">
                                <span className="text-info-1">New Pin</span>
                                <input maxLength={4} autoComplete="new-password" placeholder="New Pin" onChange = {e => setWithdrawalPin(e.target.value) } required="" className="form-field form-control" type="password" />
                                
                            </div>



                            <div className="foup">
                                <span className="text-info-1">OTP</span>
                                <input type={"text"} className="form-field form-control" onChange={e => setEmailOTP(e.target.value) } required placeholder="OTP" />
                            </div>



                        </div>


                        <div>

                        <Countdown
                            zeroPadTime={2}
                            key={new Date(userData?.withdrawalPinResetTime ?? 0)}
                            date={new Date(userData?.withdrawalPinResetTime ?? 0)}
                            renderer={({ hours, minutes, seconds, completed }) => {
                              if (completed) {
                                return <span className="text- pull-right" onClick={getNewEmailOTP}>Request OTP</span>
                              } else {
                                return <span className="text- pull-right">{minutes}:{seconds} seconds</span>;
                              }
                            }}
                          />
                            <button type="submit" disabled={loading} className="btn mt-40 btn-block btn-danger" style={{background: otherColors.btn1}}>
                            Save </button>
                        </div>
                        
                    </form>

                </div>


            </Section>
        </SiteWide>
    );

}

export default Wallet;