import React, {Component, useState} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';

import {Images} from "./customComponents";
import parse from 'html-react-parser';

    

function Certification(){

    const site_settings = useSelector(allRemoteSettingsSelect);
    const [lightImages, setLightImages]   = useState([]);
    const [openLightBox, setOpenLightBox]   = useState(false);

    return (
        <SiteWide id="main" title={"News"}>
            <Section  title={'News'}>
                {/* transaction start */}
                <div className="container">
                    <div className="" style={{marginTop: 100, padding: 20}}>

                        <div className="row" style={{padding: 30, backgroundColor: otherColors.card2}}>
                            <div className="col-4">

                                <Images className="w-100" src={site_settings?.image_settings?.[0]?.image1} alt="About" />
                            
                            </div>

                            <div className="col-8" style={{color: '#fff !important'}}>

                                <span className="text-info-1">{parse(site_settings?.content_settings?.[0]?.con2 ?? '')}</span>
                            
                            </div>
                            
                        </div>
                        
                        
                    </div>
                </div>



                {/* transaction End */}
            </Section>
        </SiteWide>
    );

}

export default Certification;