
export const mainColor 		= '#3629b7'
export const header 		= '#3629b7'
export const mainHeader 	= '#3629b7'
export const footer 		= '#3629b7'

// '#262a35'

export const otherColors = {
	// card: '#6a53ec',
	// card: 'linear-gradient(rgba(250, 188, 60, 1) 0%, rgba(241, 241, 241) 100%)',
	card: '#fff',
	card2: '#f0f3f7',
	body: '#f1f1f1',
	cotpBlue: '#00b4ff',
	text: '#fff',
	text2: '#01b574',
	// btn1: 'radial-gradient(ellipse farthest-corner at right bottom,#000 0%,#000 8%,#9f7928 30%,#8a6e2f 40%,transparent 80%), radial-gradient(ellipse farthest-corner at left top,#ffffff 0%,#000 8%,#d1b464 25%,#000 62.5%,#000 100%)',
	btn1: 'blue',
}


// #2a7ef2



export const mainStyle = {
	mainBackground: {
		backgroundColor: '#041f4b', 
		padding: 20, 
		borderRadius: 10, 
		color: mainColor
	},
	color_1:{
		color: mainColor
	}
}