import React, {useState, useEffect} from "react";
import {Images} from "./index";


function PinAuth(props) {

    const {setWithdrawalPin, setGoogleAuth, userData} = props

    return <div>
                <div class="foroup">
                    <span className="text-info-1">Pin</span>
                    <input maxLength={4} autoComplete="new-password" placeholder="Pin" onChange = {e => setWithdrawalPin(e.target.value) } required="" className="form-field form-control" type="password" />
                </div>

                {userData?.use2FA == 1 && <div class="form-group">
                    <span className="text-info-1">Google Auth</span>
                    <input autoComplete="new-password" placeholder="Code" onChange = {e => setGoogleAuth(e.target.value) } required="" className="auth-inpu  form-field form-control" type="password" />
                </div>}

            </div>

}

export default PinAuth;