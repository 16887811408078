import React, {Component} from "react";
import Colors from '../../utility/Colors';
import {mainStyle, mainColor, header, footer} from '../../utility/styles';
import {menuItems} from '../../utility/menuLists';

import {WebLink, SiteWide, Images} from "../customComponents";

class Footer extends Component {
    render() {
        return (
            <>
                <div className="footer-area" style={{background: footer}}>
                   
                    <div style={{}} id="floatFootMenu">
                        <div className="footer-bottom" 
                            style={{background: footer, border: '0px #d5d5d5 solid'}}
                        >
                            <ul style={{display: 'flex', color: '#cecece'}}>
                                
                                {menuItems.map((item, index) => {
                                    const MenuIcon = item.icon
                                    return <li className="each-bottom-menu">
                                    <WebLink style={{color: '#000'}} className="home-clicked" to={item.address}>
                                        <MenuIcon color={'#0075ff'} size={35} />
                                        <p className="text-white"><span style={{color: '#fff'}}>{item.title}</span></p>
                                    </WebLink>
                                </li>})}

                            </ul>
                        </div>
                    </div>
                </div>

                {/* Back to Top Area */}
                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up" /></span>
                </div>
            </>
        );
    }
}

export default Footer;