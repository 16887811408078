import React, {useState, useEffect, useRef} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import {Link} from "react-router-dom";
import AutoNotification from "./layouts/AutoNotification";
import Section from "./layouts/Section";
import CustomAgo from "./customComponents/CustomAgo";
import {WebLink, SiteWide, Images} from "./customComponents";
import PaginatedItems from "./customComponents/PaginatedItems";

import {mainStyle, mainColor, header, otherColors} from '../utility/styles';
import Modal from 'react-modal';

import { useSelector, useDispatch } from 'react-redux';
import {tradeActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, allTradeSelect } from '../redux/selectors/index';
import Helper1, {notify, styles, money, customNormalModalStyles, currencySliderArray} from '../utility/Helper1';
import { useSnackbar } from 'snackfy';
import Countdown from 'react-countdown';
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { Dropdown } from 'semantic-ui-react'
import ModalCustomHead from "./customComponents/ModalCustomHead";
import parse from 'html-react-parser';



import {
    Menu,
    MenuItem,
    MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';



import {RiMore2Fill, RiAddBoxFill, RiCloseLine, RiEye2Line, RiIndeterminateCircleFill, RiExchangeLine, RiBankFill, RiHandCoinFill, RiFundsFill, RiPieChartFill} from "react-icons/ri";


const tradeBTN = require('../images/trade_button_bg.jpg');
const waveBg = require('../images/images.jpeg');
  



const balanceSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,

        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 1
            }
        }]
    };











function Arbitrage() {



    const chooseCurrency2 = useRef();
    const chooseCurrency1 = useRef();

    const site_settings = useSelector(allRemoteSettingsSelect);

    const [loading, setloading]   = useState(false);
    const [tradeDataToShow, setTradeDataToShow]   = useState([]);
    const [loadingText, setLoadingText]   = useState('');
    const [currentSentTradeData, setCurrentSentTradeData]   = useState({});
    const tradesData = useSelector(allTradeSelect);
    const finance = useSelector(homeScreenStatSelect);
    const [selectedPlan, setSelectedPlan]   = useState(site_settings?.plans?.[0]?.childs?.[0]);
    const [openModal, setOpenModal]   = useState(false);

    const [openTradeModal, setOpenTradeModal]   = useState(false);
    const [tradeSaleData, setTradeSaleData]   = useState({});

    const [tradeCapital, setTradeCapital]   = useState('');
    const [selectedImage, setSelectedImage]   = useState('');
    const [selectedCurrency, setSelectedCurrency]   = useState('');
    const [actionOnInvestment, setActionOnInvestment]   = useState(null);


    // const tradeDataLength = 10 + (tradeDataToShow?.length ?? 0);
    const dispatch = useDispatch();

    const arbi = require('../images/arbi.jpg');

    const type              = finance?.[0]?.type
    const allUnprocessed    = tradesData?.childs?.filter((item, index) => item.isProcessed == 0)
    const today             = tradesData?.childs?.filter((item, index) => item.today == 1)
    const active            = tradesData?.childs?.filter((item, index) => item.active == 1)
    const inActive          = tradesData?.childs?.filter((item, index) => item.active == 0)
    const allUnprocessedSum = allUnprocessed?.reduce((a, b) => Number(a) + Number(b.profit), 0)

    const allProfits = today?.reduce((a, b) => Number(a) + Number(b?.profit), 0);
    const allCapitals = today?.reduce((a, b) => Number(a) + Number(b?.capital), 0);
    const allInactiveTrades = active?.reduce((a, b) => Number(a) + Number(b?.capital), 0);
    



    const systemPlans       =   site_settings.plans.[0].childs;




    const dashMenu = [
        {
            icon: RiExchangeLine,
            name: 'Successful transaction',
            figure: (today?.length ?? 0),
        },
        {
            icon: RiHandCoinFill,
            name: 'Income from transactions',
            figure: money(allProfits, type),
        },
        {
            icon: RiFundsFill,
            name: 'Transaction amount',
            figure: money(allCapitals, type),
        },
        {
            icon: RiPieChartFill,
            name: 'Total trades',
            figure: money(allCapitals+allProfits, type),
        },
    ]


    if (openTradeModal) {
      // push to history when modal opens
      window.history.pushState(null, '', window.location.href)
      
      // close modal on 'back'
      window.onpopstate = () => {
        window.onpopstate = () => {}
        window.history.back()
        setOpenTradeModal(false)
      }
    }


    const loadAllTrade = () => {
        dispatch(tradeActions.onGETTheTrades("trade/all", response => {
            })
        )
    }

    const intermittentTrade = () => {

        if(allUnprocessed?.length == 0){ notify('No order currently available to process', 0); return; }

        setloading(true);
        setLoadingText('Processing...')

        dispatch(tradeActions.onGETTheTrades("trade/process/manual", response => {
            setloading(false);
            notify(response.msg, response.code)
            })
        )
        
    }

    const terminateContract = (id) => {

        setloading(true);
        setLoadingText('Terminating contract...')

        dispatch(tradeActions.onGETTheTrades(`trade/close/${id}`, response => {
            setloading(false);
            notify(response.msg, response.code)
            })
        )

    }

    const startTradeModal = (currency) => {

        setSelectedCurrency(currency)
        setOpenTradeModal(true)

    }

    const tradeNow = (step = 0) => {

      setloading(true);
      setLoadingText(step == 0?'Searching...':'Selling to merchant');

      dispatch(tradeActions.onPOSTTheTrades({step, currency:selectedPlan?.type, amount: tradeCapital}, "trade/new", response => {

                setloading(false)
                

                if(response.code == 1){
                    setloading(false);
                    
                    if(step == 0){
                         // setStep(1);
                            setOpenTradeModal(true)
                            setTradeSaleData(response.data)

                    }else if(step == 1){
                         // setStep(0);
                         referralData()
                         setOpenTradeModal(false)
                         notify(response.msg, response.code);
                    }
                    
                }else{
                    notify(response.msg, response.code);
                }
                
            }),
        )

    }



    const actionOnInvestmentFn = (item, compoundOrWithdraw) => {
        
        setActionOnInvestment(item.txn_id);

        dispatch(tradeActions.onPOSTTheTrades({txnId: item.txn_id, compoundOrWithdraw}, "trade/resolve-profit-action", response => {

                setActionOnInvestment(null)
                notify(response.msg, response.code);

                // if(response.code == 1){
                    
                // }else{
                    
                // }
                
            }),
        )

    }

    useEffect(() => {

        loadAllTrade();

        const reloadEverytime = setInterval(() => {
            loadAllTrade();
        }, 15000)
        
        return () => clearInterval(reloadEverytime);
    }, [])



    useEffect(() => {
        if(tradesData?.childs){
            referralData()
        }
    }, [tradesData])


    const referralData = () => {
        // const tradeDataLength = 10 + (tradeDataToShow?.length ?? 0);
        const tradeDataLength = (tradeDataToShow?.length ?? 0);

        const tradesDataCloned = [...tradesData.childs]

        const newLength = tradesDataCloned?.slice(0, (tradeDataLength + 10));

        // setTradeDataToShow((e) => [...e, ...newLength])
        setTradeDataToShow((e) => [...newLength])

    }
    


    const customNormalModalStyles2 = {
      content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        width: 400,
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: 'transparent',
        zIndex: 999999,
        border: 'none',
        
      },

      overlay: {
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 99999999,
            background: 'rgba(0, 0, 0, 0.8)',
            padding: 0,
            margin: 0,
            border: 'none',
          }
    };


    const plansHere = site_settings?.plans?.filter((item, index) => item.mother.type == selectedCurrency)



function Template({ currentItems }){
    return (
        <>
            {currentItems &&
                currentItems.map((item) => {

                const botName = systemPlans?.filter((eachPlan, eachPlanIndex) => eachPlan.type == item.type)

                return <div className="ba-bill-pay-inner items" style={{background: '#0075ff', border: 'none'}}>

                    

                    <div className="ba-single-bill-pay">
                        <div className="thumb" style={{background: 'transparent'}}>
                            <Images height={40} source={botName?.[0]?.image} alt="img" />
                        </div>
                        <div className="details" style={{width: '100%'}}>
                            <h5 style={{display: 'flex'}}>
                                
                                <div>
                                    <span className="text-white">
                                        {botName?.[0]?.name ?? 'Trade'}
                                    </span>

                                    <div style={{}}>
                                        <Countdown
                                            date={new Date(item?.timeToMaturedForCalculation)}
                                            renderer={({ hours, minutes, seconds, completed }) => {
                                              if (completed || (item.active == 0)) {
                                                return <small className="text-white"><CustomAgo time={item?.timeToMaturedForCalculation} /></small>
                                              } else {
                                                return <small className="text-white">{Number(item.currentRound) + 1}/{item.totalRound} matures in {hours}:{minutes}:{seconds}</small>;
                                              }
                                            }}
                                          />
                                    </div>
                                </div>




                                <span className="text-white" style={{marginLeft: 'auto'}}>
                                    {money(Number(item.capital) + Number(item.compoundingBalance), item.currencyId)}<br />
                                    {Number(item.compoundingBalance) > 0 && <small style={{fontSize: 10}}>{money(item.compoundingBalance, item.currencyId)} included</small>}
                                </span>

                                {item.useTermination == 1 && <span>
                                    <Menu menuButton={<MenuButton style={{marginLeft: 10}}><RiMore2Fill /></MenuButton>} transition>
                                        {/*<MenuItem onClick={() => terminateContract(item.id)} disabled={(item.terminated == 1 || item.active == 0)?true:false}>Terminate contract</MenuItem>*/}
                                    </Menu>
                                </span>}
                            </h5>
                            <p style={{display: 'flex'}}>
                                <span style={{marginLeft: 'auto'}}>
                                    {item.isProcessed == 0?'':''}
                                </span>
                            </p>
                            <p>
                                
                            </p>
                        </div>

                        

                    </div>
                    <div className="amount-inner">
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                
                                <h5 className="text-white">Profit</h5>
                                
                                <div>

                                    {money(Number(item.profit) + Number(item.wallet), item.currencyId)}

                                </div>


                            </div>

                            <div className="text-white" style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                                
                                {Number(item.yetToCompoundBalance) > 0 && <div style={{marginTop: 3, flexWrap: 'wrap', justifyContent: 'flex-end'}} className="flexify">
                                    <button disabled={item.txn_id === actionOnInvestment} onClick={() => window.confirm(`${money(item.yetToCompoundBalance, item.currencyId)} will be added to your balance for withdrawal. Are you sure you want to proceed?`) && actionOnInvestmentFn(item, 1)} style={{fontSize: 10, borderRadius: 5, marginRight: 5, marginBottom: 5, padding: 2, paddingRight: 11, paddingLeft: 11, backgroundColor: '#f7f8fa'}} className="">
                                        Withdraw ({money(item.yetToCompoundBalance, item.currencyId)})
                                    </button>
                                    <button disabled={item.txn_id === actionOnInvestment} onClick={() => window.confirm(`${money(item.yetToCompoundBalance, item.currencyId)} will be compounded. Are you sure you want to proceed?`) && actionOnInvestmentFn(item, 0)} style={{fontSize: 10, borderRadius: 5, marginBottom: 5, backgroundColor: '#f7f8fa', padding: 2, paddingRight: 11, paddingLeft: 11,}} className="">
                                        Compound ({money(item.yetToCompoundBalance, item.currencyId)})
                                    </button>
                                </div>}

                            </div>
                            
                        </div>
                    </div>
                </div>

            })}
        </>
    );
}

    return (
        <SiteWide title={"Ai Bot Trades"}>
            <Section overlayText={loadingText} loading={loading} title={'Ai Bot Trades'}>



                <div class="">
                                       
                    {/*<div class="alarm-content">
                        

                        <div class="alarm-ul" style={{padding: 10, marginLeft: 10, marginRight: 10, backgroundColor: otherColors.cotpBlue, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>


                            <div className="row" >

                                <div className="col-12 text-left text-dark-2" style={{marginBottom: 30}}>

                                    <h6 style={{color: '#fff'}}>{parse(site_settings?.content_settings?.[0]?.con3)}</h6>
                                    <p style={{color: '#fff'}} className="text-white">{parse(site_settings?.content_settings?.[0]?.con4)}</p>

                                </div>

                                <br />
                                <div className="col-6 text-center">
                                    <p className="text-dark-2 h6">In the transaction</p>
                                    <h3 className="text-dark-2 ext-success">{money(allInactiveTrades, type)}</h3>
                                </div>

                                
                                <div className="col-6 text-center" style={{borderLeft: '2px solid #f7f8fa'}}>
                                    <p className="text-dark-2 h6">Wallet Balance</p>
                                    <h3 className="text-dark-2 txt-primary">{money(finance?.[0]?.balance ?? 0, type)}</h3>
                                </div>

                            </div>
                                
                            <hr style={{backgroundColor: '#f7f8fa'}} />

                            <div className="cntainer">
                                <div className="row">
                                    
                                    {dashMenu.map((item, index) => {

                                        let Icon = item.icon

                                        return <div className="col-6 text-dark-1">
                                        <div style={{display: 'flex', alignItems: 'center', marginTop: 30}}>
                                            <div style={{width: 25, height: 25, borderRadius: '50%', border: '0px solid grey',
                                            alignItems: 'center', justifyContent: 'center', lineHeight: '33px', textAlign: 'center'}}>
                                                <Icon color='#000' size={20}/>
                                            </div>
                                            <div style={{marginLeft: 10}}>
                                                <p className="text-white"><small>{item.name}</small></p>
                                                <h5 className="text-white">{item.figure}</h5>
                                            </div>
                                        </div>
                                    </div>})}



                                </div>

                                <br /><br />

                            </div>

                        </div>

                    </div>*/}




                    <div className="" style={{padding: 10}}>

                        <div style={{color: '#fff', borderRadius: 10}} className="op-report">


                            {(tradesData?.childs?.length ?? []) > 0 && <PaginatedItems Template={Template} arrayOfDataToDisplay={tradesData?.childs} itemsPerPage={4} />}


                        </div>

                    </div>





                </div>






            </Section>
        </SiteWide>
    );

}

export default Arbitrage;