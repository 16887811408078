import { all,call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";

import APIRequests from '../../utility/APIRequests';




function* onPOSTExchange(action) {
console.log('are you werking???')

  try {

    const response = yield (new APIRequests).runPost(action.routeToCall, action.param);

    if (response.code == 1) {

      yield put({ type: actionTypes.EXCHANGE_SAVE, data: response.data });

    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}


function* onGETExchange(action) {
  try {
    const response = yield (new APIRequests).runGet(action.routeToCall);

    if (response.code == 1) {
      yield put({ type: actionTypes.EXCHANGE_SAVE, data: response.data });
    }

    return action.callback?.(response);
  } catch (error) {
    return action.callback?.(error.response ?? error.message);
  }
}


function* watchOnPOSTExchange() {
  yield takeEvery(actionTypes.EXCHANGE_POST, onPOSTExchange);
}


function* watchOnGETExchange() {
  yield takeEvery(actionTypes.EXCHANGE_GET, onGETExchange);
}



export default function* exchangeSagas() {
  yield all([watchOnPOSTExchange(), watchOnGETExchange()]);
}