import {Link, useHistory} from 'react-router-dom';
import { store } from "../redux/store";
import {authActions} from '../redux/actions';


const getToken = () => store.getState().auth?.user?.token;

export const homepage = process.env.REACT_APP_HOMEPAGE;
export const contact_us = process.env.REACT_APP_CONTACT_PAGE;


export default class APIRequests{

  // baseUrl = process.env.REACT_APP_BASE_URL
  // const json = JSON.parse(loadedData);

  // baseUrl="http://192.168.84.113/ola_gold22.8.1/public/web/v1/";
  // baseUrl="http://localhost/ola_gold22.8.1/public/web/v1/";
  baseUrl="https://admin.phantomarbitrage.com/web/v1/";
  
  // baseUrl="https://visorotc.com/web/v1/";
  
  runGet = async (pointEnd) => {

    const accessToken = getToken();

    let data      =   await fetch(this.baseUrl + pointEnd, {
                                headers: {
                                  'Authorization': accessToken,
                                }
                            });
    let response  =   await data.json();
    this.requestActionizer(response);
    return response;

  }
 
  runPost = async (pointEnd, postObject, contentType = 0) => {

    const accessToken = getToken();

    try{

      let ct = (contentType==0)?'application/json':contentType;
      
      const formData = new FormData();
      
      for ( let key in postObject) {
        formData.append(key, postObject[key]);
      }

      let data = await fetch(this.baseUrl + pointEnd, {
          method: 'POST',
          headers: {
              
            'Authorization' : accessToken,

          },
          body: formData,
          
      });



      let response = await data.json();
      this.requestActionizer(response);
      return response;
    
    }catch(err){
      // console.error(err);
      return {code: 0, msg: 'Network request failed. Please check internet connection.'};
    }

  }


  requestActionizer = (response) => {

      if(response.code === -1){
        store.dispatch(authActions.onLogout())
      }

  }


}