import React, {useState} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../utility/Helper1';
import {homepage} from '../utility/APIRequests';


import Countdown from 'react-countdown';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import copy from 'copy-to-clipboard';

import Modal from 'react-modal';
import {staticCountries} from '../utility/countries';
import {Images} from "./customComponents";
import Slider from "react-slick";
import { RiPhoneLockFill, RiBankCardFill, RiArrowRightCircleFill, RiFileCopyFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';
import { useHistory } from 'react-router-dom'; // version 5.2.0

import { FcParallelTasks, FcRadarPlot } from "react-icons/fc";


function Security(){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const history = useHistory();

    const [financeEditing, setFinanceEditing]   = useState(0);
    const [walletModal, setWalletModal]   = useState(false);
    const [withdrawalPinModal, setWithdrawalPinModal]   = useState(false);
    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [emailOTP, setEmailOTP]   = useState('');
    const [phoneModal, setPhoneModal]   = useState(false);
    
    const [phone, setPhone]   = useState(userData?.phone ?? '');
    const [phoneCode, setPhoneCode]   = useState('...');
    const [country, setCountry]             = useState(userData?.country ?? ''); //'156'

    const [passwordModal, setPasswordModal]   = useState(false);
    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');

    const [password, setPassword]       = useState('');

    const [wallet, setWallet]       = useState(userData?.trc20tetherwallet ?? '');
    const [bank, setBank]       = useState(userData?.bank ?? '');
    const [accountName, setAccountName]       = useState(userData?.accountName ?? '');
    const [accountNumber, setAccountNumber]       = useState(userData?.accountNumber ?? '');

    const dispatch = useDispatch();

    const hiddenKYCInput = React.useRef(null);

    const doPasswordChange = async (e) => {

        if(password == ''){
            notify('Please input new password', 0);
            return false;
        }
        setloading(true);
        e.preventDefault();
        dispatch(authActions.onLogin("account/change_password", {password, pin: withdrawalPin}, response => {
            if(response.code == 1){
                setPassword('');
                setWithdrawalPin('');
            }
            notify(response.msg, response.code)
            setloading(false);
        }),
        );

        
    }




    const popUpAccountModal = async (financeWo) => {
        setFinanceEditing(financeWo)
        setWalletModal(true)
    }


    const popUpPhoneModal = () => {
        setPhoneModal(true)
    }


    const saveBTNJSX = (callback, close) => (
        <>
            <button onClick={callback}  type="button" disabled={loading} className="btn btn-block btn-primary" style={{backgroundColor: otherColors.btn1}}>
            OK </button>

        </>
        )

    const closeModal = (close) => {
        return false;
        // <div className="text-center mt-40"><WebLink onClick={() => close(false)} className="btn btn- btn-danger cursor-pointer">Close</WebLink></div>
    }

    const doPhoneChange = () => {
        setloading(true);
        setLoadingText('Updating phone...')
        dispatch(authActions.onLogin("account/update_phone", {country, phone, pin: withdrawalPin}, response => {
            if(response.code == 1){
                setWithdrawalPin('')
            }
            notify(response.msg, response.code)
            setloading(false);
        })
        );
    }

    const retrieveDepositBonus = () => {
        setloading(true);
        setLoadingText('Retrieving deposit bonus...')

        dispatch(authActions.onLogin(`withdraw/bonus`, {currency: finance?.[0]?.type}, response => {
            notify(response.msg, response.code)
            if(response.code == 1){
                
            }
            setloading(false);
        }))

    }


    React.useEffect(() => {
        changeCountry(country)
    }, [])

    const changeCountry = (countryId) =>{
        let selectedCountry = staticCountries.filter(e => e.id == countryId) 
        setCountry(countryId);
        let countryPhoneCode = selectedCountry?.[0]?.phonecode;
        setPhoneCode(countryPhoneCode);

    }


    const kycStatus = () => {
        let statusText = 0;
        let newArray = userData?.kyc?.map((item, index) => {
            if(item.status == 2){
                statusText = 'Approved';
            }else if(item.status == 1){
                statusText = 'Processing';
            }else if(item.status == 0){
                statusText = 'Pending';
            }
        })

        return statusText;
    }



    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {

        }))

    }

    React.useEffect(() => {

        let timing = setTimeout(() => {
            loadAllHomePageData();
        }, 1000)
        
        return () => clearTimeout(timing);
    }, [])

    const pinValidator = () => {
        return <div>
            <div class="form-group">
            <span className="text-info-1">PIN</span>
                <input maxLength={4} autoComplete="new-password" placeholder="Pin" onChange = {e => setWithdrawalPin(e.target.value) } required="" className="auth-inpu  form-field form-control" type="password" />
            
        </div></div>
    }

    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
        }]
    };


    const goHome = () => {
        window.location.href = homepage;
    }

    const copyNow = () => {
        let userRef = site_settings?.site_settings?.site_url+'/#/signup?ref='+userData?.myrefid
        copy(userRef)
        notify("copied Link", 1);
    }

    const dashMenu = [
        
        {
            icon: FcParallelTasks,
            name: 'Transaction PIN',
            color: '#392',
            to: () => history.push('change-pin'),
            value: "******"
        },
        // {
        //     icon: FcRadarPlot,
        //     name: 'Bind Google Authenticator',
        //     color: '#392',
        //     to: () => history.push('google-auth'),
        //     value: "******"
        // },

        
        // {
        //     icon: RiEarthLine,
        //     name: 'Home',
        //     color: '#ccc23a',
        //     to: () => goHome(),
        // },
        // {
        //     icon: RiStackshareLine,
        //     name: 'About',
        //     color: '#AE95A4',
        //     to: () => history.push('/about'),
        // },

        
        
    ]



    return (
        <SiteWide id="main" title={`Information`}>
            <Section loading={loading} overlayText={loadingText} title={'Information'}>

                    <div className="" style={{}}>

                        <div className="row">
                            <div className="col-xlg-12 col-md-12 col-12" style={{}}>
                                <div style={{}} className="top-report">


                                        

                                        {dashMenu.map((item, index) => {

                                            const Icon = item.icon;

                                            return <WebLink style={{padding: 20, display: 'flex', justifyContent: 'space-between', marginTop: 20, color: '#fff', borderRadius: 10}} title={item.name} onClick={item.to}>

                                                
                                                <div style={{display: 'flex'}}>
                                                    <span><Icon color={otherColors.text} size="24"/> </span>
                                                    <h6 className="text-info-1" style={{marginLeft: 20}}>{item.name}</h6>
                                                </div>


                                            </WebLink>})}




                                </div>
                            </div>
                        </div>

                    </div>

                



                <Modal
                    isOpen={passwordModal}
                    onRequestClose={() => setPasswordModal(false)}
                    contentLabel="Example Modal"
                    style={customNormalModalStyles}
                    className="add-balance-inner-wrap"
                    
                  >
                    <div>
                        <div className="modal-content" style={{backgroundColor: otherColors.cotpBlue, boxShadow: 'none'}}>
                            <div className="modal-body">
                                <form onSubmitFaked={doPasswordChange} className="cntact-f-box" style={{}}>
                                    <div class="form-group">
                                        <span className="text-info-1">Password</span>
                                        <input autoComplete="new-password" placeholder="New password" onChange = {e => setPassword(e.target.value) } required="" className="form-field form-control" type="password" />
                                        
                                    </div>
                                    
                                    {pinValidator()}

                                    {saveBTNJSX(doPasswordChange, setPasswordModal)} 
                                    
                                </form>
                            </div>
                            
                        </div>
                    </div>

                    {closeModal(setPasswordModal)}

                </Modal>





            </Section>
        </SiteWide>
    );

}

export default Security;