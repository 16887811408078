import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import CustomAgo from "../customComponents/CustomAgo";
import {WebLink, SiteWide, Images} from "../customComponents";
import {mainStyle, mainColor, header} from '../../utility/styles';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {exchangeActions, authActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, exchangeSelect } from '../../redux/selectors/index';
import Helper1, {notify, styles, money, customModalStyles, moneyStatus, usefulColors} from '../../utility/Helper1';
import { useHistory, useLocation } from "react-router-dom";

function AllKyc() {

    const dispatch = useDispatch();
    const location = useLocation();

    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');
    const [messageObject, setMessageObject]   = useState([]);

    const [kycFile, setKycFile]   = useState('');
    const [firstname, setFirstname]   = useState('');
    const [lastname, setLastname]   = useState('');
    const [address, setAddress]   = useState('');
    const [dob, setDob]   = useState('');
    const [sex, setSex]   = useState('');

    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);

    const hiddenKYCInput = React.useRef(null);


    const instantUploadPreview = (file, destID) => {

        let output = document.getElementById(destID);

        // if(!Array.isArray(file)){ 
            // typeof file === 'object'
        if(file.type == undefined){
            output.src = file;
        }else{

            output.src = URL.createObjectURL(file);
            output.onload = function() {
                  URL.revokeObjectURL(output.src) // free memory
                }
        }
    };


    const kycImageSelected = (file) => {
        setKycFile(file)
        instantUploadPreview(file, 'changeImage')
    }

    // uploads
    const progClickFileUpload = () => {
        hiddenKYCInput.current.click();
    }

    const uploadKYC = (file) => {
        

        if(kycFile == ''){ notify('A valid document yet to be selected', 0); return; }

        setloading(true);
        setLoadingText('Uploading KYC...')

        dispatch(authActions.onLogin("kyc/upload", {image:kycFile, firstname, lastname, address, dob, sex}, response => {
                setloading(false)
                notify(response.msg, response.code)
                if(response.code == 1){
                    setFirstname('');
                    setLastname('');
                    setAddress('');
                    setDob('');
                    setSex(0)
                }
            }),
        )
    }



    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {

        }))

    }

    React.useEffect(() => {

        let timing = setTimeout(() => {
            loadAllHomePageData();
        }, 1000)
        
        return () => clearTimeout(timing);
    }, [])

    return (
        <SiteWide id="main" title={"All KYC Applications"}>
            <Section overlayText={loadingText} loading={loading} title={'All KYC Applications'}>
                
                <div className="page-wrapper">

                    <div className="applications" style={{backgroundColor: '#fff', padding: 10, marginTop: 80}}>
                        

                        <div className="applications-preview" style={{background: 'transparent'}}>
                            

                            {userData?.kyc?.map((kycItem, kycIndex) => <div className="preview-card" style={{background: 'transparent', border: '1px solid grey', marginBottom: 10}}>
                                <div className="applicant-details">
                                    <div className="applicant-name">{kycItem.firstname} {kycItem.lastname}</div>
                                    <div className="application-status accepted">{moneyStatus()[kycItem.status]}</div>
                                </div>
                                <div className="applicant-address">
                                    {kycItem.address}
                                </div>
                                <div className="application-date">
                                    <div className="application-id">
                                        <p className="head">Application ID</p>
                                        <p className="text-whie">#{kycItem.txn_Id}</p>
                                    </div>
                                    <div className="last-updated">
                                        <p className="head">Date of birth</p>
                                        <p className="text-whte">{kycItem.dob}</p>
                                    </div>
                                </div>
                            </div>)}
                            

                        </div>
                    </div>

                </div>

            </Section>
        </SiteWide>
    );

}

export default AllKyc;