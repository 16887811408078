import React, {Component, useState} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';

import {Images} from "./customComponents";
import parse from 'html-react-parser';

    

function News(){

    const site_settings = useSelector(allRemoteSettingsSelect);
    const [lightImages, setLightImages]   = useState([]);
    const [openLightBox, setOpenLightBox]   = useState(false);

    return (
        <SiteWide id="main" title={"News"}>
            <Section  title={'News'}>
                {/* transaction start */}
                <div className="container">
                    <div className="" style={{}}>

                        {site_settings?.news?.map((item, index) => <div className="abut-content-inner" style={{padding: 20, marginBottom: 30, backgroundColor: otherColors.cotpBlue}}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>

                                <h5 className="text-info-1">{item.subject}</h5>
                                {item.image != null && <a href={item.image}><Images src={item.image} style={{width: 40, height: 40, borderRadius: '50%'}} className="img-xs img-round cursor-pointer" /></a>}
                            </div>
                            <hr />
                            <p className="text-info-1">{parse(item.message)}</p>
                        </div>)}
                        
                        
                    </div>
                </div>



                {/* transaction End */}
            </Section>
        </SiteWide>
    );

}

export default News;