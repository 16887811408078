import React, {Component, useState, useEffect} from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";
import FullScreenInputHolder from "../customComponents/FullScreenInputHolder";

import NoData from "../customComponents/NoData";

import Section from "../layouts/Section";
import {useHistory, useLocation} from 'react-router-dom';

import Slider from "react-slick";
import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {notify, styles, money, decimalCount, moneyStatus} from '../../utility/Helper1';

import {depositActions, withdrawalActions, authActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';

import LoadingOverlay from 'react-loading-overlay';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {mainStyle, mainColor, header, footer, otherColors} from '../../utility/styles';

import { AiOutlineWallet, AiFillCloseCircle, AiOutlineCopy, AiOutlineCloudUpload } from "react-icons/ai";
import CustomAgo from "../customComponents/CustomAgo";
import Countdown from 'react-countdown';

const amountSuggestion = [10, 20, 50, 100, 200, 500, 800, 1000];


function Transfer() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]   = useState('Loading history...');

    const [isOpen, setOpen] = useState(false);

    const [loading, setloading]   = useState(false);
    const [deleting, setDeleting]   = useState(false);

    const finance = useSelector(homeScreenStatSelect);
    const [currencySelected, setCurrencySelected]   = useState(finance?.[0]);
    const [currencyModal, setCurrencyModal]   = useState('');

    const allDepositData = useSelector(allDepositSelect);

    const [amount, setAmount]   = useState('');
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);

    const [googleAuth, setGoogleAuth]   = useState('');
    const [withdrawalPin, setWithdrawalPin]     = useState('');
    const [walletAddress, setWalletAddress]     = useState('');
    const [receiverEmail, setReceiverEmail]     = useState('');



    const dispatch = useDispatch();
    const loadHistoryData = useSelector(allDepositSelect);

    const search = useLocation();
    const depositRequest = search?.state?.deposit;

    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);

    

    const getPendingPayment = allDepositData?.filter((item, index) => item.status < 2);


    const processTransfer = (e) => {
        
        e.preventDefault();

        setloading(true)
        setLoadingText('Transfering fund...')


        dispatch(authActions.onLogin("fund/transfer/new", {amount, email: receiverEmail, googleAuth, withdrawalPin, currency: currencySelected.type}, response => {
            if(response.code == 1){
                setReceiverEmail('')
                setAmount('')
                setWithdrawalPin('')
                // setGoogleAuth('')
                // setSelectedCurrency('')
                // setOpenModal(false)
            }
            setloading(false)
            notify(response.msg, response.code);    
        }))
    }






    const newTransfer = () => {

        return <FullScreenInputHolder>
            <div>

               

                <form onSubmit = {processTransfer} style={{}} action="">
                       
                    <div class="single-input-wrap">
                       
                        <span className="new-input-label" style={{color: '#fff'}}>Amount</span>

                        <div className="input-group">

                            <input type="text" className="form-field form-control" onChange = {(e) => setAmount(e.target.value)} value={amount} style={{height: 60, color: '#000'}} />

                        </div>
                    </div>



                    <div class="single-input-wrap">
                       
                        <span className="new-input-label" style={{color: '#fff'}}>Receiver Email</span>

                        <div className="input-group">

                            <input type="text" className="form-field form-control" onChange = {(e) => setReceiverEmail(e.target.value)} value={receiverEmail} style={{height: 60, color: '#000'}} />

                        </div>
                    </div>


                     <div class="single-input-wrap">
                       
                        <span className="new-input-label" style={{color: '#fff'}}>Withdrawal Pin</span>

                        <div className="input-group">

                            <input type="text" className="form-field form-control" onChange = {(e) => setWithdrawalPin(e.target.value)} value={withdrawalPin} style={{height: 60, color: '#000'}} />

                        </div>
                    </div>




                    <div style={{paddingTop: 10, paddingBottom: 20}}>
                        <h4 style={{color: '#fff'}} className="passion-font">
                            {currencySelected.transfer_note_head}
                        </h4>
                        <p style={{color: '#fff'}}>
                            {currencySelected.transfer_note_body}
                        </p>
                    </div>

                    <div class="single-input-wrap">

                        <button type="submit" disabled={loading} className="passion-font h3 tn t-40 btn-block -danger" style={{padding: 50, backgroundColor: '#fbc63c'}}>
                        Submit </button>


                    
                    </div>

                </form>



            </div>

        </FullScreenInputHolder>

    }












    return (

        <SiteWide id="main" title={"Share Balance"}>
            <Section overlayText={loadingText} loading={loading} title={'Share Balance'} style={{}}>

            
            {newTransfer()}

            </Section>

        </SiteWide>
    );
}

export default Transfer;


