import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../utility/Helper1';
import {homepage} from '../utility/APIRequests';


import Countdown from 'react-countdown';

import copy from 'copy-to-clipboard';
import {staticCountries} from '../utility/countries';
import {Images} from "./customComponents";
import Slider from "react-slick";
import { RiFileCopyLine, RiPhoneLockFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';


function GoogleAuth({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [accessCode, setAccessCode]   = useState('');

    const [emailOTP, setEmailOTP]   = useState('');

    
    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');

    const dispatch = useDispatch();

    


    const activateGoogleAuth = (e) => {

        e.preventDefault();
        setloading(true)
        setLoadingText('Activating...')

        dispatch(authActions.onLogin(`account/google-auth/activate`, {google2fa:accessCode}, response => {
            notify(response.msg, response.code)
            setloading(false);
        }),
        );

    }


    const deActivateGoogleAuth = (e) => {

        e.preventDefault();
        setloading(true)
        setLoadingText('Deactivating...')

        dispatch(authActions.onLogin(`account/google-auth/deactivate`, {google2fa:accessCode}, response => {
            notify(response.msg, response.code)
            setloading(false);
        }),
        );

    }
   


    return (
        <SiteWide id="main" title={`Google Authenticator`}>
            <Section loading={loading} overlayText={loadingText} title={'Google Authenticator'}>
                
                <div className="input-container">



                        {userData?.use2FA == 0 && <div style={{background: '#fff', margin: 10, padding: 10}}>

                            <h5><strong>Bind google Auth</strong></h5>

                            <hr />


                            {userData?.use2FA == 0 && <p className="text-center">
                                <Images source={userData?.google2faImage} />
                                
                                <br/><br/><br/>

                                <h4 className="text-danger cursor-pointer" onClick={() => {copy(userData?.google2FASecret??''); notify('Copied', 1);}}>{userData?.google2FASecret} <RiFileCopyLine /></h4>

                                <br/>

                            </p>}


                            <p>
                                <ol>
                                    <li>
                                        Download Google authenticator for android or Google authenticator for Apple
                                    </li>
                                    <li>
                                        Scan the QRcode above or enter the provided key in red in your google authenticator app.
                                    </li>
                                    <li>
                                        Enter generated access code from google authenticator below.
                                    </li>
                                    <li>
                                        Upon submission. Google auth will be bind to your account activities
                                    </li>
                                </ol>
                            </p>

                            <hr />


                            <div>
                                <div class="form-group">
                                    <span className="text-info-1">Access Code</span>
                                    <input autoComplete="new-password" placeholder="Access Code" onChange = {e => setAccessCode(e.target.value) } required="" className="form-field form-control" type="password" />
                                </div>
                                <button onClick={activateGoogleAuth} style={{background: otherColors.btn1}} className="text-white cursor-pointer btn btn-block">Enable</button>
                            </div>



                        </div>}




                        {userData?.use2FA == 1 && <div style={{background: '#fff', margin: 10, padding: 10}}>

                            <h5><strong>UnBind google Auth</strong></h5>

                            <hr />



                            <p>
                                <ol>
                                    <li>
                                        Google auth active
                                    </li>
                                    <li>
                                        Enter your google access code below to disable google auth
                                    </li>
                                    <li>
                                        Or contact support
                                    </li>
                                    
                                </ol>
                            </p>

                            <hr />

                            <div>
                                <div class="form-group">
                                    <span className="text-info-1">Access Code</span>
                                    <input autoComplete="new-password" placeholder="Access Code" onChange = {e => setAccessCode(e.target.value) } required="" className="form-field form-control" type="password" />
                                    
                                </div>
                                <button onClick={deActivateGoogleAuth} style={{background: otherColors.btn1}} className="text-white cursor-pointer btn btn-block">Disable</button>
                            </div>

                        </div>}

                </div>

                <br /><br /><br />
                <br /><br /><br />
                <br /><br /><br />
                <br /><br /><br />


            </Section>
        </SiteWide>
    );

}

export default GoogleAuth;